import { ChangeDetectorRef, Component, Inject, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Book, BookSize, Template, UpdateBookInfo } from "@metranpage/book-data";
import { CompanyStore } from "@metranpage/company";
import { SelectValue, fadeInOutOnEnterLeave } from "@metranpage/components";
import { AnalyticsService, LoadingService, RouterService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { FormatData, FormatService, FormatStore } from "@metranpage/format-data";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { Subscription, filter, first, switchMap, tap } from "rxjs";
import { BookRouterService } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";
import { FormControlStructure, FormSectionStructure, ModalBookFormDataService } from "./modal-book-form-data.service";

@Component({
  selector: "m-modal-book-data",
  templateUrl: "modal-book-data.view.html",
  styleUrls: ["modal-book-data.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class ModalBookDataView {
  @Input()
  runInEditMode = false;

  protected book!: Book;
  protected bookSize!: BookSize;
  protected bookFormatName?: string;
  protected templateId = 0;
  protected template?: Template;

  protected fromCover = false;

  protected contentHeader = "";

  protected form?: FormGroup;

  protected initialBookInfo?: UpdateBookInfo;

  protected canProduceEpub = true;

  protected formatData?: FormatData;

  sub: Subscription = new Subscription();

  protected formData: FormSectionStructure[] = [];

  protected availableLanguages: SelectValue[] = [
    { id: "ru", value: $localize`:@@book.language.ru:` },
    { id: "en", value: $localize`:@@book.language.en:` },
    { id: "de", value: $localize`:@@book.language.de:` },
  ];

  protected availableAgeRestrictions: SelectValue[] = [
    { id: "0", value: "0+" },
    { id: "6", value: "6+" },
    { id: "12", value: "12+" },
    { id: "16", value: "16+" },
    { id: "18", value: "18+" },
  ];

  constructor(
    private readonly bookService: BookService,
    private readonly bookRouterService: BookRouterService,
    private readonly modalBookFormDataService: ModalBookFormDataService,
    @Inject(AVAILABLE_LANGUAGES) private readonly availableLanguagesCompany: LangSpec[],
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly routerService: RouterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly booksStore: BooksStore,
    private readonly formatService: FormatService,
    private readonly formatStore: FormatStore,
    private readonly companyStore: CompanyStore,
    private readonly analytics: AnalyticsService,
  ) {}

  async ngAfterViewInit() {
    this.sub.add(
      this.formatStore
        .getFormatsObservable()
        .pipe(
          filterUndefined(),
          filter((formats) => formats.length > 0),
          first(),
          switchMap(() => this.companyStore.getCompanyObservable()),
          filterUndefined(),
          tap((company) => {
            this.canProduceEpub = company.canProduceEpub;
          }),
          switchMap(() => this.booksStore.getModalBookObservable()),
          filterUndefined(),
        )
        .subscribe(async (book) => {
          await this.init(book);
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async init(book: Book) {
    this.book = book;

    const templateId = this.routerService.getRouteParam(this.route.snapshot, "templateId");
    this.templateId = Number(templateId);

    if (this.route.snapshot.url.some((u) => u.path === "to-cover")) {
      this.fromCover = true;
    }

    this.bookSize = {
      width: Number(this.routerService.getRouteParam(this.route.snapshot, "width")),
      height: Number(this.routerService.getRouteParam(this.route.snapshot, "height")),
    };

    this.bookFormatName = this.formatService.getFormatNameBySize(this.bookSize.width, this.bookSize.height);

    this.loadingService.startLoading({ fullPage: true });
    const allTemplates = await this.bookService.getTemplates();
    this.template = allTemplates.find((template) => template.id === this.templateId);
    this.loadingService.stopLoading();

    this.formatData = this.formatService.getFormatData(this.bookSize.width, this.bookSize.height);

    this.updateHeader();

    this.createForm(this.canProduceEpub);

    this.book.ageRestriction = _.defaultTo(this.book.ageRestriction, `${this.availableAgeRestrictions[0].id!}`);
    this.book.language = _.defaultTo(this.book.language, this.availableLanguagesCompany[0].value);

    const bookData: any = { ...this.book };
    for (const section of this.formData) {
      bookData[section.name] = { ...this.book };
    }

    this.form?.patchValue(bookData);
    this.initialBookInfo = this.getInlineBookInfoData();

    this.cdr.markForCheck();
  }

  protected getActionButtonText() {
    if (this.runInEditMode) {
      return $localize`:@@dialog.save-button:`;
    }
    return $localize`:@@dialog.next-button:`;
  }

  protected async onNextClick() {
    if (!this.form?.valid) {
      return;
    }
    if (this.runInEditMode) {
      this.analytics.event("edit-project-form");
    } else {
      this.analytics.event("new-project-form");
    }

    const data = this.getInlineBookInfoData();

    data.annotation = data.annotation?.replaceAll("<br>", "\n");
    data.adviser = data.adviser?.replaceAll("<br>", "\n");
    data.reviewers = data.reviewers?.replaceAll("<br>", "\n");

    this.notificationService.closeAll();

    if (!_.isEqual(this.initialBookInfo, data)) {
      this.loadingService.startLoading({ fullPage: true });
      const result = await this.bookService.updateBookInfo(this.book, data);
      this.loadingService.stopLoading();

      if (result === "success") {
        this.navigateToNext();
      } else if (result === "error") {
        this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
      }
    } else {
      this.navigateToNext();
    }
  }

  private async navigateToNext() {
    if (this.runInEditMode) {
      await this.routerService.closeModal();
      return;
    }

    if (this.fromCover) {
      this.router.navigateByUrl(`books/${this.book.id}/cover`);
      return;
    }
    this.router.navigateByUrl(`/books/${this.book.id}/markup`);
  }

  private getInlineBookInfoData() {
    let data: UpdateBookInfo = {};

    if (!this.form?.valid) {
      return data;
    }

    for (const control of Object.values(this.form.getRawValue())) {
      data = {
        ...data,
        ...(control as Partial<Book>),
      };
    }

    return data;
  }

  protected async onBackClick() {
    if (this.fromCover) {
      this.bookRouterService.showModal(this.book, "templates", "new");
      return;
    }
    this.bookRouterService.showModal(this.book, "upload", "new");
  }

  createForm(canProduceEpub: boolean) {
    this.formData = this.modalBookFormDataService.getFormData();

    if (!canProduceEpub) {
      this.formData = this.formData.filter((i) => i.name !== "type");
    }

    this.form = this.modalBookFormDataService.initForm(this.formData);
  }

  updateHeader() {
    let formatTitle = $localize`:@@home.new-project-modal.sidebar-section.item.book.format-custom:`;
    if (this.formatData?.title) {
      formatTitle = this.formatData.title;
    }

    let templateTitle = "";
    if (this.template) {
      templateTitle = ` / ${this.template.title}`;
      const symbolsLimit = 12;
      if (templateTitle.length > symbolsLimit) {
        templateTitle = `${templateTitle.slice(0, symbolsLimit)}...`;
      }
    }

    this.contentHeader = `${formatTitle}${templateTitle} / ${$localize`:@@books.modal.header.upload-step:`} / ${$localize`:@@books.modal.header.book-data-step:`}`;

    if (this.fromCover) {
      this.contentHeader = `${formatTitle} / ${$localize`:@@books.modal.header.book-data-step:`}`;
    }
    if (this.runInEditMode) {
      this.contentHeader = `${$localize`:@@books.modal.header.book-data-step:`}`;
    }
    this.cdr.markForCheck();
  }

  isControlTitleVisible(control: FormControlStructure) {
    return this.modalBookFormDataService.isControlTitleVisible(control);
  }

  isFieldRequired(control: FormControlStructure) {
    return this.modalBookFormDataService.isFieldRequired(control);
  }
}
