import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    Color,
  CoverObject,
  Fill,
  ObjectsAlignment,
  SolidFill,
  TextAlignment,
  TextCase,
  TextObject,
} from "@metranpage/book-data";
import { ColorConverterService, SelectValue } from "@metranpage/components";
import * as _ from "lodash-es";
import { Observable, filter, map, startWith, tap } from "rxjs";
import { CoverUiService } from "../../services/cover/cover-ui.service";

@Component({
  selector: "m-cover-text-object-settings",
  templateUrl: "./cover-text-object-settings.component.html",
  styleUrls: ["./cover-text-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverTextObjectSettingsComponent implements OnChanges, OnInit {
  private _currentObject!: TextObject;
  @Input() set currentObject(value: TextObject) {
    if (value) {
      this.resetShadowState(value.text);
      this._currentObject = value;
      if (!this.form) {
        return;
      }
      if (this._currentObject.shadow) {
        this.toggleShadow();
      }
      this.updateFormValue();
    }
  }
  get currentObject(): TextObject {
    return this._currentObject;
  }
  @Input() fontFaces?: FontFace[];

  @Output() update = new EventEmitter<CoverObject>();
  @Output() previewFontFamily = new EventEmitter<string>();
  @Output() resetFontFamily = new EventEmitter();
  @Output() align = new EventEmitter<ObjectsAlignment>();

  values$!: Observable<Partial<TextObject>>;
  fontOptions: SelectValue[] = [];
  textCase = TextCase;

  color!: Color;
  withShadow = false;
  form!: FormGroup;

  constructor(
    private readonly colorConverter: ColorConverterService,
    private readonly coverUiService: CoverUiService,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      fontSize: new FormControl<number | undefined>(undefined, [Validators.pattern("^[0-9.]*$")]),
      lineHeight: new FormControl<number | undefined>(undefined, [Validators.pattern("^[0-9.]*$")]),
      letterSpacing: new FormControl<number | undefined>(undefined, [Validators.pattern("^-?[0-9.]*$")]),
      fontFamily: new FormControl<string | undefined>(undefined),
    });

    this.updateFormValue();

    if (this.currentObject.shadow) {
      this.toggleShadow();
    }

    this.values$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      filter(() => this.form.valid),
      map((v) => <Partial<TextObject>>v),
      tap((v) => {
        Object.assign(this.currentObject, v);
        this.update.emit(this.currentObject);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fontFaces) {
      //this.fontOptions = this.fontFaces?.map((f) => <SelectValue>{ id: f.family, value: f.family }) ?? [];
      this.fontOptions = _.uniqBy(
        this.fontFaces?.map((f) => <SelectValue>{ id: f.family, value: f.family }) ?? [],
        (v) => {
          return v.id;
        },
      );
    }
  }

  private updateFormValue(): void {
    const patchValue = {
      fontSize: this.currentObject.fontSize,
      lineHeight: this.currentObject.lineHeight,
      letterSpacing: this.currentObject.letterSpacing,
      fontFamily: this.currentObject.fontFamily,
    };
    this.form.patchValue(patchValue);
  }

  private resetShadowState(text?: string): void {
    if (this._currentObject?.text !== text && this.form) {
      if (this.form.controls["shadow"]) {
        this.form.removeControl("shadow");
      }
      this.withShadow = false;
    }
  }

  toggleShadow(): void {
    if (this.form.controls["shadow"]) {
      this.form.controls["shadow"].reset();
      this.form.removeControl("shadow");
    }

    if (this.withShadow) {
      this.withShadow = false;
      return;
    }

    this.withShadow = true;
    this.setShadowColor();

    const shadowGroup = new FormGroup({
      blur: new FormControl<number | undefined>(this.currentObject.shadow?.blur || 10),
      offset: new FormControl<number | undefined>(this.currentObject.shadow?.offset || 20),
      color: new FormControl<string | undefined>(Color.toCss(this.color)),
      direction: new FormControl<number | undefined>(this.currentObject.shadow?.direction || 40),
    });
    this.form.addControl("shadow", shadowGroup);

    this.update.emit(this.currentObject);
  }

  setShadowColor() {
    if (this.currentObject.shadow?.color) {
      const shadowColor = this.currentObject.shadow?.color;
      const rgbaArray = shadowColor.slice(shadowColor.indexOf("(") + 1, shadowColor.indexOf(")")).split(",");
      const rgbaObject = ["r", "g", "b", "a"].reduce((acc: any, curr, idx) => {
        acc[curr] = rgbaArray[idx];
        return acc;
      }, {});
      this.color = new Color(
        Math.round(rgbaObject.r),
        Math.round(rgbaObject.g),
        Math.round(rgbaObject.b),
        rgbaObject.a,
      );
      return;
    }
    if (this.currentObject.fill instanceof SolidFill) {
      this.color = new Color(0, 0, 0, 0.2);
    }
  }

  setTextAlign(alignment: TextAlignment) {
    this.currentObject.textAlign = alignment;
    this.update.emit(this.currentObject);
  }

  updateFill(fill: Fill) {
    this.currentObject.fill = fill;
    this.update.emit(this.currentObject);
  }

  get fillType(): string {
    if (this.currentObject.fill instanceof SolidFill) {
      return $localize`:@@cover-editor.object.settings.color:`;
    }
    return $localize`:@@cover-editor.object.settings.gradient:`;
  }

  toggleTextBold() {
    this.currentObject.bold = !this.currentObject.bold;
    this.update.emit(this.currentObject);
  }

  toggleTextItalic() {
    this.currentObject.italic = !this.currentObject.italic;
    this.update.emit(this.currentObject);
  }

  toggleTextUnderline() {
    this.currentObject.underline = !this.currentObject.underline;
    this.update.emit(this.currentObject);
  }

  onUpdate(object: CoverObject) {
    this.update.emit(object);
  }

  onPreviewFontFamily(fontFamily: string) {
    this.previewFontFamily.emit(fontFamily);
  }

  onResetFontFamily() {
    this.resetFontFamily.emit();
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  setTextCase(textCase: TextCase): void {
    if (this.currentObject.text) {
      this.currentObject.textCase = textCase;
      this.update.emit(this.currentObject);
    }
  }

  updateShadowColor(color: Color) {
    const newColorCss = Color.toCss(color);
    (this.form.controls["shadow"] as FormGroup).controls["color"].setValue(newColorCss);
  }
}
