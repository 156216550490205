<div
  class="container"
  [class.active]="activeSubscription?.tariff?.id === tariff.id || (activeSubscription?.tariff?.level === tariff.level && activeSubscription?.tariff?.isFree)"
>
  <div class="header-wrapper">
    <div class="header">
      <div class="title">{{ tariff.title.toUpperCase() }}</div>
      <div class="tokens-count">
        <m-ng-icon class="tokens-count-icon" src="coins-01.svg" [size]="16" />
        <span>{{ getTokensCount() }}</span>
      </div>
    </div>

    <span class="more-about" i18n="@@pricing.tariffs.tariff-card.more-about" (click)="onMoreAboutTariffClick()">
      pricing.tariffs.tariff-card.more-about
    </span>
  </div>

  <div class="divider-hor"></div>

  <div class="offer-block">
    <div class="price-wrapper">
      <div class="price">{{ getPrice() }}</div>
      <div *ngIf="tariff.profit > 0 || hasPromocodeDiscount()" class="without-discount">{{ priceWithoutDiscount }}</div>
      <div *ngIf="tariff.profit > 0 || hasPromocodeDiscount()" class="without-discount-percent">
        {{ discountPercent }}
      </div>
    </div>

    <div class="accrual-period">{{ getAccrualPeriodText() }}</div>

    <ng-container
      *ngIf="(!activeSubscription || activeSubscription.tariff.isFree) && tariffChangeDirection; else active"
    >
      <m-button
        button-style="primary"
        (onClick)="onBuySubscriptionClick.emit(tariff)"
        i18n="@@pricing.tariffs.tariff-card.buttons.connect"
      >
        pricing.tariffs.tariff-card.buttons.connect
      </m-button>
    </ng-container>

    <ng-template #active>
      <m-button
        *ngIf="tariffChangeDirection === undefined"
        button-style="secondary"
        [is-disabled]="true"
        i18n="@@pricing.tariffs.tariff-card.buttons.active"
      >
        pricing.tariffs.tariff-card.buttons.active
      </m-button>

      <m-button
        *ngIf="tariffChangeDirection === 'downgrade'"
        button-style="secondary"
        (onClick)="onBuySubscriptionClick.emit(tariff)"
        i18n="@@pricing.tariffs.tariff-card.buttons.downgrade"
      >
        pricing.tariffs.tariff-card.buttons.downgrade
      </m-button>

      <m-button
        *ngIf="tariffChangeDirection === 'refresh'"
        button-style="primary"
        (onClick)="onBuySubscriptionClick.emit(tariff)"
        i18n="@@pricing.tariffs.tariff-card.buttons.refresh"
      >
        pricing.tariffs.tariff-card.buttons.refresh
      </m-button>

      <m-button
        *ngIf="tariffChangeDirection === 'upgrade'"
        button-style="primary"
        (onClick)="onBuySubscriptionClick.emit(tariff)"
        i18n="@@pricing.tariffs.tariff-card.buttons.connect"
      >
        pricing.tariffs.tariff-card.buttons.connect
      </m-button>
    </ng-template>

    <!-- <m-button
              *ngIf="activeSubscription?.tariff?.id !== tariff.id && !tariff.isFree && tariff.price > 0 && tariff.forLegalEntity"
              button-style="primary"
              (onClick)="onBuySubscriptionClick.emit(tariff)"
              i18n="@@pricing.tariffs.tariff-card.buttons.bill"
            >
              pricing.tariffs.tariff-card.buttons.bill
            </m-button> -->
  </div>
</div>
