import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";
import { User } from "@metranpage/user-data";
import { DownloadGeneratedImageData, GeneratedImage } from "../../models/generated-image";
import {
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
} from "../../models/image-generation";
import { ImageGenerationDataService } from "../../services/image-generation-data.service";
import { ImageGenerationService } from "../../services/image-generation.service";

export type PreviewImageData = { imageGeneration: ImageGeneration; generatedImage: GeneratedImage };

@Component({
  selector: "m-generation-result",
  templateUrl: "./generation-result.view.html",
  styleUrls: ["./generation-result.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerationResultView implements OnInit {
  @Input()
  user?: User;
  @Input()
  imageGeneration!: ImageGeneration;
  @Input()
  basicStyle?: ImageGenerationBasicStyle;
  @Input()
  advancedStyle?: ImageGenerationAdvancedStyle;
  @Input()
  showDivider = true;
  @Input()
  isModal = false;

  @Output()
  onPreviewImage = new EventEmitter<PreviewImageData>();
  @Output()
  onSelectImage = new EventEmitter<GeneratedImage>();
  @Output()
  onDeleteImageGeneration = new EventEmitter<ImageGeneration>();
  @Output()
  onDownnloadImage = new EventEmitter<DownloadGeneratedImageData>();
  @Output()
  onDeleteImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUpscaleImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUnzoomImage = new EventEmitter<GeneratedImage>();
  @Output()
  onEditImageGeneration = new EventEmitter<ImageGeneration>();

  protected isCollapsed = false;

  constructor(
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isCollapsed = this.imageGeneration.status === "error";
  }

  protected toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  protected onDeleteGeneratedImageClick(generatedImage: GeneratedImage) {
    this.onDeleteImage.emit(generatedImage);
  }

  protected onDeleteImageGenerationClick() {
    this.onDeleteImageGeneration.emit(this.imageGeneration);
  }

  protected onSelectImageClick(generatedImage: GeneratedImage) {
    this.onSelectImage.emit(generatedImage);
  }

  protected onDownloadImageClick(downloadGeneratedImageData: DownloadGeneratedImageData) {
    this.onDownnloadImage.emit(downloadGeneratedImageData);
  }

  protected onDeleteImageClick(generatedImage: GeneratedImage) {
    this.onDeleteImage.emit(generatedImage);
  }

  protected onUpscaleImageClick(generatedImage: GeneratedImage) {
    this.onUpscaleImage.emit(generatedImage);
  }

  protected onUnzoomImageClick(generatedImage: GeneratedImage) {
    this.onUnzoomImage.emit(generatedImage);
  }

  protected onShowImagePreviewClick(generatedImage: GeneratedImage) {
    this.onPreviewImage.emit({
      imageGeneration: this.imageGeneration,
      generatedImage,
    });
  }

  protected getGeneratedImages(imageGeneration: ImageGeneration) {
    return this.imageGenerationService.orderGeneratedImages(imageGeneration.generatedImages);
  }

  protected onEditImageGenerationClick() {
    this.onEditImageGeneration.emit(this.imageGeneration);
  }

  protected isEditable(): boolean {
    return this.imageGeneration.generationMode === "advanced" && this.imageGeneration.styleId >= 0;
  }

  protected getDetalizationTitle() {
    if (!this.imageGeneration.detalization) {
      return "";
    }
    return this.imageGenerationDataService.getDetalizationTitle(this.imageGeneration.detalization);
  }

  protected getMoodTitle() {
    if (!this.imageGeneration.mood) {
      return "";
    }
    return this.imageGenerationDataService.getMoodTitle(this.imageGeneration.mood);
  }

  protected getAdvancedGenerationModeTitle() {
    if (!this.imageGeneration.advancedGenerationMode) {
      return "";
    }
    return this.imageGenerationDataService.getAdvancedGenerationModeTitle(this.imageGeneration.advancedGenerationMode);
  }
}
