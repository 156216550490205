import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SelectValue } from "@metranpage/components";
import { User } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { Notification, NotificationTrialPeriodActivate } from "../../models/notification";
import { ConnectTelegramService } from "../../services/connect-telegram.service";

export type NotificationFilterSelectorType = "important" | "news" | "moderation" | "all";

@Component({
  selector: "m-account-notifications-view",
  templateUrl: "account-notifications.view.html",
  styleUrls: ["account-notifications.view.scss"],
})
export class AccountNotificationsView {
  @Input()
  user?: User;
  @Input()
  notifications: Notification[] = [];

  @Output()
  onScroll = new EventEmitter();

  protected selectedFilterOptionId: NotificationFilterSelectorType = "all";
  protected filterOptions: SelectValue[] = [
    { id: "all", value: $localize`:@@user.profile.notifications.filter.all:` },
    { id: "important", value: $localize`:@@user.profile.notifications.filter.important:` },
    // { id: "news", value: $localize`:@@user.profile.notifications.filter.news:` },
    { id: "moderation", value: $localize`:@@user.profile.notifications.filter.moderation:` },
  ];

  protected filteredNotifications: Notification[] = [];

  sub: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly connectTelegramService: ConnectTelegramService,
  ) {}

  ngAfterViewInit(): void {
    this.selectSuitableNotifications();
  }

  ngOnChanges() {
    this.selectSuitableNotifications();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected onSelectFilterOption(filter: string | number) {
    this.selectedFilterOptionId = filter as NotificationFilterSelectorType;
    this.selectSuitableNotifications();
  }

  selectSuitableNotifications() {
    this.filteredNotifications = this.notifications.filter((n) => {
      if (this.selectedFilterOptionId === "moderation") {
        return n.type === "info" && ["image-moderation", "cover-moderation"].includes(n.kind);
      }
      if (this.selectedFilterOptionId === "important") {
        return (
          n.type === "info" &&
          [
            "promocode-activate",
            "promocode-expire",
            "email-verification",
            "trial-period-activate",
            "trial-period-ending",
            "book-layout",
            "book-without-cover",
            "subscription-activate",
            "subscription-ending",
          ].includes(n.kind)
        );
      }
      if (this.selectedFilterOptionId === "news") {
        // return true;
      }
      if (this.selectedFilterOptionId === "all") {
        return true;
      }
      return false;
    });
  }

  protected onScrollEvent() {
    this.onScroll.emit();
  }

  protected getCardViewType(notification: Notification): string {
    if (notification.type === "info" && notification.kind === "registration") {
      return "registration";
    }
    if (notification.type === "info" && notification.kind === "image-moderation") {
      return "image-moderation";
    }
    if (notification.type === "info" && notification.kind === "cover-moderation") {
      return "cover-moderation";
    }
    if (notification.type === "info" && notification.kind === "promocode-activate") {
      return "promocode-activate";
    }
    if (notification.type === "info" && notification.kind === "promocode-expire") {
      return "promocode-expire";
    }
    if (notification.type === "info" && notification.kind === "email-verification") {
      return "email-verification";
    }
    if (notification.type === "info" && notification.kind === "trial-period-activate") {
      const payload = notification.payload as NotificationTrialPeriodActivate;
      if (payload.trialPeriodInDay > 0) {
        return "trial-period-activate";
      }
    }
    if (notification.type === "info" && notification.kind === "trial-period-ending") {
      return "trial-period-ending";
    }
    if (notification.type === "info" && notification.kind === "book-layout") {
      return "book-layout";
    }
    if (notification.type === "info" && notification.kind === "book-without-cover") {
      return "book-without-cover";
    }
    if (notification.type === "info" && notification.kind === "subscription-activate") {
      return "subscription-activate";
    }
    if (notification.type === "info" && notification.kind === "subscription-ending") {
      return "subscription-ending";
    }
    if (notification.type === "info" && notification.kind === "low-balance") {
      return "low-balance";
    }
    if (
      notification.type === "info" &&
      notification.kind === "connect-telegram" &&
      this.connectTelegramService.isTelegramConnectionAvailable()
    ) {
      return "connect-telegram";
    }
    if (notification.type === "info" && notification.kind === "add-tokens") {
      return "add-tokens";
    }
    if (notification.type === "info" && notification.kind === "like") {
      return "like";
    }

    return "info";
  }
}
