import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "@metranpage/user";
import { UserAction } from "@metranpage/user-data";
import { ShepherdService } from "angular-shepherd";
import { Subject, filter, timer } from "rxjs";
import { OnboardingPage, StepsCreator } from "../services/steps-creator.service";
import Step from "shepherd.js/src/types/step";

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly shepherdService: ShepherdService,
    @Inject(StepsCreator) private readonly stepsCreator: StepsCreator,
  ) {
    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(async (_data) => {
      this.clearTour();
    });
  }

  onStartOnboarding$ = new Subject<boolean>();

  clearTour() {
    if (!this.shepherdService.isActive) {
      return;
    }

    this.shepherdService.tourObject.off("complete");
    this.shepherdService.tourObject.off("cancel");

    // this.shepherdService.cancel();
    this.shepherdService.complete();
  }

  async onStartOnboarding(showForced = false) {
    this.onStartOnboarding$.next(showForced);
  }

  async startOnboarding(onboardingPage: OnboardingPage, showForced = false, timeout = 0): Promise<void> {
    this.clearTour();

    const isOnboardingWasShown = await this.isOnboardingWasShown(`onboarding-${onboardingPage}`);
    if (isOnboardingWasShown && !showForced) {
      return;
    }

    timer(timeout).subscribe(() => {
      this.startTour(onboardingPage);
    });
  }

  startTour(onboardingPage: OnboardingPage) {
    const steps = this.stepsCreator.createSteps(onboardingPage);
    this.settingsOnboarding(onboardingPage, steps);
    this.shepherdService.start();

    this.watchTourEvents();
  }

  watchTourEvents() {
    const events = ["complete", "cancel"];
    for (const event of events) {
      this.shepherdService.tourObject.on(event, () => {
        this.shepherdService.onTourFinish(event);
        this.userService.updateUserAction({
          name: `onboarding-${this.shepherdService.tourName}`,
          status: event,
        });
      });
    }
  }

  async settingsOnboarding(tourName: string, steps: Step.StepOptions[]): Promise<void> {
    this.shepherdService.defaultStepOptions = this.stepsCreator.getDefaultStepOptions();
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.exitOnEsc = true;

    this.shepherdService.addSteps(steps);
    this.shepherdService.tourName = tourName;
  }

  async isOnboardingWasShown(actionName: string): Promise<boolean> {
    const userActions = await this.userService.getUserActions();
    const action = userActions?.find((action: UserAction) => action.name === actionName);
    if (action) {
      return true;
    }
    return false;
  }
}
