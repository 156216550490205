import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription, timer } from "rxjs";
import { UserService } from "../../services/user.service";
import { AccountContactsData } from "../../views/account-contacts/account-contacts.view";

@Component({
  selector: "m-account-contacts-page",
  template: `
    <m-account-contacts-view
      [user]="user"
      [savedStatus]="savedStatus"
      (onSaveClick)="onSaveClick($event)"
      (onVerifyEmailClick)="onVerifyEmailClick()"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class AccountContactsPage {
  user?: User;
  savedStatus = false;
  savedStatusVisibleTimeout = 1;

  sub: Subscription = new Subscription();

  constructor(
    private readonly userService: UserService,
    private readonly loadingService: LoadingService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly router: Router,
    userStore: UserStore,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async onSaveClick(data: AccountContactsData) {
    this.savedStatus = false;

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.userService.updateUserEmail(data);
    if (result === "success") {
      this.notificationsService.notify({
        content: $localize`:@@user.profile.updated-successfully:`,
        type: "success",
      });

      this.savedStatus = true;
      timer(this.savedStatusVisibleTimeout * 1000).subscribe(() => {
        this.savedStatus = false;
      });
    }
    this.loadingService.stopLoading();
  }

  onVerifyEmailClick() {
    this.router.navigate(["/account/account-verification"], { queryParams: { from: "account" } });
  }
}
