<div class="wrapper" *ngIf="currentObject">
  <div class="section header-wrapper">
    <div class="caption">{{ caption }}</div>
    <m-ng-icon class="close-icon" src="cross-02.svg" [size]="20" (click)="onClose()" />
  </div>
  <div class="section main">
    <ng-scrollbar class="scroll-container">
      <m-cover-shape-object-settings
        *ngIf="shapeObject"
        [currentObject]="shapeObject"
        [isGradientApplicable]="isGradientApplicable"
        (update)="onUpdate(shapeObject)"
        (align)="onAlign($event)"
      />
      <m-cover-text-object-settings
        *ngIf="textObject"
        [currentObject]="textObject"
        [fontFaces]="fontFaces"
        (update)="onUpdate(textObject)"
        (align)="onAlign($event)"
        (previewFontFamily)="onPreviewFontFamily($event)"
        (resetFontFamily)="onResetFontFamily()"
      />
      <m-cover-image-object-settings
        *ngIf="imageObject"
        [currentObject]="imageObject"
        [prices]="prices"
        [balance]="balance"
        (align)="onAlign($event)"
        (removeBackground)="onRemoveBackground($event)"
      />
      <m-cover-group-object-settings
        *ngIf="groupObject"
        [currentObject]="groupObject"
        (align)="onAlign($event)"
        (ungroup)="onungroupObjects()"
        (update)="onUpdate(groupObject)"
      />
      <m-cover-multiselect-settings
        *ngIf="multiselect"
        [objects]="multiselect"
        (align)="onAlign($event)"
        (group)="onGroupObjects()"
        (colorsUpdate)="onColorsUpdate($event)"
      />
    </ng-scrollbar>
  </div>
</div>
