<m-ng-island [paddingSize]="0">
  <div class="container">
    <div class="header">
      <div class="header-group">
        <div i18n="@@user.profile.rewards.title">user.profile.rewards.title</div>
      </div>
    </div>

    <div class="divider-hor"></div>

    <div
      class="rewards-wrapper"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollEvent()"
    >
      <overlay-scrollbars>
        <div class="rewards-cards">
          <div class="rewards-card" *ngFor="let r of rewardNotifications; let isLast = last;">
            <m-account-reward-notification-base-card
              [rewardNotification]="r"
              [title]="getTitle(r)"
              [iconSrc]="getIconSrc(r)"
              [isLast]="isLast"
            />
          </div>
        </div>
      </overlay-scrollbars>
    </div>
  </div>
</m-ng-island>
