import { Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { User } from "@metranpage/user-data";
import posthog from "posthog-js";
import { Observable, filter } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private gtag: any;

  constructor(private readonly router: Router) {
    this.gtag = (window as any).gtag;
    posthog.init("phc_AiKB10K4WruG9fqwRWU9TivbDjt6lpDaPwQVVpZNi7u", {
      api_host: "https://eu.i.posthog.com",
      person_profiles: "identified_only",
      capture_pageview: false,
    });

    const navigationEnd = router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;
    navigationEnd.subscribe((_event: NavigationEnd) => {
      posthog.capture("$pageview");
    });
  }

  trackUser(user: User) {
    if (this.gtag) {
      this.gtag("set", {
        user_id: user.id,
      });
    }
    posthog.identify(`${user.id}`, {
      email: user.email,
      // registered_at: user.,
      is_verified: user.isVerified,
    });
  }

  logout() {
    if (this.gtag) {
      this.gtag("set", {
        user_id: null,
      });
    }
    posthog.reset();
  }

  event(eventName: string, payload: any = {}) {
    if (this.gtag) {
      this.gtag("event", eventName, payload);
    }
    posthog.capture(eventName, payload);
  }
}
