import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  BlockId,
  EditorData,
  EditorDataItem,
  Heading,
  MarkupEditorView,
  MarkupService,
  StyleDef,
  StyleKey,
  StyleSettings,
  Styles,
  ToolbarSettings,
} from "@metranpage/markup-editor";
import { Book, CompanyFont, ImageBlockDefaulfData, StylesSettings } from "@metranpage/book-data";
import { PaletteDTO, SelectValue, fadeInOutForSidebarElements, slideInOutSidebarLTR } from "@metranpage/components";
import { AnalyticsService, LoadingService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { OnboardingService } from "@metranpage/onboarding";
import { TextGenerationDataDto, TextGenerationService } from "@metranpage/text-generation";
import { UserStore } from "@metranpage/user-data";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, first, interval, timer } from "rxjs";
import { ComponentCanDeactivate } from "../../book.guard";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";
import { FontsService } from "../../services/fonts.service";
import { MarginsService } from "../../services/margins.service";
import { MarkupFormService } from "./markup-form.service";

@Component({
  selector: "m-books-markup",
  templateUrl: "./markup.page.html",
  styleUrls: ["./markup.page.scss"],
  animations: [slideInOutSidebarLTR, fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupPage implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(MarkupEditorView)
  protected editor!: MarkupEditorView;

  protected book$: Observable<Book>;

  protected styles?: Styles;
  protected stylesSettings: StylesSettings = {};
  protected selectedStyles: StyleKey[] = [];
  protected hoveredStyle: StyleKey | undefined = undefined;
  protected editorItems: EditorDataItem[] = [];
  protected headings: Heading[] = [];
  protected activeHeadingIndex = 0;

  protected styleChangeEvents = new Subject<StyleKey>();
  protected selectBlockEvents = new Subject<BlockId>();

  protected availableMainFonts: SelectValue[] = [];
  protected availableHeadersFonts: SelectValue[] = [];

  protected availablePalettes!: PaletteDTO[];

  protected isStyleDetailsSidebarVisible = false;
  protected styleDetailsSidebarVisible: StyleKey | undefined = undefined;
  protected detailsStyle: StyleDef | undefined;

  protected isColorsDetailsVisible = false;
  protected activePalette?: PaletteDTO;

  protected imageDefaultData: ImageBlockDefaulfData = {
    isImagesSettingsAvailable: true,
    size: "medium",
    cropClass: "noncrop",
  };

  protected toolbarSettings: ToolbarSettings = {
    isAddTableAvailable: true,
  };

  protected autoSaveTimeoutMinutes = 5;

  protected isDataSaved = true;

  protected form?: FormGroup;

  protected formChanges = new Subject<any>();

  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  private sub = new Subscription();

  constructor(
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly markupService: MarkupService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly markupForm: MarkupFormService,
    private readonly cdr: ChangeDetectorRef,
    private readonly marginsService: MarginsService,
    private readonly onboardingService: OnboardingService,
    userStore: UserStore,
    private readonly fontsService: FontsService,
    private readonly textGenerationService: TextGenerationService,
    private readonly analytics: AnalyticsService,
  ) {
    this.availablePalettes = markupForm.getAvailablePalettes();

    this.book$ = this.booksStore.getActiveBookObservable().pipe(filterUndefined());

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.isStyleDetailsSidebarVisible = false;
        this.isColorsDetailsVisible = false;
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;
      }),
    );
  }

  ngOnInit(): void {
    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), first())
        .subscribe(async (book) => {
          const isEditableTemplate = !!book.bookSettings?.isEditable;

          this.updateImagesSettings(book);
          this.updateToolbarSettings(book);

          await this.updateFonts(isEditableTemplate);

          await this.initForm(book);

          await this.initEditor(book);

          this.updatePalette(book);

          this.watchFormChanges();
        }),
    );

    this.initAutoSaving();

    this.cdr.detectChanges();
  }

  async ngAfterViewInit() {
    this.startOnboarding();
  }

  ngOnDestroy(): void {
    this.markupForm.destroyForm();
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    this.onboardingService.startOnboarding("markup-page", showForced, 1000);
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (!this.isDataSaved) {
      return confirm($localize`:@@books.markup.close-without-save-data:`);
    }
    return true;
  }

  private async initForm(book: Book) {
    const bookSettings = await this.bookService.getMarkupSettings(book.id);

    this.stylesSettings = bookSettings.styles;

    this.styles = {};
    for (const style in this.stylesSettings) {
      this.styles[style] = this.getDetailsStyle(style);
    }

    const isEditableTemplate = !!book.bookSettings?.isEditable;
    this.form = this.markupForm.initForm(this.stylesSettings, isEditableTemplate);

    for (const style of Object.values(bookSettings.styles)) {
      this.markupService.updateStyleDisplayOpts(style.styleKey!, style);
    }

    const styles = bookSettings.styles;
    for (const style of Object.values(styles)) {
      if (isEditableTemplate) {
        style.font = `${this.markupForm.getMainFontId(bookSettings.mainFont)}`;
        if (style.styleKey?.includes("header")) {
          style.font = `${this.markupForm.getHeaderFontId(bookSettings.headerFont)}`;
        }
      }

      if (style.dropCapChars > 0 && style.dropCapLines > 0) {
        style.dropCap = true;
      }
    }

    const formData = {
      fontSizeHeaders: this.markupForm.getHeadersFontSizeValue(bookSettings.styles),
      headerFont: this.markupForm.getHeaderFontId(bookSettings.headerFont),
      mainFont: this.markupForm.getMainFontId(bookSettings.mainFont),
      styles: styles,
      tocVisible: bookSettings.tocVisible,
      tocPosition: bookSettings.tocPosition,
      centerImages: bookSettings.centerImages,
      palette: bookSettings.palette,
    };

    this.form?.patchValue(formData);
    this.cdr.markForCheck();
  }

  watchFormChanges() {
    this.markupForm.watchFontSizeHeadersChanges();

    this.sub.add(
      this.form?.valueChanges.subscribe((data) => {
        this.isDataSaved = false;
        this.formChanges.next(data);
      }),
    );

    this.sub.add(
      this.form?.get("palette")?.valueChanges.subscribe((palette) => {
        this.activePalette = _.clone(palette);
        this.cdr.markForCheck();
      }),
    );
  }

  private async initEditor(book: Book) {
    const data: EditorData | undefined = await this.bookService.getEditorData(book);
    if (!data) {
      this.notificationService.error($localize`:@@books.error.cant-load-book:`);
      this.loadingService.stopLoading();
      return;
    }
    this.editorItems = this.markupService.prepareEditorData(data);
    this.cdr.detectChanges();
  }

  onEditorReady() {
    // this.headings = this.getHeadings();

    timer(500).subscribe(() => {
      // timer just in case
      this.loadingService.stopLoading();
    });
  }

  async onSaveClick() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.save();
    this.loadingService.stopLoading();
    if (result === "success") {
      this.notificationService.notify({
        content: $localize`:@@books.notification.book-saved:`,
        type: "success",
      });
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  protected isNextStepEnable() {
    if (!this.form?.valid || !this.hasTextInBlocks() || this.isDocumentOnlyImages()) {
      return false;
    }
    return true;
  }

  private isDocumentOnlyImages(): boolean {
    const hasImageBlock = this.editorItems.some((block) => block.block.style === "image");
    const hasNonImageBlocks = this.editorItems.some((block) => block.block.style !== "image");
    return this.editorItems.length > 0 && !hasNonImageBlocks;
  }

  async onNextClick() {
    timer(250).subscribe(async () => {
      if (!this.isNextStepEnable()) {
        return;
      }

      if (this.isDataSaved) {
        this.navigateNextStep();
        return;
      }

      this.notificationService.closeAll();
      this.loadingService.startLoading({ fullPage: true });
      const result = await this.save();
      this.loadingService.stopLoading();

      if (result === "success") {
        this.navigateNextStep();
      } else {
        this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
      }
    });
  }

  private navigateNextStep() {
    this.analytics.event("markup-next");

    const book = this.booksStore.getActiveBook()!;
    if ((!this.hasPaidTariff && !this.hasTrialPeriod) || !book.exportPrint || !book.bookSettings?.isEditable) {
      this.router.navigate(["books", book.id, "preview"]);
      return;
    }
    this.router.navigate(["books", book.id, "margins"]);
  }

  private async save(): Promise<GeneralResultStatus> {
    const activeBook = this.booksStore.getActiveBook()!;
    const formValue = this.form!.getRawValue();
    this.clearFormData(formValue);
    const result1 = await this.bookService.setMarkupSettings(activeBook, formValue);
    const blocks = this.editor.getBlocks();
    const result2 = await this.bookService.setEditorData(activeBook, { blocks }, false);
    if (result1 === "success" && result2 === "success") {
      this.isDataSaved = true;
      return "success";
    }
    return "error";
  }

  onStyleSelected(style: StyleKey) {
    this.styleChangeEvents.next(style);
  }

  onStyleDisplayOptionChanged(data: { style: StyleKey; opts: StyleSettings }) {}

  onBlockSelectionChanged(selectedBlocks: EditorDataItem[]) {
    const blockIndex = this.editorItems.findIndex((block) => block?.block.id === selectedBlocks[0]?.block.id);
    if (blockIndex !== -1) {
      this.searchHeadingBlock(blockIndex);
    }
    const blockText = this.editorItems.findIndex((block) => block?.block.id === selectedBlocks[0]?.block.text);
    const styles = selectedBlocks.map((block) => block.block.style);
    this.selectedStyles = _.uniq(styles);
  }

  showStyleDetails(style: StyleKey) {
    this.isStyleDetailsSidebarVisible = true;
    this.styleDetailsSidebarVisible = style;

    this.detailsStyle = this.getDetailsStyle(style);

    this.cdr.detectChanges();
  }

  getDetailsStyle(style: StyleKey) {
    return {
      availableControls: this.stylesSettings[style].availableControls,
      isConfigurableAtDetailsSidebar: this.stylesSettings[style].isConfigurableAtDetailsSidebar,
      isDisplayedAtSidebar: this.stylesSettings[style].isDisplayedAtSidebar,
      styleKey: style,
      type: this.stylesSettings[style].type,
      visibleTitle: this.stylesSettings[style].visibleTitle,
    };
  }

  hideStyleDetailsSidebar() {
    this.isStyleDetailsSidebarVisible = false;
    this.styleDetailsSidebarVisible = undefined;

    this.cdr.detectChanges();
  }

  getStyleKeysForDetailsSidebar(): StyleKey[] {
    return _.filter(this.stylesSettings, (s) => s.isConfigurableAtDetailsSidebar).map((s) => s.styleKey!);
  }

  onHeadingsChanged(headings: Heading[]) {
    this.headings = headings;
    this.cdr.detectChanges();
  }

  onHeadingClick(heading: Heading) {
    this.selectBlockEvents.next(heading.blockId);
  }

  showColorsDetailsSidebar() {
    this.isColorsDetailsVisible = true;
    this.hideStyleDetailsSidebar();
  }

  hideColorsDetailsSidebar() {
    this.isColorsDetailsVisible = false;
  }

  protected onBlockHovered(styleKey: StyleKey | undefined) {
    this.hoveredStyle = styleKey;
  }

  protected onBlockUpdate() {
    this.isDataSaved = false;
  }

  protected onBlockInViewport(blockIndex: number) {
    this.searchHeadingBlock(blockIndex);
  }

  searchHeadingBlock(blockIndex: number) {
    for (let i = blockIndex; i >= 0; i--) {
      const blockData = this.editorItems[i].block;
      const blockText = this.editorItems[i].block.text;
      if (blockData.style.includes("header")) {
        const headingIndex = this.headings.findIndex((heading) => heading.blockId === blockData.id);
        if (headingIndex !== -1) {
          this.activeHeadingIndex = headingIndex;
          break;
        }
      }
    }
  }

  private hasTextInBlocks() {
    const blockWithText = this.editorItems.find(
      (block) =>
        block.block.data?.text ||
        (block.block.style === "list" && block.block.data.items) ||
        block.block.style === "table",
    );
    return Boolean(blockWithText);
  }

  // private hasTablesInMultiColumns() {
  //   const tableBlock = this.editorItems.find((block) => block.block.style === "table");
  //   const book = this.booksStore.getActiveBook();
  //   return (
  //     !book?.bookSettings?.isEditable &&
  //     book?.bookSettings?.columnsCount &&
  //     book?.bookSettings?.columnsCount > 1 &&
  //     Boolean(tableBlock)
  //   );
  // }

  async backgroundSave() {
    if (!this.form?.valid || this.isDataSaved) {
      return;
    }

    const result = await this.save();
    if (result === "success") {
      this.notificationService.notify({
        content: $localize`:@@books.notification.book-saved:`,
        type: "success",
        showOptsTimeout: 1,
      });
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  private initAutoSaving() {
    this.sub.add(
      interval(1000 * 60 * this.autoSaveTimeoutMinutes).subscribe(async (val) => {
        await this.backgroundSave();
      }),
    );
  }

  private clearFormData(formValue: any) {
    const styles = formValue.styles;
    for (const style of Object.values(styles)) {
      const styleSettings = style as StyleSettings;

      styleSettings.dropCapChars = 0;
      styleSettings.dropCapLines = 0;
      if (styleSettings.dropCap) {
        styleSettings.dropCapChars = 1;
        styleSettings.dropCapLines = 3;
      }

      if (styleSettings.indentParagraphAfterHeader) {
        styleSettings.dropCapChars = 0;
        styleSettings.dropCapLines = 0;
      }

      let font = formValue.mainFont ?? "PT Serif";
      if (styleSettings.styleKey?.includes("header")) {
        font = formValue.headerFont;
      }

      styleSettings.indentParagraphValue = 0;
      if (styleSettings.indentParagraph === "indented-line") {
        styleSettings.indentParagraphValue = this.marginsService.getMinimalColumnSize(
          "indented-line",
          styleSettings.fontSize ?? 10,
          font,
        );
      }

      styleSettings.dropCap = undefined;
    }
  }

  private updatePalette(book: Book) {
    this.activePalette = this.form?.get("palette")?.value;
  }

  protected isSettingsAvailable() {
    const book = this.booksStore.getActiveBook();
    return (this.hasPaidTariff || this.hasTrialPeriod) && !!book?.bookSettings?.isEditable;
  }

  protected isTocSettingsEnable() {
    const book = this.booksStore.getActiveBook();
    return !!book?.exportPrint && !!book?.bookSettings?.isEditable;
  }

  protected isBookEpub() {
    return !!this.booksStore.getActiveBook()?.exportEpub;
  }

  protected shouldDisplaySidebarTabs() {
    return this.isTocSettingsEnable();
  }

  async updateFonts(isBookSettingsEditable: boolean) {
    const fonts = await this.fontsService.getCompanyFonts();
    this.availableMainFonts = await this.markupForm.getAvailableMainFonts(fonts, isBookSettingsEditable);
    this.availableHeadersFonts = await this.markupForm.getAvailableHeaderFonts(fonts, isBookSettingsEditable);

    await this.loadFontsCss(fonts, isBookSettingsEditable);

    this.cdr.detectChanges();
  }

  async loadFontsCss(fonts: CompanyFont[], isBookSettingsEditable: boolean) {
    let filteredFonts: CompanyFont[] = [];
    filteredFonts = filteredFonts.concat(this.markupForm.getFilteredFonts(fonts, "mainText", isBookSettingsEditable));
    filteredFonts = filteredFonts.concat(this.markupForm.getFilteredFonts(fonts, "header", isBookSettingsEditable));
    for (const f of filteredFonts) {
      await this.fontsService.loadCompanyFontCss(f);
    }
  }

  private updateImagesSettings(book: Book) {
    if (book.bookSettings?.imagesSize) this.imageDefaultData.size = book.bookSettings?.imagesSize;
    if (book.bookSettings?.imagesCropClass) this.imageDefaultData.cropClass = book.bookSettings?.imagesCropClass;
    this.imageDefaultData.isImagesSettingsAvailable = !!book.bookSettings?.isImagesSettingsAvailable;
  }

  private updateToolbarSettings(book: Book) {
    if (!book.bookSettings) {
      return;
    }

    this.toolbarSettings.isAddTableAvailable = book.bookSettings.columnsCount === 1;
  }

  protected async onAiGenerationClick(data: TextGenerationDataDto) {
    const result = await this.textGenerationService.upgradeTextGeneration({
      mode: data.mode,
      prompt: data.prompt,
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHander(event: Event) {
    if (!this.isDataSaved) {
      event.returnValue = true;
    }
  }
}
