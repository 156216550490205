import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { RouterService, filterUndefined } from "@metranpage/core";
import { ActiveSubscription, PaymentCurrency, PromocodeDiscountData, Tariff } from "@metranpage/pricing-data";
import { Subscription } from "rxjs";
import { PricingViewService } from "../pricing.view-service";

type FeatureType =
  | "tokens_included"
  | "symbol_limits"
  | "image_generation"
  | "upload_limits"
  | "template_editing"
  | "layout_images"
  | "layout_tables"
  | "watermark"
  | "autopublish_in_community"
  | "output_pdf"
  | "output_epub"
  | "output_src_indd";

type FeatureDetails = {
  iconPath: string;
  iconColor?: string;
  description: string;
};

type TariffChangeDirection = "downgrade" | "refresh" | "upgrade";

@Component({
  selector: "m-pricing-tariff-card-2",
  templateUrl: "./tariff-card.view.html",
  styleUrls: ["./tariff-card.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffCard2View implements OnInit, OnChanges {
  @Input()
  tariff!: Tariff;
  @Input()
  activeSubscription?: ActiveSubscription;
  @Input()
  promocodeDiscountData?: PromocodeDiscountData;
  @Input()
  currency: PaymentCurrency = "RUB";

  @Output()
  onBuySubscriptionClick = new EventEmitter<Tariff>();

  features!: Record<FeatureType, FeatureDetails>;
  tariffChangeDirection?: TariffChangeDirection;
  priceWithoutDiscount?: string;
  discountPercent?: string;

  protected welcomeCreditsCount = 0;
  sub: Subscription = new Subscription();

  constructor(
    private readonly companyStore: CompanyStore,
    private readonly pricingViewService: PricingViewService,
    private readonly router: Router,
    private readonly routerService: RouterService,
  ) {
    this.sub.add(
      this.companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.welcomeCreditsCount = company.welcomeCreditsCount;
        }),
    );
  }

  ngOnInit(): void {
    // this.constructFeatures();

    this.constructDiscountStrings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeSubscription) {
      this.defineTariffChangeDirection();
    }

    if (changes.promocodeDiscountData) {
      this.constructDiscountStrings();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructDiscountStrings(): void {
    if (this.tariff.profit > 0) {
      const priceWithoutDiscount = this.tariff.price + this.tariff.profit;
      if (priceWithoutDiscount === 0) {
        return;
      }
      let discountPercent = 100 * (1 - this.tariff.price / priceWithoutDiscount);

      this.priceWithoutDiscount = this.pricingViewService.thousandsFormat(priceWithoutDiscount);
      this.discountPercent = `- ${Math.ceil(discountPercent)}%`;

      if (this.promocodeDiscountData) {
        discountPercent = discountPercent + this.promocodeDiscountData.discount;

        this.discountPercent = `- ${Math.ceil(discountPercent)}%`;
      }
    } else {
      if (this.promocodeDiscountData) {
        const priceWithoutDiscount = this.tariff.price;
        const discountPercent = this.promocodeDiscountData.discount;

        this.priceWithoutDiscount = this.pricingViewService.thousandsFormat(priceWithoutDiscount);
        this.discountPercent = `- ${Math.ceil(discountPercent)}%`;
      }
    }
  }

  protected defineTariffChangeDirection(): void {
    if (!this.activeSubscription) {
      this.tariffChangeDirection = "upgrade";
    } else if (this.activeSubscription.tariff.level === this.tariff.level && this.tariff.isFree) {
      this.tariffChangeDirection = undefined;
    } else if (this.activeSubscription.tariff.id === this.tariff.id && !this.tariff.isFree) {
      this.tariffChangeDirection = "refresh";
    } else if (
      this.tariff.level > this.activeSubscription.tariff.level ||
      (this.tariff.level === this.activeSubscription.tariff.level &&
        this.tariff.period > this.activeSubscription.tariff.period)
    ) {
      this.tariffChangeDirection = "upgrade";
    } else {
      this.tariffChangeDirection = "downgrade";
    }
  }

  protected getPrice() {
    let newPrice = this.tariff.price;

    const currencySymbol = this.pricingViewService.getCurrencySymbol(this.currency);

    if (this.promocodeDiscountData) {
      const priceWithoutDiscount = this.tariff.price + this.tariff.profit;
      if (priceWithoutDiscount === 0) {
        return this.pricingViewService.priceFormat(priceWithoutDiscount, currencySymbol);
      }
      const discountPercent = 100 * (1 - this.tariff.price / priceWithoutDiscount);
      newPrice =
        priceWithoutDiscount - (priceWithoutDiscount * (discountPercent + this.promocodeDiscountData.discount)) / 100;
    }
    return this.pricingViewService.priceFormat(newPrice, currencySymbol);
  }

  protected getTokensCount(): number {
    return this.tariff.isFree ? this.welcomeCreditsCount : this.tariff.creditsPerMonth * this.tariff.period;
  }

  protected hasPromocodeDiscount() {
    return this.tariff.price > 0 && !this.tariff.isFree && this.promocodeDiscountData;
  }

  protected getAccrualPeriodText() {
    if (!this.tariff.isFree && this.tariff.period === 1) {
      return $localize`:@@pricing.tariffs.accrual-period.payment-once-a-month:`;
    }
    if (!this.tariff.isFree && this.tariff.period === 6) {
      return $localize`:@@pricing.tariffs.accrual-period.payment-once-a-half-year:`;
    }
    if (!this.tariff.isFree && this.tariff.period === 12) {
      return $localize`:@@pricing.tariffs.accrual-period.payment-once-a-year:`;
    }
    return $localize`:@@pricing.tariffs.accrual-period.tokens-one-time:`;
  }

  protected onMoreAboutTariffClick() {
    this.router.navigateByUrl("payments/subscription");
  }
}
