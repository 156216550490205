import { moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  ApplyTemplateRequestDto,
  Book,
  BookCoverTemplate,
  Fill,
  GradientFill,
  GroupObject,
  ObjectsAlignment,
  PredefinedSvgObjects,
  SolidFill,
  SvgObject,
  TextCase,
  UploadGeneratedImageRequestDto,
} from "@metranpage/book-data";
import {
  BookCover,
  Color,
  CoverObject,
  CoverObjectType,
  EllipseObject,
  ImageObject,
  RectangleObject,
  ShapeObject,
  TextObject,
} from "@metranpage/book-data";
import { ColorConverterService, fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import {
  LoadingService,
  NotificationsPopUpService,
  RealtimeService,
  RewardsService,
  RewardsStore,
  UserRewardOneTime,
  filterUndefined,
} from "@metranpage/core";
import {
  FabulGenerationMode,
  FabulaGeneratedImage,
  FabulaImageGenerationResultUpdate,
  FabulaImageGenerationService,
  FabulaRemoveBackgroundDataDto,
  GeneratedImage,
  ImageGeneration,
  ImageGenerationPrices,
  ImageGenerationService,
  PublishedImageStore,
} from "@metranpage/image-generation";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { CoverConceptualGenerationDataDto, TextGenerationService } from "@metranpage/text-generation";
import { ThemeService } from "@metranpage/theme";
import { User, UserBalance, UserStore } from "@metranpage/user-data";
import { BalanceData } from "@metranpage/user-payment-data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { instanceToPlain, plainToInstance } from "class-transformer";
import {
  util,
  ActiveSelection,
  BasicTransformEvent,
  Canvas,
  Ellipse,
  FabricImage,
  FabricObject,
  Gradient,
  Group,
  Line,
  Point,
  Rect,
  Shadow,
  TEvent,
  TPointerEvent,
  TPointerEventInfo,
  Textbox,
  loadSVGFromURL,
} from "fabric";
import {
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  from,
  fromEvent,
  map,
  of,
  switchMap,
  tap,
  timer,
} from "rxjs";
import { COVER_TEXT_STORAGE } from "../../book.module";
import { CoverFontsService } from "../../services/cover/cover-fonts.service";
import { CoverTemplateStore } from "../../services/cover/cover-template.store";
import { CoverUiService, UpdateGroupParam } from "../../services/cover/cover-ui.service";
import { CoverService } from "../../services/cover/cover.service";
import { CoverTextStorage } from "../../services/cover/text-storage/cover-text-storage";
import { FontsWithColorData } from "../cover-conceptual-assistant-generation-result/cover-conceptual-assistant-generation-result.view";
import { CreateCoverObject } from "../cover-object-create/cover-object-create.component";
import DataHelper from "./helpers/data-helper";
import { FabricCustomizer } from "./helpers/fabric-customizer";
import { SelectionManager } from "./helpers/selection-helper";
import { Guidelines, Snapper } from "./helpers/snapper";
import { BookCoverState, BookCoverStateOptions, SimpleUndoRedo } from "./helpers/undo-redo";
type ObjectCreatedModel = { objects: ObjectShape[]; correctionsApplied: boolean };

type EditingMode = "common" | "pan";

const defaultText = "Текст всякий там";
const defaultImageUrl = "https://img.freepik.com/premium-vector/geometric-abstract-vertical-background_697972-1515.jpg";
const defaultFontFamily = "Roboto";

const colorActive = "#E02379";
const snapTolerance = 8;
const gridSize = 8;
const shapAngle = 15;
const safeMarginValue = (1 / 2.54) * 72;
const safeMarginWidth = 2;
const zoomByWheel = true;
const maskOuterStrokeWidth = 10000;
const maskInnerStrokeWidth = 2;

const coverFullsizeImageMultiplier = 5;
const coverPreviewImageMultiplier = 2;

export type ObjectShape = (TextObject | ImageObject | RectangleObject | EllipseObject | SvgObject | GroupObject) & {
  shape?: FabricObject;
};

type ViewMode = "editing" | "preview" | "final";
type SidebarMode =
  | "object-settings"
  | "multiselect-settings"
  | "object-create"
  | "template-list"
  | "conceptual-assistant";

type ObjectSize = { width: number; height: number };
type RescaleFitMode = "contain" | "cover" | "horizontally" | "vertically";

type Position = { x: number; y: number };

type CoverObjectRole = "title" | "subtitle" | "author";

@UntilDestroy()
@Component({
  selector: "m-cover-view",
  templateUrl: "./cover.view.html",
  styleUrls: ["./cover.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutOnEnterLeave, slideInOutVertical],
})
export class CoverView implements OnChanges, AfterViewInit, OnDestroy, OnInit {
  @Input() book!: Book;
  @Input() cover!: BookCover;

  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() processing: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("wrapperRef") wrapperRef!: ElementRef;
  @ViewChild("canvasRef") canvasRef!: ElementRef;

  user!: User;

  stage!: Canvas;

  objects!: ObjectShape[];
  panSelectedObjects?: ObjectShape[] = [];
  selectionManager!: SelectionManager;

  coverBaseRect!: Rect;
  coverSafeMarginsRect!: Rect;
  maskOuterRect!: Rect;
  maskInnerRect!: Rect;
  maskOuterRectStrokeColor = "rgba(41, 43, 75, 0.64)";
  maskInnerRectStrokeColor = "#484A73";

  guidelines: Line[] = [];

  resizeObserver!: ResizeObserver;

  snapper: Snapper = new Snapper(snapTolerance);

  viewMode: ViewMode = "editing";

  fontsLoaded: FontFace[] = [];

  predefinedSvgObjects: PredefinedSvgObjects[] = [];

  isCompletionModalVisible = false;
  isImageSelectionModalVisible = false;
  sidebarMode: SidebarMode = "object-create";

  undoRedo: SimpleUndoRedo = new SimpleUndoRedo();

  panPrevPoint?: Point;
  editingMode: EditingMode = "common";
  isPanning = false;

  isMouseInsideCanvas = true;

  isEyeDropperActive = false;
  eyeDropperSelectedColor?: Color;
  eyeDropperPosition?: Position;
  onEyeDroperSelected?: (color: Color) => void;

  protected isImageGeneratorVisible = false;

  protected isShareModalVisible = false;

  protected imageSize = {
    width: 148,
    height: 210,
  };

  protected processingImageObject: ImageObject | undefined = undefined;
  protected processingImageGenenerationId: number | undefined = undefined;

  protected isLowBalanceModalVisible = false;
  tariffsForUpgrade$!: Observable<Tariff[]>;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected balance!: UserBalance;
  protected imageGenerationPaymentData!: BalanceData;
  protected prices?: ImageGenerationPrices;
  protected imageGenerationMode: FabulGenerationMode | undefined = undefined;

  protected rewardsOneTime: UserRewardOneTime[] = [];

  sub: Subscription = new Subscription();

  constructor(
    private readonly coverService: CoverService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly themeService: ThemeService,
    private readonly userStore: UserStore,
    private readonly coverTemplateStore: CoverTemplateStore,
    private readonly loadingService: LoadingService,
    @Inject(COVER_TEXT_STORAGE) private readonly textStorageService: CoverTextStorage,
    private readonly onboardingService: OnboardingService,
    private readonly coverUiService: CoverUiService,
    private readonly textGenerationService: TextGenerationService,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly colorConverter: ColorConverterService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly coverFontsService: CoverFontsService,
    realtimeService: RealtimeService,
    private readonly destroyRef: DestroyRef,
    private readonly fabulaImageGenerationService: FabulaImageGenerationService,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly pricingService: PricingService,
    rewardsStore: RewardsStore,
    private readonly rewardsService: RewardsService,
  ) {
    realtimeService
      .getEvents<BookCover>("book-cover-state")
      .pipe(filterUndefined())
      .subscribe((bookCoverUpdate: BookCover) => {
        if (this.cover.id === bookCoverUpdate.id) {
          this.cover = bookCoverUpdate;
          this.changeDetector.markForCheck();
        }
      });

    realtimeService
      .getEvents<FabulaImageGenerationResultUpdate>("image-enhancement-result")
      .pipe(filterUndefined())
      .subscribe(async (imageGenerationUpdate: FabulaImageGenerationResultUpdate) => {
        if (imageGenerationUpdate.isError) {
          this.notifyOnImageGenerationError(imageGenerationUpdate.mode);
        }

        if (this.processingImageGenenerationId !== imageGenerationUpdate.imageGeneration.id) {
          return;
        }
        await this.processGeneratedImages(imageGenerationUpdate.imageGeneration.generatedImages);
        this.onProccesingData(false);
      });

    this.coverUiService.updateGroup$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((v) => {
      this.updateGroup(v);
    });

    this.coverUiService.renameObject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((v) => {
      this.renameObject(v);
    });

    this.loadFonts();
    this.loadPredefinedSvgShapes();
    this.watchTheme();
    this.getUser();
    this.getUserBalance();

    this.loadImageGenerationPrices();

    const customizer = new FabricCustomizer();
    customizer.setRotationIcon();

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );
  }

  ngOnInit() {
    this.imageSize = {
      width: this.cover.width || 148,
      height: this.cover.height || 210,
    };
    this.stage;
  }

  get viewModeIcon() {
    if (this.viewMode === "editing") {
      return "/assets/icons/frame-01.svg";
    }
    return "/assets/icons/frame-no-01.svg";
  }

  get currentObject(): ObjectShape | undefined {
    return this.selectionManager?.currentObject;
  }

  get selectedObjects(): ObjectShape[] {
    return this.selectionManager?.selectedObjects ?? [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.undoRedo.reset();
    this.checkCoverData();
    this.saveCoverState();
    if (!changes.cover.firstChange) {
      this.onCover();
    }

    this.loadLastCoverConceptualGeneration();
  }

  getUser() {
    this.sub.add(
      this.userStore
        .getUserObservable()
        .pipe(filterUndefined())
        .subscribe((user) => {
          this.user = user;
        }),
    );
  }

  getUserBalance() {
    this.sub.add(
      this.userStore
        .getBalanceObservable()
        .pipe(filterUndefined())
        .subscribe((balance) => {
          this.balance = balance;
        }),
    );
  }

  getActiveSubscription() {
    this.sub.add(
      this.userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();
      }),
    );
  }

  watchTheme() {
    this.themeService.changeEvents$.pipe(untilDestroyed(this)).subscribe((v) => {
      console.log("themeService.changeEvents$", v);
      this.maskOuterRectStrokeColor = v === "dark" ? "rgba(41, 43, 75, 0.64)" : "rgba(255, 255, 255, 0.64)";
      this.maskInnerRectStrokeColor = v === "dark" ? "#484A73" : "#E1E1EB";
      const maskOuterRect = this.stage?.getObjects().find((i: any) => {
        i.name === "maskOuterRect";
      });
      const maskInnerRect = this.stage?.getObjects().find((i: any) => {
        i.name === "maskInnerRect";
      });
      if (maskOuterRect) {
        maskOuterRect.stroke = this.maskOuterRectStrokeColor;
        console.log("maskOuterRect", maskOuterRect);
      }
      if (maskInnerRect) {
        maskInnerRect.stroke = this.maskInnerRectStrokeColor;
        console.log("maskInnerRect", maskInnerRect);
      }
      if (maskOuterRect && maskInnerRect) {
        console.log("renderAll");
        maskOuterRect.render(this.stage.getContext());
        maskInnerRect.render(this.stage.getContext());
        this.stage.renderAll();
      }
    });
  }

  loadPredefinedSvgShapes() {
    from(this.coverService.getPredefinedSvgObjects()).subscribe((v) => {
      this.predefinedSvgObjects = v;
    });
  }

  loadFonts() {
    const fonts$ = this.coverFontsService.getCompleteSet();
    fonts$.pipe(switchMap((v) => this.coverFontsService.loadFontFaces(v))).subscribe((v) => {
      v.sort((a, b) => a.family.localeCompare(b.family));
      this.fontsLoaded = v;
    });
  }

  constructGroupIds() {
    const groups = this.cover.objects?.filter((i) => i instanceof GroupObject);
    if (!groups) {
      return;
    }
    groups.forEach((v, i) => {
      (<GroupObject>v).groupId = i.toString();
    });
  }

  async onCover(alertIfNoImage = true, showLoader = true, loadTextFromStorage = false) {
    this.constructGroupIds();
    if (loadTextFromStorage) {
      await this.textStorageService.toCoverAsync(this.cover);
    }
    this.constructStage(this.cover.objects ?? [], alertIfNoImage, showLoader);
  }

  saveCover() {
    const dto = instanceToPlain(this.cover, { excludeExtraneousValues: true });
    this.textStorageService.fromCoverAsync(this.cover);
    this.coverService.updateCover(this.book.id, dto);
    this.saveCoverPreviewImage();
  }

  uploadCoverImage(data: Blob, fullsize: boolean) {
    const file = DataHelper.blobToFile(data, "cover.png");
    return this.coverService.uploadImage(this.book.id, file, fullsize ? "cover-fullsize" : "cover-preview");
  }

  getCoverImageBlob(multiplier: number): Blob {
    const currentViewMode = this.viewMode;

    this.setViewMode("final");

    const dimensions = { width: this.stage.getWidth(), height: this.stage.getHeight() };
    const zoom = this.stage.getZoom();
    const viewportTransform = this.stage.viewportTransform;

    this.stage.setZoom(1);
    this.stage.setDimensions({
      width: this.coverBaseRect.width! * this.stage.getZoom(),
      height: this.coverBaseRect.height! * this.stage.getZoom(),
    });

    this.stage.absolutePan(
      new Point({
        x: this.coverBaseRect.left!,
        y: this.coverBaseRect.top!,
      }),
    );

    const base64 = this.stage.toDataURL({
      multiplier,
    });

    this.stage.setZoom(zoom);
    this.stage.setDimensions(dimensions);
    this.centerStage();

    this.stage.setViewportTransform(viewportTransform!);

    this.setViewMode(currentViewMode);

    const blob = DataHelper.base64ToBlob(base64, "image/png");
    return blob;
  }

  constructCoverBaseShapeAndMask() {
    this.coverBaseRect = new Rect({
      /* originX: "center",
      originY: "center",
      left: this.cover.width / 2,
      top: this.cover.height / 2, */
      originX: "left",
      originY: "top",
      left: 0,
      top: 0,
      width: this.cover.width,
      height: this.cover.height,
      strokeWidth: 0,
      fill: this.cover.backgroundColor?.toCss(),
      selectable: false,
      evented: false,
      name: "coverBaseRect",
      objectCaching: false,
      absolutePositioned: true,
    });

    this.coverSafeMarginsRect = new Rect({
      left: safeMarginValue,
      top: safeMarginValue,
      width: this.cover.width! - safeMarginValue * 2 - safeMarginWidth,
      height: this.cover.height! - safeMarginValue * 2 - safeMarginWidth,
      fill: undefined,
      strokeWidth: safeMarginWidth,
      stroke: colorActive,
      selectable: false,
      evented: false,
      strokeDashArray: [4, 4],
      strokeUniform: true,
      name: "safeMarginRect",
      objectCaching: false,
    });

    this.stage.add(this.coverBaseRect);
    this.stage.sendObjectToBack(this.coverBaseRect);

    this.stage.add(this.coverSafeMarginsRect);
    this.stage.bringObjectToFront(this.coverSafeMarginsRect);

    this.maskOuterRect = new Rect({
      left: 0 - maskOuterStrokeWidth,
      top: 0 - maskOuterStrokeWidth,
      width: this.cover.width! + maskOuterStrokeWidth,
      height: this.cover.height! + maskOuterStrokeWidth,
      selectable: false,
      evented: false,
      name: "maskOuterRect",
      fill: "rgba(0,0,0,0)",
      strokeWidth: maskOuterStrokeWidth,
      stroke: this.maskOuterRectStrokeColor,
      objectCaching: false,
    });

    this.maskInnerRect = new Rect({
      left: 0 - maskInnerStrokeWidth,
      top: 0 - maskInnerStrokeWidth,
      width: this.cover.width! + maskInnerStrokeWidth,
      height: this.cover.height! + maskInnerStrokeWidth,
      selectable: false,
      evented: false,
      name: "maskInnerRect",
      fill: "rgba(0,0,0,0)",
      strokeWidth: maskInnerStrokeWidth,
      stroke: this.maskInnerRectStrokeColor,
      objectCaching: false,
    });

    this.stage.add(this.maskOuterRect);
    this.stage.add(this.maskInnerRect);
    // заменили на bringObjectToFront(). Есть только у Group
    this.stage.bringObjectToFront(this.maskOuterRect);
    this.stage.bringObjectToFront(this.maskInnerRect);

    this.centerStage();
  }

  toggleViewMode() {
    this.setViewMode(this.viewMode === "editing" ? "preview" : "editing");
  }

  setViewMode(mode: ViewMode) {
    this.viewMode = mode;
    this.coverSafeMarginsRect.visible = mode === "editing";
    this.maskOuterRect.visible = mode === "editing";
    this.maskInnerRect.visible = mode !== "final";

    const objects = this.stage.getObjects().filter((v: any) => {
      v.name === "object";
    });
    for (const object of objects) {
      object.clipPath = mode === "editing" ? undefined : this.coverBaseRect;
      object.dirty = true;
    }

    this.stage.renderAll();
  }

  centerStage() {
    if (this.cover) {
      this.stage.absolutePan(
        new Point({
          x: -(this.stage.width! - this.cover.width! * this.stage.getZoom()) / 2,
          y: -(this.stage.height! - this.cover.height! * this.stage.getZoom()) / 2,
        }),
      );
    }
  }

  getCoverFontFamilies(): Observable<FontFace[]> {
    if (!this.cover.objects) {
      return of([]);
    }
    const families: string[] = [];
    for (const o of this.cover.objects) {
      if (o instanceof TextObject && !families.some((i) => i === o.fontFamily) && o.fontFamily) {
        families.push(o.fontFamily);
      }
    }
    return this.coverFontsService.getSubset(families);
  }

  constructStage(objects: ObjectShape[], alertIfNoImage = true, showLoader = true) {
    if (showLoader) {
      this.loadingService.startLoading({ fullPage: true });
    }

    this.changeDetector.markForCheck();

    this.objects = [];
    this.selectionManager.clear();

    this.stage.clear();

    (this.fontsLoaded.length > 0
      ? of(this.fontsLoaded)
      : this.getCoverFontFamilies().pipe(switchMap((v) => this.coverFontsService.loadFontFaces(v)))
    )
      .pipe(switchMap(() => this.createObjectShapes([...objects])))
      .subscribe((v) => {
        this.objects = v.objects.sort((a, b) => a.zIndex - b.zIndex);
        this.addObjectsToStage(v.objects);
        this.constructCoverBaseShapeAndMask();
        this.setViewMode(this.viewMode);
        this.saveCoverPreviewImage();
        this.loadingService.stopLoading();
        this.changeDetector.markForCheck();

        if (alertIfNoImage && !this.cover.objects?.some((v) => v instanceof ImageObject)) {
          this.isImageSelectionModalVisible = true;
        }

        if (v.correctionsApplied) {
          this.saveCover();
        }
      });
  }

  addObjectsToStage(objects: ObjectShape[]) {
    this.stage.add(...objects.map((v) => v.shape!));
  }

  createObjectShapes(objects: CoverObject[], resizeImages = false): Observable<ObjectCreatedModel> {
    return new Observable<ObjectCreatedModel>((observer) => {
      const result = <ObjectCreatedModel>{
        objects,
        correctionsApplied: false,
      };
      if (objects.length === 0) {
        observer.next(result);
        observer.complete();
      }
      let shapesCreated = 0;

      for (const o of objects) {
        let shape: FabricObject;

        if (o instanceof ImageObject) {
          let url = defaultImageUrl;
          if (o.imageUrl && o.imageUrl !== "default") {
            url = this.coverService.getObjectImageUrl(this.book.id, o.imageUrl);
          }
          FabricImage.fromURL(
            url,

            {
              crossOrigin: "anonymous",
            },
          ).then((image: any) => {
            shape = image;
            this.initializeObjectShape(o, shape, resizeImages);
            if ((!o.width || !o.height) && shape.width && shape.height) {
              o.width = shape.width;
              o.height = shape.height;
              result.correctionsApplied = true;
            }
            shapesCreated++;
            if (shapesCreated === objects.length) {
              observer.next(result);
              observer.complete();
            }
          });
        } else if (o instanceof SvgObject) {
          if (!o.imageUrl) {
            throw new Error("SvgObject must have imageUrl set");
          }
          loadSVGFromURL(o.imageUrl, undefined, {
            crossOrigin: "anonymous",
          }).then(({ objects, options }) => {
            const image = util.groupSVGElements(objects as FabricObject[], options);
            shape = image;
            this.initializeObjectShape(o, shape, resizeImages);
            if ((!o.width || !o.height) && shape.width && shape.height) {
              o.width = shape.width;
              o.height = shape.height;
              result.correctionsApplied = true;
            }
            shapesCreated++;
            if (shapesCreated === objects.length) {
              observer.next(result);
              observer.complete();
            }
          });
        } else if (o instanceof GroupObject) {
          shape = new Group();
          this.createObjectShapes(o.objects, resizeImages).subscribe((v) => {
            const shapes = v.objects.map((i) => (i as ObjectShape).shape!);

            for (const s of shapes) {
              (shape as Group).add(s);
            }

            //shape = new fabric.Group(shapes);

            this.initializeObjectShape(o, shape, resizeImages);
            shapesCreated++;
            if (shapesCreated === objects.length) {
              observer.next(result);
              observer.complete();
            }
          });
        } else {
          if (o instanceof TextObject) {
            shape = new Textbox(o.text ?? defaultText);
          } else if (o instanceof RectangleObject) {
            shape = new Rect();
          } else if (o instanceof EllipseObject) {
            shape = new Ellipse();
          } else {
            throw new Error("Unsupported object type");
          }

          this.initializeObjectShape(o, shape, resizeImages);
          shapesCreated++;
          if (shapesCreated === objects.length) {
            observer.next(result);
            observer.complete();
          }
        }
      }
    });
  }

  updateObjectFromShape(object: ObjectShape, shape: FabricObject) {
    const selection = shape.group;

    const matrix = shape.calcTransformMatrix();
    const options = util.qrDecompose(matrix);

    let x = shape?.left;
    let y = shape?.top;

    if (selection && shape.aCoords) {
      const groupmatrix = selection.calcTransformMatrix();
      const point = util.transformPoint(new Point(shape.aCoords.tl.x, shape.aCoords.tl.y), groupmatrix);
      x = point.x;
      y = point.y;
    }

    object.x = x;
    object.y = y;

    object.rotationAngle = options.angle;

    /* object.scaleX = Math.abs(options.scaleX);
    object.scaleY = Math.abs(options.scaleY); */

    /* object.scaleX = shape.scaleX;
    object.scaleY = shape.scaleY;
    object.skewX = shape.skewX;
    object.skewY = shape.skewY; */

    object.scaleX = options.scaleX;
    object.scaleY = options.scaleY;
    object.skewX = options.skewX;
    object.skewY = options.skewY;

    if (object instanceof TextObject && shape instanceof Textbox) {
      object.lineHeight = ((shape.lineHeight ?? 0) === 0 ? 1 : shape.lineHeight!) * 100;
      object.fontSize = (shape.fontSize ?? 0) === 0 ? 24 : shape.fontSize!;
      object.width = shape.width;
    } else if (object instanceof ShapeObject) {
      object.width = shape.width;
      object.height = shape.height;
    }
  }

  get isGradientApplicable(): boolean {
    const object = this.currentObject;
    if (!object) {
      return false;
    }
    if (object instanceof SvgObject && object.shape instanceof Group) {
      return false;
    }
    return true;
  }

  updateShapeFromObject(shape: FabricObject, object: ObjectShape) {
    if (shape instanceof Textbox) {
      if (shape.isEditing) {
        shape.exitEditing();
      }
    }

    if (!shape.group) {
      shape.rotate(object.rotationAngle ?? 0);
      shape.scaleX = object.scaleX ?? 1;
      shape.scaleY = object.scaleY ?? 1;
      shape.skewX = object.skewX ?? 0;
      shape.skewY = object.skewY ?? 0;
      shape.left = object.x ?? 0;
      shape.top = object.y ?? 0;
    }

    shape.opacity = (object.opacity ?? 100) / 100;

    shape.lockMovementX = object.isLocked || false;
    shape.lockMovementY = object.isLocked || false;
    shape.lockRotation = object.isLocked || false;
    shape.lockScalingFlip = object.isLocked || false;
    shape.lockScalingX = object.isLocked || false;
    shape.lockScalingY = object.isLocked || false;
    shape.lockSkewingX = object.isLocked || false;
    shape.lockSkewingY = object.isLocked || false;

    shape.visible = object.isVisible;

    if (object instanceof TextObject && shape instanceof Textbox) {
      shape.text =
        object.textCase && object.text
          ? object.textCase === TextCase.Auto
            ? object.text
            : this.updateCase(object.text, object.textCase)
          : object.text || "";
      shape.fontSize = (object.fontSize ?? 0) === 0 ? 24 : object.fontSize!;
      shape.lineHeight = ((object.lineHeight ?? 0) === 0 ? 100 : object.lineHeight!) / 100;
      shape.charSpacing = object.letterSpacing ?? 0;
      shape.textAlign = object.textAlign ?? "left";
      shape.fontFamily = object.fontFamily ?? defaultFontFamily;

      shape.underline = object.underline || false;
      shape.fontWeight = object.bold ? "bold" : "normal";
      shape.fontStyle = object.italic ? "italic" : "normal";
      shape.linethrough = object.linethrough || false;

      clearFabricFontCache(shape.fontFamily);

      if (!object.width) {
        this.applyOptimalTextboxWidth(shape);
      } else {
        shape.width = object.width;
      }
      if (object.shadow) {
        const shadow = new Shadow({
          blur: object.shadow.blur && object.shadow.blur / 10,
          offsetX: object.shadow.offset && object.shadow.offset * Math.cos((object.shadow.direction! * Math.PI) / 180),
          offsetY: object.shadow.offset && object.shadow.offset * Math.sin((object.shadow.direction! * Math.PI) / 180),
          color: object.shadow.color,
        });

        shape.shadow = shadow;
      }

      this.applyFill(shape, object.fill);
    } else if (object instanceof ShapeObject) {
      if (!(object instanceof SvgObject)) {
        shape.width = object.width ?? 0;
        shape.height = object.height ?? 0;
      }

      shape.strokeWidth = (object.strokeWidth ?? 0) / 1;
      shape.stroke = (object.strokeFill as SolidFill)?.color?.toCss();

      if (object instanceof EllipseObject && shape instanceof Ellipse) {
        shape.rx = object.width! / 2;
        shape.ry = object.height! / 2;
      }

      if (object instanceof RectangleObject && shape instanceof Rect) {
        shape.rx = object.cornerRadius ?? 0;
        shape.ry = object.cornerRadius ?? 0;
      }

      if (object instanceof SvgObject && shape instanceof Group) {
        for (const s of shape.getObjects()) {
          s.strokeWidth = (object.strokeWidth ?? 0) / 1;
          s.stroke = (object.strokeFill as SolidFill)?.color?.toCss();
          this.applyFill(s, object.fill);
        }
      }

      this.applyFill(shape, object.fill);
    } else if (object instanceof GroupObject) {
      for (const o of object.objects) {
        const os = o as ObjectShape;
        this.updateShapeFromObject(os.shape!, os);
      }
    }
  }

  private updateCase(text: string, textCase: TextCase): string {
    if (textCase === TextCase.Upper) {
      return text.replace(/[a-z]/g, (char) => char.toUpperCase());
    }
    if (textCase === TextCase.Lower) {
      return text.replace(/[A-Z]/g, (char) => char.toLowerCase());
    }
    return text;
  }

  private checkCoverData() {
    if (!this.cover.objects) {
      return;
    }
    for (const object of this.cover.objects) {
      if (object.isVisible === undefined) {
        object.isVisible = true;
      }
    }
  }

  private applyOptimalTextboxWidth(shape: Textbox): void {
    // shape.width = shape.dynamicMinWidth ? shape.dynamicMinWidth * 2 : shape.width;
    /* if (!shape.left || !shape.width || !this.cover.width) {
      return;
    }

    let iters = 0;
    const originalWidth = shape.width;
    while (shape.textLines.length > 1 && shape.width <= this.cover.width - shape.left) {
      shape.set({ width: shape.getScaledWidth() + 1 });
      if (++iters > 10) {
        shape.set({ width: originalWidth });
        break;
      }
    } */
    /* if (!shape.left || !shape.width || !this.cover.width) {
      return;
    }
    while (shape.textLines.length > 1 && shape.width <= this.cover.width - shape.left) {
      shape.set({ width: shape.getScaledWidth() + 1 });
    } */
  }

  applyFill(shape: FabricObject, fill: Fill) {
    if (fill instanceof SolidFill) {
      shape.fill = fill.color.toCss();
    } else if (fill instanceof GradientFill) {
      const gradient = fill.gradient;
      if (gradient.colorStops.length === 0) {
        return;
      }

      // let coords: fabric.IGradientOptionsCoords;
      let coords: any;

      if (gradient.type === "linear") {
        coords = {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 0,
        };
      } else {
        coords = {
          x1: 0.5,
          y1: 0.5,
          x2: 0.5,
          y2: 0.5,
          r1: 0,
          r2: 0.5,
        };
      }

      const colorsCount = gradient.colorStops.length;

      shape.fill = new Gradient({
        type: gradient.type,
        gradientUnits: "percentage",
        coords,
        colorStops: [
          ...gradient.colorStops.map((v, i) => ({
            offset: i / (colorsCount - 1),
            color: v.color.toCss(),
          })),
        ],
      }) as FabricObject["fill"];
    }
  }

  setCurrentObjects(objects: ObjectShape[]) {
    this.selectionManager.selectObjects(objects, true);
  }

  createObject(event: CreateCoverObject, needToStopLoading = true) {
    const length = this.objects.length;
    let coverObject: CoverObject | undefined = undefined;
    if (event.type === CoverObjectType.Text) {
      const textObject = new TextObject();
      textObject.id = undefined;
      textObject.name = `Text ${length}`;
      textObject.x = 10;
      textObject.y = 20;
      textObject.text = `Text ${length}`;
      textObject.fontFamily = defaultFontFamily;
      textObject.fontSize = 24;
      textObject.lineHeight = 100;
      textObject.letterSpacing = 0;
      textObject.textAlign = "left";
      textObject.fill = new SolidFill();
      textObject.zIndex = length + 1;

      coverObject = textObject;
    } else if (event.type === CoverObjectType.Image) {
      const imageObject = new ImageObject();
      imageObject.id = undefined;
      imageObject.name = `Image ${length}`;
      imageObject.x = 0;
      imageObject.y = 0;
      imageObject.imageUrl = event.imageName!;

      imageObject.zIndex = this.cover.objects?.some((v) => v instanceof ImageObject) ? length + 1 : 0;
      console.log("imageObject", imageObject);
      console.log("imageObject.zIndex", imageObject.zIndex);

      coverObject = imageObject;
    } else if (event.type === CoverObjectType.Rectangle) {
      const rectangleObject = new RectangleObject();
      rectangleObject.id = undefined;
      rectangleObject.name = `Rectangle ${length}`;
      rectangleObject.x = 10;
      rectangleObject.y = 20;
      rectangleObject.width = 100;
      rectangleObject.height = 100;
      rectangleObject.fill = new SolidFill();
      rectangleObject.strokeWidth = 0;
      rectangleObject.strokeFill = new SolidFill();
      rectangleObject.zIndex = length + 1;

      coverObject = rectangleObject;
    } else if (event.type === CoverObjectType.Ellipse) {
      const ellipseObject = new EllipseObject();
      ellipseObject.id = undefined;
      ellipseObject.name = `Ellipse ${length}`;
      ellipseObject.x = 10;
      ellipseObject.y = 20;
      ellipseObject.width = 100;
      ellipseObject.height = 100;
      ellipseObject.fill = new SolidFill();
      ellipseObject.strokeWidth = 0;
      ellipseObject.strokeFill = new SolidFill();
      ellipseObject.zIndex = length + 1;

      coverObject = ellipseObject;
    } else if (event.type === CoverObjectType.SVG) {
      const svgObject = new SvgObject();
      svgObject.id = undefined;
      svgObject.name = `Shape ${length}`;
      svgObject.x = 10;
      svgObject.y = 20;
      svgObject.fill = new SolidFill();
      svgObject.strokeWidth = 0;
      svgObject.strokeFill = new SolidFill();
      svgObject.imageUrl = event.svgData;
      svgObject.zIndex = length + 1;

      coverObject = svgObject;
    }
    if (coverObject) {
      this.loadingService.startLoading({ fullPage: true });
      this.cover.objects?.push(coverObject);
      this.createObjectShapes([coverObject], true).subscribe((v) => {
        this.objects.push(...v.objects);
        this.addObjectsToStage(v.objects);
        this.stage.setActiveObject(v.objects[0].shape!);
        this.stage.bringObjectToFront(this.coverSafeMarginsRect);
        this.stage.bringObjectToFront(this.maskOuterRect);
        this.stage.bringObjectToFront(this.maskInnerRect);

        if (coverObject?.zIndex === 0) {
          this.objects.sort((a, b) => a.zIndex - b.zIndex);
          this.stage.sendObjectToBack(v.objects[0].shape!);
          this.stage.bringObjectForward(v.objects[0].shape!);
          this.stage.sendObjectToBack(this.coverBaseRect);
        }

        this.stage.renderAll();
        this.saveCover();
        this.saveCoverState();
        if (needToStopLoading) {
          this.loadingService.stopLoading();
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  deleteObjects(objects: ObjectShape[]) {
    this.stage.discardActiveObject();
    this.stage.remove(...objects.map((v) => v.shape!));

    this.cover.objects = this.cover.objects?.filter((v) => !objects.some((o) => o === v));
    this.objects = this.objects?.filter((v) => !objects.some((o) => o === v));
    this.saveCover();
    this.saveCoverState();
  }

  updateObject(object: ObjectShape) {
    this.updateShapeFromObject(object.shape!, object);
    object.shape!.dirty = true;
    this.stage.renderAll();
    this.saveCoverState();
    this.saveCover();
  }

  reorderObjects(objects: ObjectShape[]) {
    objects.forEach((v, i) => {
      v.zIndex = i + 1;
      this.stage.moveObjectTo(v.shape!, i + 1);
    });
    this.saveCover();
    this.saveCoverState();
  }

  renameObject(object: ObjectShape) {
    object.isNameModifiedByUser = true;
    this.saveCover();
    this.saveCoverState();
  }

  cloneObject(object: CoverObject) {
    const coverObject: CoverObject = plainToInstance(Object.getPrototypeOf(object).constructor, object, {
      excludeExtraneousValues: true,
    });
    const length = this.objects.length;
    coverObject.id = undefined;
    coverObject.x! += 10;
    coverObject.y! += 10;
    coverObject.zIndex = length + 1;
    coverObject.name += " [copy]";
    if (coverObject) {
      this.loadingService.startLoading({ fullPage: true });
      this.cover.objects?.push(coverObject);
      this.createObjectShapes([coverObject], false).subscribe((v) => {
        this.objects.push(...v.objects);
        this.addObjectsToStage(v.objects);
        this.stage.setActiveObject(v.objects[0].shape!);
        this.stage.bringObjectToFront(this.coverSafeMarginsRect);
        this.stage.bringObjectToFront(this.maskOuterRect);
        this.stage.bringObjectToFront(this.maskInnerRect);
        this.saveCover();
        this.saveCoverState();
        this.loadingService.stopLoading();
      });
    }
  }

  private alignRelativeTo(objectsToAlign: FabricObject[], relativeToObject: FabricObject, alignment: ObjectsAlignment) {
    const selection = relativeToObject;
    if (!selection) {
      return;
    }
    const shapes = objectsToAlign;

    for (const shape of shapes) {
      const sc = shape.getCenterPoint();
      const bb = shape.getBoundingRect();
      if (shapes.length > 1) {
        if (alignment === "center") {
          shape.setPositionByOrigin(new Point(0, sc.y), "center", "center");
        } else if (alignment === "middle") {
          shape.setPositionByOrigin(new Point(sc.x, 0), "center", "center");
        } else if (alignment === "center-middle") {
          shape.setPositionByOrigin(new Point(0, 0), "center", "center");
        } else if (alignment === "left") {
          shape.setPositionByOrigin(
            new Point(-selection.getScaledWidth() / 2 + bb.width / 2, sc.y),
            "center",
            "center",
          );
        } else if (alignment === "right") {
          shape.setPositionByOrigin(new Point(selection.getScaledWidth() / 2 - bb.width / 2, sc.y), "center", "center");
        } else if (alignment === "top") {
          shape.setPositionByOrigin(
            new Point(sc.x, -selection.getScaledHeight() / 2 + bb.height / 2),
            "center",
            "center",
          );
        } else if (alignment === "bottom") {
          shape.setPositionByOrigin(
            new Point(sc.x, selection.getScaledHeight() / 2 - bb.height / 2),
            "center",
            "center",
          );
        }
        shape.setCoords();
      } else {
        const bb = shape.getBoundingRect();
        if (alignment === "center") {
          shape.setPositionByOrigin(
            new Point(selection.left! + selection.getScaledWidth() / 2, sc.y),
            "center",
            "center",
          );
        } else if (alignment === "middle") {
          shape.setPositionByOrigin(
            new Point(sc.x, selection.top! + selection.getScaledHeight() / 2),
            "center",
            "center",
          );
        } else if (alignment === "center-middle") {
          shape.setPositionByOrigin(
            new Point(
              selection.left! + selection.getScaledWidth() / 2,
              selection.top! + selection.getScaledHeight() / 2,
            ),
            "center",
            "center",
          );
        } else if (alignment === "left") {
          shape.setPositionByOrigin(
            new Point(selection.left! + bb.width / this.stage.getZoom() / 2, sc.y),
            "center",
            "center",
          );
        } else if (alignment === "right") {
          shape.setPositionByOrigin(
            new Point(selection.left! + selection.getScaledWidth() - bb.width / this.stage.getZoom() / 2, sc.y),
            "center",
            "center",
          );
        } else if (alignment === "top") {
          shape.setPositionByOrigin(
            new Point(sc.x, selection.top! + bb.height / this.stage.getZoom() / 2),
            "center",
            "center",
          );
        } else if (alignment === "bottom") {
          shape.setPositionByOrigin(
            new Point(sc.x, selection.top! + selection.getScaledHeight() - bb.height / this.stage.getZoom() / 2),
            "center",
            "center",
          );
        }
        shape.setCoords();
      }
    }
  }

  alignObjects(alignment: ObjectsAlignment) {
    const selection = this.selectionManager.activeSelection;
    if (selection) {
      this.alignRelativeTo(selection.getObjects(), selection, alignment);
      const objects = this.selectedObjects;
      this.selectionManager.clear();
      this.selectionManager.selectObjects(objects, true);
      selection.setCoords();
      this.stage.renderAll();
      this.stage.fire("object:modified");
    } else if (this.currentObject?.shape) {
      this.alignRelativeTo([this.currentObject.shape], this.coverBaseRect, alignment);
      this.stage.renderAll();
      this.stage.fire("object:modified");
    }
  }

  updateObjectColors(objects: ObjectShape[]) {
    for (const object of objects) {
      this.updateShapeFromObject(object.shape!, object);
    }
    this.saveCover();
    this.saveCoverState();
  }

  ungroup(groupObject: GroupObject) {
    if (
      !(this.currentObject instanceof GroupObject) ||
      !(this.currentObject.shape instanceof Group) ||
      (this.currentObject.objects?.length ?? 0) === 0
    ) {
      return;
    }

    const groupShape = (groupObject as ObjectShape).shape as Group;
    const objects = groupObject.objects;
    const shapes = objects.map((v) => (v as ObjectShape).shape!);

    this.selectionManager.clear();
    this.stage.remove(groupShape);
    this.stage.add(...shapes);
    this.stage.renderAll();

    this.cover.objects?.push(...objects);
    this.objects.push(...objects);

    for (const o of objects) {
      this.updateObjectFromShape(o, (o as ObjectShape).shape!);
    }

    this.deleteObjects([groupObject]);

    this.constructGroupIds();
  }

  ungroupObjects() {
    if (
      !(this.currentObject instanceof GroupObject) ||
      !(this.currentObject.shape instanceof Group) ||
      (this.currentObject.objects?.length ?? 0) === 0
    ) {
      return;
    }
    this.ungroup(this.currentObject);
    this.sidebarMode = "object-create";
  }

  groupObjects() {
    if (this.selectedObjects.length === 0) {
      return;
    }

    const objects = this.selectedObjects;

    this.objects = this.objects.filter((v) => !objects.some((i) => i === v));
    this.cover.objects = this.cover.objects?.filter((v) => !objects.some((i) => i === v));
    this.stage.remove(...objects.map((v) => v.shape!));

    const length = this.objects.length;

    this.selectionManager.clear();

    const flatObjects: ObjectShape[] = [];
    for (const o of objects) {
      if (o instanceof GroupObject) {
        const gs = o.shape as Group;
        // gs.destroy();
        this.stage.remove(gs);
        flatObjects.push(...o.objects);
        continue;
      }
      flatObjects.push(o);
    }

    const groupObject = new GroupObject();
    groupObject.objects = flatObjects;

    groupObject.id = undefined;
    groupObject.name = `Group ${length + 1}`;
    groupObject.zIndex = length + 1;

    const shapes = flatObjects.map((v) => (v as ObjectShape).shape!);

    const groupShape = new Group(shapes);
    (groupObject as ObjectShape).shape = groupShape;
    this.addBasicEventListeners(groupShape);
    SelectionManager.applySelectionStyles(groupShape);
    groupShape.controls.mtr.offsetY = -18;

    this.cover.objects?.push(groupObject);
    this.objects.push(groupObject);
    this.stage.add(groupShape);
    this.stage.setActiveObject(groupShape);
    this.stage.bringObjectToFront(this.coverSafeMarginsRect);
    this.stage.bringObjectToFront(this.maskOuterRect);
    this.stage.bringObjectToFront(this.maskInnerRect);
    this.stage.renderAll();

    this.sidebarMode = "object-settings";

    this.updateObjectFromShape(groupObject, groupShape);

    for (const o of flatObjects) {
      this.updateObjectFromShape(o, o.shape!);
    }

    this.constructGroupIds();

    this.saveCover();
    this.saveCoverState();
    this.changeDetector.markForCheck();
  }

  updateGroup(param: UpdateGroupParam) {
    const groupShape = (param.group as ObjectShape).shape as Group;
    const objectToAdd = param.objectToAdd as ObjectShape;
    const objectToRemove = param.objectToRemove as ObjectShape;

    if (objectToAdd?.shape) {
      groupShape.add(objectToAdd.shape);

      for (const obj of param.group.objects) {
        this.updateObjectFromShape(obj, (obj as ObjectShape).shape!);
      }
      this.updateObjectFromShape(objectToAdd, objectToAdd.shape);

      this.updateObjectFromShape(param.group, groupShape);

      this.cover.objects = this.cover.objects?.filter((v) => v !== objectToAdd);
      this.stage.remove(objectToAdd.shape);

      this.saveCover();
      this.saveCoverState();
      this.changeDetector.markForCheck();
      return;
    }

    if (objectToRemove) {
      groupShape.remove(objectToRemove.shape!);

      for (const obj of param.group.objects) {
        this.updateObjectFromShape(obj, (obj as ObjectShape).shape!);
      }
      this.updateObjectFromShape(objectToRemove, objectToRemove.shape!);

      this.updateObjectFromShape(param.group, groupShape);

      this.cover.objects?.push(objectToRemove);
      this.stage.add(objectToRemove.shape!);

      if (param.group.objects.length === 1) {
        this.ungroup(param.group);
      }

      this.saveCover();
      this.saveCoverState();
      this.changeDetector.markForCheck();
      return;
    }
  }

  onPreviewFontFamily(fontFamily: string) {
    if (this.currentObject instanceof TextObject && this.currentObject.shape instanceof Textbox) {
      this.currentObject.shape.fontFamily = fontFamily;
      this.stage.requestRenderAll();
    }
  }

  onResetFontFamily() {
    if (this.currentObject instanceof TextObject && this.currentObject.shape instanceof Textbox) {
      this.currentObject.shape.fontFamily = this.currentObject.fontFamily ?? "";
      this.stage.requestRenderAll();
    }
  }

  constructGuidelines(activeShape: FabricObject) {
    const marginsInnerRect = new Rect();
    marginsInnerRect.set({
      left: this.coverSafeMarginsRect.left! + this.coverSafeMarginsRect.strokeWidth!,
      top: this.coverSafeMarginsRect.top! + this.coverSafeMarginsRect.strokeWidth!,
      width: this.coverSafeMarginsRect.width! - this.coverSafeMarginsRect.strokeWidth!,
      height: this.coverSafeMarginsRect.height! - this.coverSafeMarginsRect.strokeWidth!,
    });
    this.snapper.calculatePotentialGuidelines([
      ...this.objects
        .map((v) => v.shape!)
        .filter(
          (v) =>
            v !== activeShape &&
            (!(activeShape instanceof ActiveSelection) || !activeShape.getObjects().some((o) => o === v)),
        ),
      this.coverBaseRect,
      marginsInnerRect,
    ]);
  }

  constructGuidelineShapes(guidelines: Guidelines) {
    if (this.guidelines.length > 0) {
      this.stage.remove(...this.guidelines);
      this.guidelines = [];
    }

    const strokeWidth = 1;
    for (const v of guidelines.v) {
      const line = new Line(
        [
          v.position - strokeWidth / 2 / this.stage.getZoom(),
          -5000,
          v.position - strokeWidth / 2 / this.stage.getZoom(),
          5000,
        ],
        {
          stroke: "#c0c0c0",
          strokeWidth: strokeWidth / this.stage.getZoom(),
          selectable: false,
        },
      );
      this.guidelines.push(line);
    }
    for (const h of guidelines.h) {
      const line = new Line(
        [
          -5000,
          h.position - strokeWidth / 2 / this.stage.getZoom(),
          5000,
          h.position - strokeWidth / 2 / this.stage.getZoom(),
        ],
        {
          stroke: "#c0c0c0",
          strokeWidth: strokeWidth / this.stage.getZoom(),
          selectable: false,
        },
      );
      this.guidelines.push(line);
    }

    this.stage.add(...this.guidelines);
  }

  snapToGuidelines(shape: FabricObject, guidelines: Guidelines) {
    const center = shape.getCenterPoint();
    const moveTo = center;
    if (guidelines.v.length > 0) {
      moveTo.x += guidelines.v[0].distance ?? 0;
    }
    if (guidelines.h.length > 0) {
      moveTo.y += guidelines.h[0].distance ?? 0;
    }
    shape.setPositionByOrigin(moveTo, "center", "center");
  }

  resetGuidelines() {
    if (this.guidelines.length > 0) {
      this.stage.remove(...this.guidelines);
      this.guidelines = [];
    }
    this.snapper.resetGuidelines();
  }

  initializeObjectShape(object: ObjectShape, shape: FabricObject, resizeImages = false) {
    SelectionManager.applySelectionStyles(shape);
    shape.controls.mtr.offsetY = -18;

    shape.strokeUniform = true;
    //@ts-ignore
    shape.name = "object";
    shape.strokeWidth = 0;

    if (object instanceof GroupObject && shape instanceof Group) {
      /* const shapes = object.objects.map((v) => (v as ObjectShape).shape);
      for (const s of shapes) {
        shape.addWithUpdate(s);
      } */
    } else {
      shape.originX = "left";
      shape.originY = "top";
    }

    if (resizeImages && shape instanceof Image && object instanceof ImageObject) {
      if (shape.width && shape.height && this.cover.width && this.cover.height) {
        const ratio = this.rescaleToFit(shape, this.cover, "contain");
        shape.scale(ratio);
        object.scaleX = ratio;
        object.scaleY = ratio;
      }
    }

    this.updateShapeFromObject(shape, object);

    if (object instanceof ShapeObject) {
      shape.set({
        objectCaching: false,
      });

      if (object instanceof RectangleObject || object instanceof EllipseObject) {
        shape.on("scaling", () => {
          shape.set({
            width: shape.width! * shape.scaleX!,
            height: shape.height! * shape.scaleY!,
            scaleX: 1,
            scaleY: 1,
          });
          if (shape instanceof Ellipse) {
            shape.set({
              rx: shape.width! / 2,
              ry: shape.height! / 2,
            });
          }
        });
      }
    }

    object.shape = shape;

    this.addBasicEventListeners(shape);

    if (shape instanceof Textbox && object instanceof TextObject) {
      shape.on("changed", () => {
        if (!object.isNameModifiedByUser) {
          object.name = shape.text;
        }
      });
    }
  }

  private updateUncaseText(eventText: string, objectText: string): string {
    return eventText
      .split("")
      .map((char: string, idx: number) => {
        if (!objectText[idx]) {
          return char;
        }
        return char.toLowerCase() === objectText[idx].toLowerCase() ? objectText[idx] : char;
      })
      .join("");
  }

  private subscribeOnTextChange(textObject: TextObject): void {
    this.stage.off("text:changed");
    this.stage.on("text:changed", (event) => {
      if (textObject.text && textObject.textCase && textObject.textCase !== TextCase.Auto && event.target) {
        textObject.text = this.updateUncaseText((event.target as Textbox).text!, textObject.text);
        (event.target as Textbox).text = this.updateCase(
          (event.target as Textbox).text!,
          textObject.textCase,
        );
        return;
      }
      textObject.text = (event.target as Textbox)?.text;
    });
  }

  addEventListeners() {
    const canvasElement = this.stage.getElement();
    canvasElement.tabIndex = 1;

    const wrapperElement = this.wrapperRef.nativeElement;
    wrapperElement.tabIndex = 2;
    wrapperElement.focus();

    /////////////////////////
    // ZOOM BY WHEEL
    wrapperElement.addEventListener("wheel", (e: WheelEvent) => {
      if (!zoomByWheel) {
        return;
      }
      e.preventDefault();
      const oldScale = this.stage.getZoom();
      const direction = (e as WheelEvent).deltaY > 0 ? -1 : 1;
      const newScale = direction > 0 ? oldScale * 1.05 : oldScale / 1.05;
      const center = this.stage.getCenter();
      this.stage.zoomToPoint(new Point({ x: center.left, y: center.top }), newScale);
      this.constructGuidelineShapes(this.snapper.snapToGuidelines);
    });
    /////////////////////////

    this.stage.on("selection:cleared", (e: any) => {
      if (this.isEyeDropperActive && this.selectionManager.selectedObjects.length) {
        this.selectionManager.selectObjects(this.selectionManager.selectedObjects, true);
        return;
      }
      this.resetGuidelines();
      this.selectionManager.clear();
      if (this.editingMode === "common") {
        this.panSelectedObjects = [];
      }
      this.sidebarMode = "object-create";
      this.changeDetector.detectChanges();
    });

    this.stage.on("selection:created", (e: any) => {
      const objects = this.objects.filter((o) => e.selected?.some((v: any) => v === o.shape));
      if (objects.length === 1 && objects[0] instanceof TextObject) {
        this.subscribeOnTextChange(objects[0]);
      }
      this.selectionManager.selectObjects(objects);
      if (this.currentObject) {
        this.sidebarMode = "object-settings";
      } else {
        this.sidebarMode = "multiselect-settings";
      }
      if (this.selectionManager.activeSelection) {
        this.addBasicEventListeners(this.selectionManager.activeSelection);
      }
      this.changeDetector.detectChanges();
    });

    this.stage.on("selection:updated", (e: any) => {
      const active = this.stage.getActiveObjects();
      const objects = this.objects.filter((o) => active?.some((v) => v === o.shape));
      if (objects.length === 1 && objects[0] instanceof TextObject) {
        this.subscribeOnTextChange(objects[0]);
      }
      const addListeners = this.selectionManager.selectedObjects.length <= 1;
      this.selectionManager.selectObjects(objects);
      if (this.currentObject) {
        this.sidebarMode = "object-settings";
      } else {
        this.sidebarMode = "multiselect-settings";
      }
      if (this.selectionManager.activeSelection && addListeners) {
        this.addBasicEventListeners(this.selectionManager.activeSelection);
      }
      this.changeDetector.detectChanges();
    });

    fromEvent(this.stage, "object:modified")
      .pipe(
        untilDestroyed(this),
        tap((j) => {
          this.resetGuidelines();
          for (const object of this.selectionManager.selectedObjects) {
            this.updateObjectFromShape(object, object.shape!);
          }
          this.saveCoverState();
        }),
        debounceTime(1000),
      )
      .subscribe(() => {
        this.saveCover();
      });

    /////////////////////////
    // PAN

    this.stage.on("mouse:down", (e: any) => {
      if (this.editingMode === "pan") {
        this.panPrevPoint = e.pointer;
        this.isPanning = true;
        this.changeDetector.markForCheck();
        return;
      }
      if (this.isEyeDropperActive) {
        if (this.onEyeDroperSelected && this.eyeDropperSelectedColor) {
          this.onEyeDroperSelected(this.eyeDropperSelectedColor);
        }
        this.setEyeDropper(false);
      }
    });

    this.stage.on("mouse:up", (e: any) => {
      if (this.editingMode === "pan") {
        this.isPanning = false;
        this.changeDetector.markForCheck();
      }
    });

    this.stage.on("mouse:move", (e: TPointerEventInfo<TPointerEvent>) => {
      const event = e.e;
      if (event instanceof TouchEvent) {
        return;
      }
      if (this.editingMode === "pan" && event.buttons === 1 && e.pointer && this.panPrevPoint) {
        const delta = new Point(e.pointer.x - this.panPrevPoint.x, e.pointer.y - this.panPrevPoint.y);
        this.stage.relativePan(delta);
        this.panPrevPoint = e.pointer;
        return;
      }
      if (this.isEyeDropperActive) {
        const pointer = this.stage.getPointer(e.e, true);
        this.eyeDropperSelectedColor = this.getColorByPosition(pointer.x, pointer.y);
        this.eyeDropperPosition = { x: event.clientX + 21 - 2, y: event.clientY - 21 - 18 + 2 };
        this.changeDetector.markForCheck();
      }
    });
    /////////////////////////

    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === "z" && (e.ctrlKey || e.metaKey)) {
        if (
          this.selectionManager.currentObject &&
          this.selectionManager.currentObject instanceof TextObject &&
          (this.selectionManager.currentObject.shape as Textbox).isEditing
        ) {
          return;
        }

        if (e.shiftKey) {
          this.redo();
        } else {
          this.undo();
        }
      } else if (e.key === "Delete" || e.key === "Backspace") {
        const selectedObjects = this.selectionManager.selectedObjects;
        if (!selectedObjects.length) {
          return;
        }
        if (this.isAnyControlBeingEdited()) {
          return;
        }
        this.deleteObjects(selectedObjects);
      } else if (e.code === "Space" && this.editingMode !== "pan") {
        if (this.isAnyControlBeingEdited()) {
          return;
        }
        this.editingMode = "pan";
        this.stage.skipTargetFind = true;
        this.stage.selection = false;

        if (this.selectionManager.selectedObjects.length) {
          this.panSelectedObjects = this.selectionManager.selectedObjects;
          this.selectionManager.clear();
        }

        this.changeDetector.markForCheck();
      } else if (e.key === "ArrowUp" || e.key === "ArrowRight" || e.key === "ArrowDown" || e.key === "ArrowLeft") {
        const shape = this.selectionManager.activeSelection ?? this.currentObject?.shape;
        if (!shape) {
          return;
        }
        if (this.currentObject?.isLocked) {
          return;
        }
        if (this.isAnyControlBeingEdited()) {
          return;
        }
        e.preventDefault();
        const delta = e.shiftKey ? gridSize : 1;

        if (e.key === "ArrowUp") {
          shape.top! -= delta;
        }
        if (e.key === "ArrowDown") {
          shape.top! += delta;
        }
        if (e.key === "ArrowLeft") {
          shape.left! -= delta;
        }
        if (e.key === "ArrowRight") {
          shape.left! += delta;
        }
        this.stage.renderAll();
        this.stage.fire("object:modified");
      }
    });

    document.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.code === "Space" && this.editingMode === "pan") {
        this.editingMode = "common";
        if (!this.isEyeDropperActive) {
          this.stage.skipTargetFind = false;
          this.stage.selection = true;
        }
        if (this.panSelectedObjects?.length) {
          this.selectionManager.selectObjects(this.panSelectedObjects, true);
        }
        this.changeDetector.markForCheck();
      }
    });

    this.coverUiService.eyedropper$.pipe(untilDestroyed(this)).subscribe((v) => this.onEyeDropper(v));
  }

  addBasicEventListeners(shape: FabricObject) {
    shape.on("moving", (event: TEvent<TPointerEvent>) => {
      this.snapper.calculateActiveShapeGuidelines(shape);
      this.constructGuidelineShapes(this.snapper.snapToGuidelines);
      this.snapToGuidelines(shape, this.snapper.snapToGuidelines);

      if (event.e.shiftKey) {
        // Snap to gridSize value
        const posX = this.snapToGrid(shape.left!);
        const posY = this.snapToGrid(shape.top!);
        if (this.snapper.snapToGuidelines.v.length === 0) {
          shape.left = posX;
        }
        if (this.snapper.snapToGuidelines.h.length === 0) {
          shape.top = posY;
        }
      }
    });

    shape.on("rotating", (e: BasicTransformEvent<TPointerEvent>) => {
      const event = e.e;
      if (event instanceof TouchEvent) {
        return;
      }
      if (event.shiftKey) {
        // Snap to snapAngle value
        const angle = this.snapToAngle(shape.angle!);
        shape.rotate(angle);
      }
    });

    shape.on("mousedown", () => {
      this.constructGuidelines(shape);
    });
  }

  getColorByPosition(x: number, y: number): Color {
    const context = this.stage.getContext();
    const data = context.getImageData(x * window.devicePixelRatio, y * window.devicePixelRatio, 1, 1);
    return new Color(data.data[0], data.data[1], data.data[2], data.data[3] / 255);
  }

  isAnyControlBeingEdited(): boolean {
    const activeElement = document.activeElement;
    if (activeElement?.hasAttribute("contenteditable")) {
      return true;
    }
    if (activeElement instanceof HTMLInputElement) {
      return true;
    }

    const textBoxEditing = this.objects.map((v) => v.shape!).filter((v) => v instanceof Textbox && v.isEditing).length;

    if (textBoxEditing) {
      return true;
    }

    return false;
  }

  resizeStage() {
    const width = this.wrapperRef.nativeElement.offsetWidth;
    const height = this.wrapperRef.nativeElement.offsetHeight;

    this.stage.setDimensions({
      width,
      height,
    });

    const ratio = (height - 48) / this.cover.height!;
    this.stage.setZoom(ratio);

    this.centerStage();
  }

  initializeStage() {
    const width = this.wrapperRef.nativeElement.offsetWidth;
    const height = this.wrapperRef.nativeElement.offsetHeight;

    this.stage = new Canvas(this.canvasRef.nativeElement, {
      width,
      height,
      uniformScaling: true,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      stopContextMenu: true,
      selection: true,
      defaultCursor: "inherit",
    });

    this.selectionManager = new SelectionManager(this.stage);

    this.canvasRef.nativeElement.focus();

    this.addEventListeners();
  }

  ngAfterViewInit(): void {
    this.initializeStage();
    this.onCover();

    this.resizeObserver = new ResizeObserver(() => {
      this.resizeStage();
    });

    this.resizeObserver.observe(this.wrapperRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.wrapperRef.nativeElement);
    this.sub.unsubscribe();
  }

  showTemplates() {
    this.sidebarMode = "template-list";
  }

  hideTemplates() {
    this.sidebarMode = "object-create";
  }

  showConceptualAssistantMenu() {
    this.sidebarMode = "conceptual-assistant";
  }

  hideConceptualAssistant() {
    this.sidebarMode = "object-create";
  }

  onObjectSettingsClose() {
    this.sidebarMode = "object-create";
    this.stage.discardActiveObject();
    this.stage.renderAll();
  }

  async toTemplate() {
    this.loadingService.startLoading({ fullPage: true });
    await this.saveCoverFullsizeImage();
    const template = await this.coverService.toTemplate(this.cover.id!);
    this.coverTemplateStore.addTemplate(template);
    this.loadingService.stopLoading();
  }

  async applyTemplate(template: BookCoverTemplate) {
    this.loadingService.startLoading({ fullPage: true });
    const command = <ApplyTemplateRequestDto>{
      bookId: this.book.id,
      templateId: template.id,
    };
    this.cover = await this.coverService.applyTemplate(command);
    this.checkCoverData();
    this.saveCoverState({
      template,
    });
    this.onCover(false, true, true);
  }

  saveCoverState(options: BookCoverStateOptions | undefined = undefined) {
    const state = new BookCoverState(this.cover, options);
    this.undoRedo.save(state);
    if (!options?.template) {
      this.coverTemplateStore.setActiveTemplate(undefined);
    }
  }

  private _undoredo(undo: boolean) {
    const state = undo ? this.undoRedo.undo() : this.undoRedo.redo();
    if (state instanceof BookCoverState) {
      this.cover.objects = state.cover.objects;
      this.saveCover();
      this.onCover(false, false);
      this.coverTemplateStore.setActiveTemplate(state.options?.template);
    }
  }

  undo() {
    this._undoredo(true);
  }

  redo() {
    this._undoredo(false);
  }

  setEyeDropper(set: boolean) {
    this.isEyeDropperActive = set;
    this.stage.skipTargetFind = this.isEyeDropperActive;
    if (!set) {
      this.eyeDropperSelectedColor = undefined;
    }
  }

  onEyeDropper(callback: (color: Color) => void) {
    this.setEyeDropper(true);
    this.onEyeDroperSelected = callback;
  }

  // HELPERS

  private getTextObjectsByRoles(objects: CoverObject[], roles: CoverObjectRole[]): TextObject[] {
    const result = objects
      .filter(
        (v) => v instanceof TextObject && roles.some((j) => j === v.id?.toLowerCase() || j === v.name?.toLowerCase()),
      )
      .map((v) => v as TextObject);
    return result;
  }

  snapToGrid(position: number): number {
    return gridSize ? Math.round(position / gridSize) * gridSize : position;
  }

  snapToAngle(angle: number): number {
    return shapAngle ? Math.round(angle / shapAngle) * shapAngle : angle;
  }

  showCompletionModal() {
    this.isCompletionModalVisible = true;
    this.saveCoverFullsizeImage();
  }

  saveCoverPreviewImage() {
    const scaleRatio = this.rescaleToFit(this.cover, { width: 190, height: 270 }, "contain");
    const blob = this.getCoverImageBlob(scaleRatio * coverPreviewImageMultiplier);
    return this.uploadCoverImage(blob, false);
  }

  saveCoverFullsizeImage() {
    const blob = this.getCoverImageBlob(coverFullsizeImageMultiplier);
    return this.uploadCoverImage(blob, true);
  }

  closeCompletionModal() {
    this.isCompletionModalVisible = false;
  }

  closeImageSelectionModal() {
    this.isImageSelectionModalVisible = false;
    this.onboardingService.onStartOnboarding(false);
    this.changeDetector.markForCheck();
  }

  async downloadCoverImage() {
    const blob = this.getCoverImageBlob(coverFullsizeImageMultiplier);
    DataHelper.saveAsFile(blob, `book-${this.book.id}-${this.book.title}-cover.png`, "image/png");
  }

  private rescaleToFit(
    src: ObjectSize | BookCover | CoverObject | FabricObject,
    dst: ObjectSize | BookCover | CoverObject | FabricObject,
    mode: RescaleFitMode,
  ): number {
    if (!src.width || !src.height || !dst.width || !dst.height) {
      return 1;
    }
    const scaleRatioX = dst.width / src.width;
    const scaleRatioY = dst.height / src.height;
    if (mode === "horizontally") {
      return scaleRatioX;
    }
    if (mode === "vertically") {
      return scaleRatioY;
    }
    return mode === "contain" ? Math.min(scaleRatioX, scaleRatioY) : Math.max(scaleRatioX, scaleRatioY);
  }

  protected onBackClick() {
    this.back.emit();
  }

  protected async onApplyFontsAndColors(data: FontsWithColorData) {
    if (!this.cover.objects) {
      return;
    }
    let applied = false;
    if (data.main) {
      const objects = this.getTextObjectsByRoles(this.cover.objects, ["title"]);
      const rgb = data.main.color ? this.colorConverter.hex2rgb(data.main.color) : undefined;
      for (const o of objects) {
        o.fontFamily = data.main.font;
        if (rgb) {
          o.fill = new SolidFill();
          (o.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
        }
        applied = true;
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
      }
    }
    if (data.sub) {
      const objects = this.getTextObjectsByRoles(this.cover.objects, ["subtitle"]);
      const rgb = data.sub.color ? this.colorConverter.hex2rgb(data.sub.color) : undefined;
      for (const o of objects) {
        o.fontFamily = data.sub.font;
        if (rgb) {
          o.fill = new SolidFill();
          (o.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
        }
        applied = true;
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
      }
    }
    if (data.sec) {
      const objects = this.getTextObjectsByRoles(this.cover.objects, ["author"]);
      const rgb = data.sec.color ? this.colorConverter.hex2rgb(data.sec.color) : undefined;
      for (const o of objects) {
        o.fontFamily = data.sec.font;
        if (rgb) {
          o.fill = new SolidFill();
          (o.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
        }
        applied = true;
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
      }
    }

    if (applied) {
      this.saveCoverState();
      this.saveCover();
    }
  }

  protected onCreateImageGeneration(data: ImageGeneration) {
    this.publishedImageStore.setPublishedImageSettings(data);
    this.isImageGeneratorVisible = true;
    this.changeDetector.markForCheck();
  }

  protected async onGenerateCoverConceptual(data: CoverConceptualGenerationDataDto) {
    this.loadingService.startLoading({ fullPage: true });

    await this.textGenerationService.coverConceptualGeneration(data);
    this.loadingService.stopLoading();
  }

  showImageGenerator() {
    this.isImageGeneratorVisible = true;
  }

  hideImageGenerator() {
    this.isImageGeneratorVisible = false;
    this.changeDetector.markForCheck();
  }

  async selectGeneratedImage(image: GeneratedImage) {
    this.loadingService.startLoading({ fullPage: true });
    this.isImageGeneratorVisible = false;
    const result = await this.coverService.uploadGeneratedObjectImage(<UploadGeneratedImageRequestDto>{
      bookId: this.book.id,
      generationId: image.imageGenerationId,
      src: image.imageUrl,
    });
    this.loadingService.stopLoading();
    this.createObject({ type: CoverObjectType.Image, imageName: result.name });
    this.closeImageSelectionModal();
  }

  protected onShowShareCoverModal() {
    this.isShareModalVisible = true;
    this.saveCoverFullsizeImage();
  }

  protected onCloseShareCoverModal() {
    this.isShareModalVisible = false;
  }

  protected async onPublishCoverClick() {
    await this.coverService.publishBookCover(this.cover.id);
    this.cover.isPublic = true;

    this.notificationService.notify({
      content: $localize`:@@cover-editor.share.cover-published-notification:`,
      type: "success",
    });
    this.onCloseShareCoverModal();
    this.changeDetector.markForCheck();
  }

  loadLastCoverConceptualGeneration() {
    this.textGenerationService.loadLastCoverConceptualGeneration(this.cover.id);
  }

  async onRemoveBackground(imageObject: ImageObject) {
    if (!imageObject.imageUrl) {
      return;
    }

    const isEnoughtTokens = this.fabulaImageGenerationService.isEnoughtTokens(this.prices, this.balance, "nobg");

    if (!isEnoughtTokens) {
      await this.calculatePaymentData("nobg");
      return;
    }

    const data: FabulaRemoveBackgroundDataDto = {
      imageUrl: imageObject.imageUrl,
      bookId: this.book.id,
    };

    this.loadingService.startLoading({ fullPage: true });

    const result = await this.fabulaImageGenerationService.removeBackground(data);
    if (result.status !== "success") {
      this.loadingService.stopLoading();
      this.notifyOnImageGenerationError();
    }
    this.processingImageObject = imageObject;
    this.processingImageGenenerationId = result.generationId;

    this.onProccesingData(true);
  }

  private async calculatePaymentData(mode: FabulGenerationMode) {
    let price = 0;
    if (this.prices && mode === "nobg") {
      price = this.prices.fabulaRemoveBackground;
    }

    this.imageGenerationPaymentData = {
      price: price,
      userBalance: this.balance,
    };

    this.imageGenerationMode = mode;
    this.isLowBalanceModalVisible = true;
  }

  async processGeneratedImages(images: FabulaGeneratedImage[]) {
    for (const image of images) {
      this.createObjectAfterRemoveBackground(image.imageUrl);
    }

    // TODO back after made async
    // if (this.processingImageObject?.isVisible !== undefined) {
    //   this.processingImageObject.isVisible = false;
    //   // this.updateObject(this.processingImageObject);
    //   this.updateShapeFromObject((this.processingImageObject as ObjectShape).shape!, this.processingImageObject);
    // }

    // this.processingImageObject = undefined;
    // this.processingImageGenenerationId = undefined;

    // this.onProccesingData(false);

    // this.loadingService.stopLoading();
  }

  private createObjectAfterRemoveBackground(imageUrl: string) {
    this.createObject({ type: CoverObjectType.Image, imageName: imageUrl }, false);

    const o = this.cover.objects?.find((o) => o instanceof ImageObject && o.imageUrl === imageUrl) as ImageObject;
    if (o) {
      const name = this.processingImageObject?.name ? this.processingImageObject.name : o.name;
      o.name = `${name} - ${$localize`:@@cover-editor.image.removed-background.text:`}`;
      o.x = this.processingImageObject?.x;
      o.y = this.processingImageObject?.y;
      // o.scaleX = this.processingImageObject?.scaleX;
      // o.scaleY = this.processingImageObject?.scaleY;
      o.rotationAngle = this.processingImageObject?.rotationAngle;
      o.opacity = this.processingImageObject?.opacity;
      o.skewX = this.processingImageObject?.skewX;
      o.skewY = this.processingImageObject?.skewY;
      o.isBackgroundRemoved = true;
    }

    // TODO move after made async
    if (this.processingImageObject?.isVisible !== undefined) {
      this.processingImageObject.isVisible = false;
      this.updateObject(this.processingImageObject);
    }

    this.loadingService.startLoading({ fullPage: true });

    timer(5000).subscribe(() => {
      if (!this.processingImageObject) {
        return;
      }

      const o = this.objects?.find((o) => o instanceof ImageObject && o.imageUrl === imageUrl) as ImageObject;
      if (o) {
        const indexProcessingImageObject = this.objects?.indexOf(this.processingImageObject);
        const indexCreatedObject = this.objects?.indexOf(o);

        moveItemInArray(this.objects, indexCreatedObject, indexProcessingImageObject + 1);

        o.scaleX = this.processingImageObject?.scaleX;
        o.scaleY = this.processingImageObject?.scaleY;

        if (o.width && this.processingImageObject.width && o.width !== this.processingImageObject.width) {
          const c = this.processingImageObject.width / o.width;
          o.scaleX = (o.scaleX || 1) * c;
        }
        if (o.height && this.processingImageObject.height && o.height !== this.processingImageObject.height) {
          const c = this.processingImageObject.height / o.height;
          o.scaleY = (o.scaleY || 1) * c;
        }

        this.reorderObjects(this.objects);
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
        this.objects = this.objects.slice();
        this.changeDetector.detectChanges();
      }

      // TODO move after made async
      this.processingImageObject = undefined;
      this.processingImageGenenerationId = undefined;

      this.saveCover();

      this.onProccesingData(false);

      this.loadingService.stopLoading();
    });
  }

  notifyOnImageGenerationError(generationMode: FabulGenerationMode | undefined = undefined) {
    switch (generationMode) {
      case "nobg":
        this.notificationService.error($localize`:@@image-generation.generation.variant-image.nobg.error:`);
        break;
      // case "upscale":
      // this.notificationService.error($localize`:@@image-generation.generation.variant-image.upscale.error:`);
      // break;
      // case "unzoom":
      //   this.notificationService.error($localize`:@@image-generation.generation.variant-image.unzoom.error:`);
      //   break;
      default:
        this.notificationService.error($localize`:@@image-generation.generation.error:`);
        break;
    }
  }

  loadImageGenerationPrices() {
    from(this.imageGenerationService.loadPrices()).subscribe((p) => {
      this.prices = p;
    });
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected closePricingModal() {
    this.isLowBalanceModalVisible = false;
  }

  protected onBuySubscription(tariff: Tariff) {
    this.closePricingModal();
    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }

  onProccesingData(isProcessing: boolean) {
    this.processing.emit(isProcessing);
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }
}

function clearFabricFontCache(fontFamily: string) {
  // throw new Error("Function not implemented.");
}
