<div
  class="layers-container"
  [class.group]="isGroup"
  cdkDropList
  [cdkDropListData]="objects"
  [id]="listId"
  [cdkDropListConnectedTo]="connectedTo"
  (cdkDropListDropped)="reorderCoverObjects($event)"
  [cdkDropListEnterPredicate]="dragDropPredicate"
>
  <m-cover-object-list-item
    *ngFor="let object of reversedObjects"
    cdkDrag
    [cdkDragDisabled]="object.isLocked"
    [object]="object"
    [isGroupItem]="isGroup"
    [class.group]="isGroupObject(object)"
    [listIds]="listIds"
    [isSelected]="isSelected(object)"
    [isCurrent]="currentObject === object"
    [user]="user"
    (select)="onObjectSelected($event)"
    (cdkDragStarted)="onDragStarted()"
    (cdkDragReleased)="onDragReleased()"
  >
    <div ngProjectAs="drag-handle" class="cell drag-handle" *ngIf="!object.isLocked" cdkDragHandle>
      <m-ng-icon [size]="24" src="/assets/icons/drag-handle-01.svg"></m-ng-icon>
    </div>
  </m-cover-object-list-item>
</div>
