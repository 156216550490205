import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { PricingViewService } from "@metranpage/pricing";
import { PaymentCurrency } from "@metranpage/pricing-data";
import { PaymentData } from "@metranpage/user-payment-data";
import { RunningTitlesService } from "../../services/running-titles.service";

@Component({
  selector: "m-payments-modal",
  templateUrl: "./payments-modal.view.html",
  styleUrls: ["./payments-modal.view.scss"],
})
export class PaymentsModalView {
  @Input("payment-data")
  paymentData!: PaymentData;
  @Input("is-purchases-available")
  isPurchasesAvailable!: boolean;
  @Input()
  currency: PaymentCurrency = "RUB";

  @Output()
  close = new EventEmitter();
  @Output()
  payGoldCredits = new EventEmitter<number>();
  @Output()
  payCredits = new EventEmitter<number>();
  @Output()
  buyCredits = new EventEmitter<number>();

  protected isAboutGoldCreditsVisible = false;
  protected notEnoughCredits = 0;

  constructor(
    private readonly pricingViewService: PricingViewService,
    private readonly runningTitlesService: RunningTitlesService,
  ) {}

  ngOnInit() {
    this.notEnoughCredits = this.calculateNotEnoughCredits();
  }

  protected onClickPayGoldCredits() {
    this.payGoldCredits.emit(1);
  }

  protected onClickPayCredits() {
    this.payCredits.emit(this.paymentData.bookPrice);
  }

  protected onClickBuyCredits() {
    this.buyCredits.emit(this.notEnoughCredits);
  }

  protected onCloseClick() {
    this.close.emit();
  }

  protected toggleAboutGoldCredits() {
    this.isAboutGoldCreditsVisible = !this.isAboutGoldCreditsVisible;
  }

  protected getUserCreditsBalance() {
    return this.pricingViewService.thousandsFormat(this.paymentData.userBalance.credits);
  }

  protected getUserGoldCreditsBalance() {
    return this.pricingViewService.thousandsFormat(this.paymentData.userBalance.goldCredits);
  }

  protected getBuyCreditsPrice() {
    const price = this.notEnoughCredits * this.paymentData.creditPrice;
    const currencySymbol = this.pricingViewService.getCurrencySymbol(this.currency);
    return `${$localize`:@@books.preview.payments-modal.buy-credits:`} ${this.pricingViewService.priceFormat(
      price,
      currencySymbol,
    )}`;
  }

  protected getBookPrice() {
    return this.pricingViewService.thousandsFormat(this.paymentData.bookPrice);
  }

  protected isNotEnoughCredits() {
    return this.paymentData.userBalance.credits - this.paymentData.bookPrice < 0;
  }

  protected isNotEnoughGoldCredits() {
    return this.paymentData.userBalance.goldCredits - 1 < 0;
  }

  protected pluralizeCredits() {
    return `${this.runningTitlesService.capitalizeFirstLetter(
      this.pricingViewService.pluralizeSimpleCredits(this.paymentData.bookPrice),
    )} ${this.pricingViewService.pluralizeCredits(this.paymentData.bookPrice)}`;
  }

  protected pluralizeGoldCredits() {
    return this.runningTitlesService.capitalizeFirstLetter(this.pricingViewService.pluralizeGoldCredits(1));
  }

  protected calculateNotEnoughCredits() {
    if (!this.isNotEnoughCredits()) {
      return 0;
    }
    return Math.abs(this.paymentData.userBalance.credits - this.paymentData.bookPrice);
  }

  protected pluralizeNotEnoughtCredits() {
    return `${$localize`:@@books.preview.payments-modal.not-enought:`} ${
      this.notEnoughCredits
    } ${this.pricingViewService.pluralizeNotEnoughtCredits(this.notEnoughCredits)}`;
  }

  @HostListener("document:keydown.escape", ["$event"])
  onEscape(event: KeyboardEvent) {
    this.close.emit();
  }
}
