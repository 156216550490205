import { Component, HostBinding, Input, SimpleChanges } from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { CommunityDataService } from "../../community-data.service";

@Component({
  selector: "m-community-cover-view",
  templateUrl: "cover.view.html",
  styleUrls: ["cover.view.scss"],
})
export class CommunityCoverView {
  @Input()
  cover!: BookCover;

  protected url = "";

  constructor(private readonly communityDataService: CommunityDataService) {}

  @HostBinding("style.aspect-ratio")
  get aspectRatio() {
    return this.cover?.width / this.cover?.height;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cover) {
      this.url = this.getUrlForImage(this.cover)!;
    }
  }

  protected getUrlForImage(cover: BookCover) {
    return this.communityDataService.getImageUrlForObject("covers", cover.id, false);
  }
}
