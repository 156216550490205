<div class="bar-left">
  <m-logo logo-style="full" routerLink="/" class="logo"></m-logo>

  <m-breadcrumbs></m-breadcrumbs>
</div>

<div *ngIf="user" class="bar-right">
  <a *ngIf="user.isAdmin" routerLink="/admin/users" i18n="@@app-bar.admin-button">app-bar.admin-button</a>

  <m-app-bar-menu
    [userBalance]="userBalance"
    [user]="user"
    [isLanguageSwitchAvailable]="isLanguageSwitchAvailable"
    (onThemeToggle)="onThemeToggle.emit()"
    (onLanguageChange)="onLanguageChange.emit($event)"
    (onLogoutClick)="onLogoutClick.emit()"
    (onPromocodeEnter)="onPromocodeEnter.emit($event)"
  ></m-app-bar-menu>
</div>
