import { ChangeDetectorRef, Component, Inject, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Book, BookSize, EditionNoticeSettings, TemplateWithPreview } from "@metranpage/book-data";
import { InfoBlockData, fadeInOutOnEnterLeave } from "@metranpage/components";
import { LoadingService, RouterService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { FormatData, FormatService, FormatStore } from "@metranpage/format-data";
import { StyleKey } from "@metranpage/markup-editor";
import { BehaviorSubject, Observable, Subscription, filter, first, switchMap } from "rxjs";
import { BookModalEventBus } from "../../services/book-modal-event-bus.service";
import { BookRouterService, ModalType } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";

type ImageState = {
  index: number;
  url: string;
};

@Component({
  selector: "m-modal-book-template-preview",
  templateUrl: "modal-book-template-preview.view.html",
  styleUrls: ["modal-book-template-preview.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class ModalBookTemplatePreviewView {
  @Input()
  runInEditMode = false;

  protected book!: Book;
  protected bookSize!: BookSize;
  protected templateId = 0;

  protected selectedLeftPage = 0;
  previews$!: Observable<string[]>;
  previewsThumbnails$!: Observable<string[]>;
  previews: string[] = [];

  protected previewIndex = 1;

  protected contentHeader = "";

  allTemplates: TemplateWithPreview[];
  template?: TemplateWithPreview;
  currentImage$ = new BehaviorSubject<ImageState | undefined>(undefined);

  protected formatData?: FormatData;

  sub: Subscription = new Subscription();

  constructor(
    private readonly bookService: BookService,
    private readonly bookRouterService: BookRouterService,
    private readonly bookModalEventBus: BookModalEventBus,
    @Inject("ConfigService")
    private readonly configService: ConfigService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly routerService: RouterService,
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly booksStore: BooksStore,
    private readonly formatService: FormatService,
    private readonly formatStore: FormatStore,
  ) {
    this.allTemplates = [];
  }

  async ngAfterViewInit() {
    this.sub.add(
      this.formatStore
        .getFormatsObservable()
        .pipe(
          filterUndefined(),
          filter((formats) => formats.length > 0),
          first(),
          switchMap(() => this.booksStore.getModalBookObservable()),
          filterUndefined(),
        )
        .subscribe(async (book) => {
          await this.init(book);
        }),
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async init(book: Book) {
    this.book = book;

    this.templateId = Number(this.routerService.getRouteParam(this.route.snapshot, "templateId"));
    this.bookSize = {
      width: Number(this.routerService.getRouteParam(this.route.snapshot, "width")),
      height: Number(this.routerService.getRouteParam(this.route.snapshot, "height")),
    };
    this.loadingService.startLoading({ fullPage: true });
    this.allTemplates = await this.bookService.getTemplates();
    this.template = this.allTemplates.find((template) => template.id === this.templateId);

    this.formatData = this.formatService.getFormatData(this.bookSize.width, this.bookSize.height);

    await this.preparePreviews();

    this.selectPreviewImage(0);
    this.loadingService.stopLoading();

    this.updateHeader();

    this.cdr.markForCheck();
  }

  async preparePreviews() {
    this.previews = [];

    if (!this.template?.previews) {
      return;
    }

    for (const preview of this.template.previews) {
      const previewUrl = this.formatPreviewImage(this.template.id, preview);
      this.previews.push(previewUrl);
    }

    const editionNoticeSettings = await this.bookService.getEditionNotice();
    const editionNoticePreview = this.formatEditionNoticePreviewImage(editionNoticeSettings);
    // if (this.template?.isEditable && editionNoticePreview) {
    // this.previews.push(editionNoticePreview);
    // }
  }

  async selectTemplate() {
    const templateId = this.templateId;
    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.setBookSizeAndTemplate(this.book, this.bookSize, templateId);
    this.loadingService.stopLoading();

    if (result === "success") {
      if (this.runInEditMode) {
        this.bookModalEventBus.onTemplateSelected();
        return;
      }

      const book = this.book;
      this.bookRouterService.showModal(book, "upload", "new");
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  formatPreviewImage(templateId: number, imageName: string) {
    return `${this.configService.getApiUrl()}/templates/${templateId}/previews/${imageName}`;
  }

  formatEditionNoticePreviewImage(editionNotice: EditionNoticeSettings | undefined): string {
    if (!editionNotice) {
      return "";
    }
    return `${this.configService.getApiUrl()}/edition-notice/${editionNotice.lang}/previews/${editionNotice.preview}`;
  }

  protected onSelectLeftPage(value: number) {
    this.selectedLeftPage = value;
  }

  hasPreviousPreview() {
    return this.template && this.previewIndex - 1 >= 0;
  }

  hasNextPreview() {
    return this.template && this.previewIndex + 1 < this.template.previews.length;
  }

  onPreviousPreviewClick() {
    if (!this.hasPreviousPreview()) {
      return;
    }
    this.selectPreviewImage(this.previewIndex - 1);
  }

  onNextPreviewClick() {
    if (!this.hasNextPreview()) {
      return;
    }
    this.selectPreviewImage(this.previewIndex + 1);
  }

  selectPreviewImage(index: number) {
    this.previewIndex = index;
    this.currentImage$.next({
      index: index,
      url: this.formatPreviewImage(this.template!.id, this.template!.previews[index]),
    });
  }

  getStyleFontSize(styleKey: StyleKey) {
    if (!this.template?.styles) {
      return 10;
    }
    return this.template?.styles[styleKey].fontSize;
  }

  getRunningTitlesDescription(position: number) {
    switch (position) {
      case 0:
        return $localize`:@@books.template.template-info.running-titles-position-outside:`;
      case 1:
        return $localize`:@@books.template.template-info.running-titles-position-top:`;
      case 2:
        return $localize`:@@books.template.template-info.running-titles-position-bottom:`;
      default:
        return $localize`:@@books.template.template-info.running-titles-position-top:`;
    }
  }

  getPageNumbersDescription(position: number) {
    switch (position) {
      case 0:
        return $localize`:@@books.template.template-info.page-numbers-position-outside:`;
      case 1:
        return $localize`:@@books.template.template-info.page-numbers-position-top:`;
      case 2:
        return $localize`:@@books.template.template-info.page-numbers-position-bottom:`;
      default:
        return $localize`:@@books.template.template-info.page-numbers-position-top:`;
    }
  }

  protected getInfoLabels(): InfoBlockData[] {
    const labels: InfoBlockData[] = [];

    if (this.template?.isEditable) {
      labels.push({ textData: [{ text: $localize`:@@books.template.template-info.changes-info:` }] });
    }
    if (this.hasTablesInMultiColumnTemplate()) {
      labels.push({
        textData: [{ text: $localize`:@@books.template.select-template.template-not-suitable-for-uploaded-document:` }],
      });
    }

    return labels;
  }

  updateHeader() {
    let formatTitle = $localize`:@@home.new-project-modal.sidebar-section.item.book.format-custom:`;
    if (this.formatData?.title) {
      formatTitle = this.formatData.title;
    }

    let templateTitle = "";
    if (this.template) {
      templateTitle = ` / ${this.template.title}`;
      const symbolsLimit = 22;
      if (templateTitle.length > symbolsLimit) {
        templateTitle = `${templateTitle.slice(0, symbolsLimit)}...`;
      }
    }

    this.contentHeader = `${formatTitle}${templateTitle}`;
  }

  protected async onBackClick() {
    let type: ModalType = "new";

    if (this.runInEditMode) {
      type = "edit";
    }

    this.bookRouterService.showModal(this.book, "templates", type, this.formatData);
  }

  protected isSelectDisable() {
    return this.hasTablesInMultiColumnTemplate();
  }

  protected hasTablesInMultiColumnTemplate() {
    return !!(this.template?.columnsCount && this.template?.columnsCount > 1) && !!this.book?.bookFeatures?.hasTables;
  }
}
