<div class="wrapper">
  <div class="section main">
    <ng-scrollbar class="scroll-container">
      <m-cover-object-list
        [user]="user"
        [objects]="objects"
        [currentObject]="currentObject"
        [selectedObjects]="selectedObjects"
        [listId]="parentId"
        [listIds]="listIds"
        (current)="onCurrent($event)"
      ></m-cover-object-list>
    </ng-scrollbar>
  </div>

  <div class="section" *ngIf="currentObject">
    <div class="layer-controls-wrapper">
      <div class="block opacity">
        <form [formGroup]="form" *ngIf="values$ | async as values">
          <m-ng-input-number-with-icon
            iconLeft="opacity-01.svg"
            formControlName="opacity"
            size="32"
            [iconSize]="24"
            text-align="right"
          />
        </form>
      </div>
      <div class="block">
        <div class="button">
          <m-ng-icon [src]="visibilityIcon" [size]="24" (click)="toggleObjectVisibility(currentObject)"></m-ng-icon>
        </div>
        <div class="button">
          <m-ng-icon [src]="lockIcon" [size]="24" (click)="toggleLockObject(currentObject)"></m-ng-icon>
        </div>
        <div class="button">
          <m-ng-icon src="/assets/icons/clone-01.svg" [size]="24" (click)="cloneObject(currentObject)"></m-ng-icon>
        </div>
        <div class="button delete">
          <m-ng-icon src="/assets/icons/delete-01.svg" [size]="24" (click)="deleteObject(currentObject)"></m-ng-icon>
        </div>
      </div>
    </div>
  </div>
</div>
