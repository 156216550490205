import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  Book,
  Dimensions,
  MarginKey,
  Margins,
  MarginsLimits,
  MarginsSelectState,
  MarginsState,
} from "@metranpage/book-data";
import { CompanyStore } from "@metranpage/company";
import { InfoBlockActionData, InfoBlockData, NavTab, fadeInOutOnEnterLeave } from "@metranpage/components";
import { AnalyticsService, LoadingService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingViewService } from "@metranpage/pricing";
import { UserStore } from "@metranpage/user-data";
import { UntilDestroy } from "@ngneat/until-destroy";
import * as _ from "lodash-es";
import { Subscription, timer } from "rxjs";
import { filter, first } from "rxjs/operators";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";
import { MarginsService } from "../../services/margins.service";
import { MarginsStore } from "../../services/margins.store";

@UntilDestroy()
@Component({
  selector: "m-margins-page",
  templateUrl: "./margins.page.html",
  styleUrls: ["./margins.page.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class MarginsPage implements OnInit, OnDestroy {
  protected pageSize: Dimensions = { width: 100, height: 150 };
  protected fontSizeMain = 12;

  protected reDigits = /^[0-9.]+$/;
  protected activeSidebarTabNumber = 1;
  protected selectState: MarginsSelectState = {};
  protected marginsError: MarginKey[] = [];
  protected spaceLevelLimits = { min: 1, max: 5 };
  protected marginsLimits!: MarginsLimits;
  protected form!: FormGroup;

  protected isTablesInBook?: boolean = false;
  protected isCenterImages?: boolean = false;

  protected isColumsCountDisable = false;
  protected isWidePaddingDisable = false;
  protected widePaddingState = false;

  protected isLoading = true;

  protected book?: Book;

  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  protected hasOnboarding = true;
  protected timeoutOnboarding = 2000;

  protected isImagesSettingsModalVisible = false;

  protected navTabs: NavTab[] = [
    { id: "margins", isActive: true, text: $localize`:@@books.nav-tabs.margins:` },
    { id: "running-titles", isActive: false, text: $localize`:@@books.nav-tabs.running-titles:` },
  ];

  protected canProduceEpub = true;

  private sub = new Subscription();

  constructor(
    private readonly marginsService: MarginsService,
    private readonly booksStore: BooksStore,
    private readonly pricingViewService: PricingViewService,
    private readonly marginsStore: MarginsStore,
    private readonly router: Router,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef,
    private readonly onboardingService: OnboardingService,
    private readonly bookService: BookService,
    private readonly analytics: AnalyticsService,
    userStore: UserStore,
    companyStore: CompanyStore,
    renderer: Renderer2,
  ) {
    marginsService.renderer = renderer;

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore
        .getActiveSubscriptionObservable()
        .pipe(filterUndefined())
        .subscribe((activeSubscription) => {
          this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
          this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

          if (!this.hasPaidTariff && !this.hasTrialPeriod) {
            this.hasOnboarding = false;
            this.router.navigate(["books"]);
          }
        }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.canProduceEpub = company.canProduceEpub;
        }),
    );
  }

  ngOnInit(): void {
    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), first())
        .subscribe(async (book) => {
          this.book = book;

          if (!book.exportPrint || !book.bookSettings?.isEditable) {
            this.hasOnboarding = false;
            this.router.navigate(["books", this.book?.id, "markup"]);
          }

          this.isTablesInBook = book.bookFeatures?.hasTables;
          this.isCenterImages = book.bookSettings?.centerImages;
          this.initState(book);
        }),
    );

    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined())
        .subscribe(async (book) => {
          this.book = book;
          this.isCenterImages = book.bookSettings?.centerImages;

          if (!this.isCenterImages && !this.form.controls["widePaddingState"].enabled) {
            this.form.controls["widePaddingState"].enable();
          }
        }),
    );
  }

  async ngAfterViewInit() {
    this.sub.add(
      timer(this.timeoutOnboarding).subscribe(() => {
        this.startOnboarding();
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    if (!this.hasOnboarding) {
      return;
    }
    this.onboardingService.startOnboarding("margins-page", showForced, 500);
  }

  private initState(book: Book) {
    this.pageSize = _.pick(book.bookSettings!, ["width", "height"]);
    this.fontSizeMain = book.bookSettings!.mainSize;

    const margins: Margins = _.pick(book.bookSettings!, [
      "marginTop",
      "marginBottom",
      "marginOuter",
      "marginInner",
      "gutter",
      "widePadding",
    ]);

    const partMarginsState = _.pick(book.bookSettings!, [
      "widePaddingState",
      "widePaddingLeftPosition",
      "widePaddingRightPosition",
      "columnsCount",
    ]);

    const marginsState: MarginsState = {
      ...partMarginsState,
      margins: margins,
      spaceLevel: 1,
      lastChangeSource: "sidebar",
    };

    this.marginsStore.setMarginsState(marginsState);

    this.isLoading = false;
    this.subscribeToUpdates();
  }

  private subscribeToUpdates() {
    this.sub.add(
      this.marginsStore
        .getMarginsStateObservable()
        .pipe(first())
        .subscribe((state) => {
          this.initForm(state);
          this.updateMarginsLimits(state);
          this.setFormInputs(state);

          this.widePaddingState = state.widePaddingState;
          this.watchFormChanges();
        }),
    );

    this.sub.add(
      this.marginsStore
        .getMarginsStateObservable()
        .pipe(filter((state) => state.lastChangeSource === "preview"))
        .subscribe((state) => {
          this.updateMarginsLimits(state);
          this.setFormInputs(state);
        }),
    );
  }

  private initForm(state: MarginsState) {
    this.calculateMarginsLimits(state);
    this.form = new FormGroup({
      spaceLevel: new FormControl(state.spaceLevel, [
        Validators.required,
        Validators.min(this.spaceLevelLimits.min),
        Validators.max(this.spaceLevelLimits.max),
      ]),
      columnsCount: new FormControl(false, [Validators.required]),
      widePaddingState: new FormControl(false, [Validators.required]),
      marginTop: new FormControl(state.margins.marginTop, [
        Validators.required,
        Validators.min(this.marginsLimits.marginTop.min),
        Validators.max(this.marginsLimits.marginTop.max),
        Validators.pattern(this.reDigits),
      ]),
      marginBottom: new FormControl(state.margins.marginBottom, [
        Validators.required,
        Validators.min(this.marginsLimits.marginBottom.min),
        Validators.max(this.marginsLimits.marginBottom.max),
        Validators.pattern(this.reDigits),
      ]),
      marginOuter: new FormControl(state.margins.marginOuter, [
        Validators.required,
        Validators.min(this.marginsLimits.marginOuter.min),
        Validators.max(this.marginsLimits.marginOuter.max),
        Validators.pattern(this.reDigits),
      ]),
      marginInner: new FormControl(state.margins.marginInner, [
        Validators.required,
        Validators.min(this.marginsLimits.marginInner.min),
        Validators.max(this.marginsLimits.marginInner.max),
        Validators.pattern(this.reDigits),
      ]),
      gutter: new FormControl(state.margins.gutter, [
        Validators.required,
        Validators.min(this.marginsLimits.gutter.min),
        Validators.max(this.marginsLimits.gutter.max),
        Validators.pattern(this.reDigits),
      ]),
      widePadding: new FormControl(state.margins.widePadding, [
        Validators.required,
        Validators.min(this.marginsLimits.widePadding.min),
        Validators.max(this.marginsLimits.widePadding.max),
        Validators.pattern(this.reDigits),
      ]),
    });
  }

  protected watchFormChanges() {
    this.form.controls["columnsCount"].valueChanges.subscribe((value) => {
      const columnsCount = value ? 2 : 1;
      this.marginsStore.setColumnsCount(columnsCount);
    });

    this.form.controls["widePaddingState"].valueChanges.subscribe((value) => {
      this.marginsStore.setWidePaddingState(value);

      const marginsState = this.marginsStore.getMarginsState();
      this.updateMarginsLimits(marginsState);

      this.cdr.detectChanges();

      if (!this.selectState.isMouseKeyPressed) {
        this.widePaddingState = value;
      }

      let widePadding = 0;
      if (value) {
        widePadding = this.form.get("widePadding")?.value || this.marginsLimits.widePadding.min;
        widePadding = Math.min(this.marginsLimits.widePadding.max, widePadding);
      }
      this.marginsStore.setMargin("widePadding", widePadding);
      this.form.get("widePadding")?.setValue(widePadding, { emitEvent: false });
    });

    this.form.controls["spaceLevel"].valueChanges.subscribe((value) => {
      this.marginsStore.setSpaceLevel(Math.round(value));
      this.setMarginsBySpaceLevel(value);
    });

    for (const margin of Object.keys(this.marginsStore.getMargins())) {
      const marginKey = margin as MarginKey;
      this.form.controls[marginKey].valueChanges.subscribe((value) => {
        this.marginsStore.setMargin(marginKey, Number.parseFloat(value), "sidebar");

        const marginsState = this.marginsStore.getMarginsState();
        this.updateMarginsLimitsAndCheck(marginsState);
      });
    }
  }

  protected setFormInputs(state: MarginsState) {
    let formValues = _.omit(state, ["margins"]);
    formValues = { ...formValues, ...state.margins };
    this.form.patchValue(formValues, { emitEvent: false });
    this.form.controls["columnsCount"].setValue(this.isColumnsCountEnable(state.columnsCount), { emitEvent: false });
    this.updateMarginsLimitsAndCheck(state);
  }

  private updateMarginsLimitsAndCheck(state: MarginsState) {
    this.updateMarginsLimits(state);
    this.checkColumnCounts(state);
    this.checkWidePadding(state);
    this.checkMarginsOnError(state);
  }

  private checkMarginsOnError(state: MarginsState) {
    for (const margin of Object.keys(state.margins)) {
      const marginKey = margin as MarginKey;
      const marginErrors = this.form.get(`${marginKey}`)?.errors;
      if (marginErrors) {
        this.addMarginToErrorList(marginKey);
      } else {
        this.removeMarginFromErrorList(marginKey);
      }
    }
  }

  private addMarginToErrorList(margin: MarginKey) {
    if (!this.marginsError.includes(margin)) {
      this.marginsError.push(margin);
    }
  }

  private removeMarginFromErrorList(margin: MarginKey) {
    if (this.marginsError.includes(margin)) {
      const index = this.marginsError.indexOf(margin);
      if (index > -1) {
        this.marginsError.splice(index, 1);
      }
    }
  }

  protected onSelectStateChange(selectState: MarginsSelectState) {
    this.selectState = selectState;
  }

  protected onMarginInputMouseEnter(marginKey: MarginKey) {
    this.marginsService.onHoverMargin(this.selectState, marginKey);
  }

  protected onMarginInputMouseLeave() {
    this.marginsService.onHoverMargin(this.selectState, undefined);
  }

  protected onMarginInputFocus(marginKey: MarginKey) {
    this.marginsService.onSelectMargin(this.selectState, marginKey, "sidebar");
  }

  protected onMarginInputFocusOut() {
    if (this.selectState.selectedSource === "sidebar") {
      this.marginsService.onSelectMargin(this.selectState, undefined, "sidebar");
    }
  }

  protected onBackClick() {
    this.router.navigate(["books", this.booksStore.getActiveBook()?.id, "markup"]);
  }

  protected async onNextClick() {
    if (!this.form.valid) {
      return;
    }
    this.analytics.event("margins-next");

    const activeBook = this.booksStore.getActiveBook()!;
    await this.save(activeBook);
  }

  protected async onNavTabClick(navTab: NavTab) {
    if (navTab.id === "margins") {
      return;
    }

    const activeBook = this.booksStore.getActiveBook()!;

    if (!this.form.valid) {
      this.router.navigate(["books", activeBook.id, navTab.id]);
      return;
    }

    await this.save(activeBook);
  }

  private async save(activeBook: Book) {
    this.loadingService.startLoading({
      fullPage: true,
      description: $localize`:@@books.margins.action-save-margins-state:`,
    });
    const result = await this.marginsService.updateState(activeBook);
    if (result === "success") {
      this.loadingService.stopLoading();
      this.router.navigate(["books", activeBook.id, "running-titles"]);
    } else {
      this.notificationService.error($localize`:@@books.error.save-margins-state:`);
      this.loadingService.stopLoading();
    }
  }

  protected isMarginsWideEnable() {
    return this.marginsStore.getWidePaddingState();
  }

  private checkColumnCounts(state: MarginsState) {
    if (this.isTablesInBook) {
      this.form.controls["columnsCount"].disable();
      this.marginsStore.setColumnsCount(1);
      return;
    }

    const minTextPadding = this.marginsService.getMinimalColumnSize("text-padding");
    const textPaddingDimentions = this.marginsService.calculateMarginTextDimentions(
      this.pageSize,
      state.margins,
      state.widePaddingState,
    );
    if (textPaddingDimentions.width / minTextPadding < 2) {
      this.form.controls["columnsCount"].setValue(false);
      this.form.controls["columnsCount"].disable();
      this.marginsStore.setColumnsCount(1);
      this.isColumsCountDisable = true;
    } else {
      this.form.controls["columnsCount"].enable();
      this.isColumsCountDisable = false;
    }
  }

  private checkWidePadding(state: MarginsState) {
    if (this.selectState.selected === "widePadding") {
      return;
    }

    if (this.isCenterImages) {
      this.form.controls["widePaddingState"].setValue(false);
      this.form.controls["widePaddingState"].disable();
      return;
    }

    const minTextPadding = this.marginsService.getMinimalColumnSize("text-padding");
    const textPaddingDimentions = this.marginsService.calculateMarginTextDimentions(
      this.pageSize,
      state.margins,
      state.widePaddingState,
    );
    const widePaddingMin = this.marginsService.getMinimalColumnSize("wide-padding");

    const textPaddingWidth = textPaddingDimentions.width;
    let isWidePaddingDisable = false;

    if (!state.widePaddingState) {
      isWidePaddingDisable = textPaddingWidth - minTextPadding - state.margins.gutter < widePaddingMin;
    } else {
      isWidePaddingDisable = state.margins.widePadding < widePaddingMin;
    }

    if (this.marginsLimits.widePadding.max < this.marginsLimits.widePadding.min) {
      isWidePaddingDisable = true;
    }

    if (isWidePaddingDisable) {
      this.form.controls["widePaddingState"].setValue(false);
      this.form.controls["widePaddingState"].disable({ emitEvent: false });
      this.isWidePaddingDisable = true;
    } else {
      this.form.controls["widePaddingState"].enable({ emitEvent: false });
      this.isWidePaddingDisable = false;

      if (this.widePaddingState && this.selectState.isMouseKeyPressed) {
        this.form.controls["widePaddingState"].setValue(this.widePaddingState);
      }
    }
  }

  protected getWidePaddingStateInfo() {
    return $localize`:@@books.margins.wide-padding-info:`;
  }

  private setMarginsBySpaceLevel(spaceLevel: number) {
    const margins = this.marginsService.calculateMarginsBySpaceLevel(this.marginsLimits, this.pageSize, spaceLevel);
    this.marginsStore.setMargins(margins, "sidebar");

    const marginsState = this.marginsStore.getMarginsState();
    // this.updateMarginsLimits(marginsState);
    this.updateMarginsLimitsAndCheck(marginsState);
    this.setFormInputs(marginsState);

    this.cdr.detectChanges();
  }

  private calculateMarginsLimits(state: MarginsState) {
    this.marginsLimits = this.marginsService.calculateMarginsLimits(this.pageSize, state);
  }

  private updateMarginsLimits(state: MarginsState) {
    this.calculateMarginsLimits(state);
    for (const margin of Object.keys(state.margins)) {
      const marginKey = margin as MarginKey;
      this.form.controls[marginKey].setValidators([
        Validators.required,
        Validators.min(this.marginsLimits[marginKey].min),
        Validators.max(this.marginsLimits[marginKey].max),
        Validators.pattern(this.reDigits),
      ]);
    }

    this.cdr.detectChanges();
  }

  protected getMarginsErrorsList() {
    const errors: InfoBlockData[] = [];

    let id = 0;
    for (const margin of Object.keys(this.marginsStore.getMargins())) {
      const marginKey = margin as MarginKey;
      const marginErrors = this.form.get(`${marginKey}`)?.errors;
      if (!marginErrors) {
        continue;
      }

      for (const [errorKey, errorValue] of Object.entries(marginErrors)) {
        let error: string;
        let admissibleValue: string | undefined = undefined;
        switch (marginKey) {
          case "marginTop":
            error = $localize`:@@books.margins.top:`;
            break;
          case "marginBottom":
            error = $localize`:@@books.margins.bottom:`;
            break;
          case "marginOuter":
            error = $localize`:@@books.margins.outer:`;
            break;
          case "marginInner":
            error = $localize`:@@books.margins.inner:`;
            break;
          case "gutter":
            error = $localize`:@@books.margins.gutter:`;
            break;
          case "widePadding":
            error = $localize`:@@books.margins.padding-wide:`;
            break;
          default:
            error = "";
            break;
        }

        if (error.length !== 0) {
          error += ": ";
        }

        switch (errorKey) {
          case "required":
            error += $localize`:@@books.error.empty-margin:`;
            break;
          case "pattern":
            error += $localize`:@@books.error.should-be-digits:`;
            break;
          case "min":
            error += $localize`:@@books.error.should-be-gt-min-value:`;
            admissibleValue = errorValue.min;
            break;
          case "max":
            error += $localize`:@@books.error.should-be-lt-max-value:`;
            admissibleValue = errorValue.max;
            break;
          default:
            break;
        }

        if (admissibleValue) {
          error += " ";
        }

        errors.push({
          id: id,
          textData: [
            { text: error },
            { text: admissibleValue ?? "", action: { controlName: marginKey, value: admissibleValue ?? "" } },
          ],
        });
        id++;
      }
    }

    return errors;
  }

  protected onMarginsErrorsInfoBlockActionClick(actionData: InfoBlockActionData) {
    this.form?.get(actionData.controlName)?.setValue(actionData.value);
  }

  private isColumnsCountEnable(columnsCount: number) {
    return columnsCount > 1;
  }

  protected getSidebarTabCssClassList(tabNumber: number): string[] {
    const result: string[] = [];

    result.push("sidebar-tab");
    if (this.activeSidebarTabNumber === tabNumber) {
      result.push("active-tab");
    }

    return result;
  }

  protected activeSidebarTab(tabNumber: number) {
    this.activeSidebarTabNumber = tabNumber;
  }

  protected getTabIcon(tabNumber: number) {
    if (this.activeSidebarTabNumber === tabNumber) {
      return "--color-font-tab-active";
    }
    return "--color-font-tab";
  }

  protected isInputHighlighted(margin: MarginKey) {
    return this.selectState.hovered === margin || this.selectState.selected === margin;
  }

  protected getInputCssClassList(margin: MarginKey): string[] {
    const result: string[] = [];

    if (this.selectState.selected === margin) {
      result.push("selected");
    }
    if (this.selectState.hovered === margin) {
      result.push("hovered");
    }

    return result;
  }

  protected pluralizeChargeOff() {
    const bookPrice = this.booksStore.getBookPrice(this.booksStore.getActiveBook()!.id) ?? 0;

    return `${$localize`:@@books.margins.sidebar-tabs.ebook.price:`} ${this.pricingViewService.pluralizeChargeOff(
      bookPrice,
    )} ${bookPrice} ${this.pricingViewService.pluralizeCredits(bookPrice)}`;
  }

  protected getInfoLabels(): InfoBlockData[] {
    const labels: InfoBlockData[] = [];

    if (this.isTablesInBook) {
      labels.push({ textData: [{ text: $localize`:@@books.margin.warning-multi-column-layout:` }] });
    }

    if (this.isCenterImages) {
      labels.push({
        textData: [
          {
            text: $localize`:@@books.margin.warning-wide-paddings-layout:`,
          },
          {
            text: $localize`:@@books.margin.warning-wide-paddings-layout-settings:`,
            action: {
              controlName: "imagesPosition",
              value: $localize`:@@books.margin.warning-wide-paddings-layout-settings:`,
            },
          },
          {
            text: $localize`:@@books.margin.warning-wide-paddings-layout-2:`,
          },
        ],
      });
    }

    if (this.isColumsCountDisable || this.isWidePaddingDisable) {
      labels.push({ textData: [{ text: $localize`:@@books.margins.warning-short-text-padding:` }] });
    }

    return labels;
  }

  protected onInfoBlockActionClick(actionData: InfoBlockActionData) {
    if (actionData.controlName === "imagesPosition") {
      this.isImagesSettingsModalVisible = true;
    }
  }

  protected closeImagesSettingsModalVisible() {
    this.isImagesSettingsModalVisible = false;
  }

  protected async onSaveImagesPosition(centerImages: boolean) {
    if (!this.book) {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
      return;
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.updateCenterImagesSettings(this.book, centerImages);
    this.loadingService.stopLoading();

    if (result === "success") {
      this.cdr.detectChanges();
    } else if (result === "error") {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }
}
