import { Injectable } from "@angular/core";
import { SelectValue } from "@metranpage/components";
import { I18nService } from "@metranpage/i18n";
import { CreditsDiscount } from "@metranpage/pricing-data";

export type InputCalculatorFieldsValue = number | undefined;

@Injectable({
  providedIn: "root",
})
export class PricingViewService {
  constructor(private readonly i18nService: I18nService) {}

  pluralizeCredits(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, {
      credits: number,
    });
  }

  pluralizeSymbols(number: number, isThousand = true) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-symbols.1:`, {
      symbols: isThousand ? number * 1000 : number,
    });
  }

  pluralizeImages(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-images.1:`, {
      images: number,
    });
  }

  pluralizeSimpleCredits(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-simple-credits:`, {
      credits: number,
    });
  }

  pluralizeGoldCredits(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-gold-credits:`, {
      credits: number,
    });
  }

  pluralizeNotEnoughtCredits(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-not-enought-credits:`, {
      credits: number,
    });
  }

  pluralizeBooks(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-books:`, {
      books: number,
    });
  }

  pluralizeChargeOff(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-charge-off:`, {
      credits: number,
    });
  }

  pluralizeCreditsNeed(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.calculator.panel.pluralize-for-layout-need:`, {
      credits: number,
    });
  }

  pluralizeWeeks(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-weeks:`, {
      weeks: number,
    });
  }

  pluralizeDays(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-days:`, {
      days: number,
    });
  }

  pluralizeMonths(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-months:`, {
      months: number,
    });
  }

  pluralizeYears(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-years:`, {
      years: number,
    });
  }

  pluralizeAuthorLists(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-author-lists:`, {
      value: number,
    });
  }

  pluralizeActiveProjects(number: number) {
    return this.i18nService.pluralize($localize`:@@pricing.pluralize-active-projects:`, {
      value: number,
    });
  }

  thousandsFormat(value: number, separateFrom = 9999) {
    if (value > separateFrom) {
      return `${new Intl.NumberFormat("ru-RU").format(value)}`;
    }
    return `${value.toString()}`;
  }

  priceFormat(value: number, currency: string, separateFrom = 9999) {
    return `${this.thousandsFormat(value, separateFrom)} ${currency}`;
  }

  symbolsFormat(value: number) {
    let result = `${value.toString()}`;
    if (value > 1_000) {
      result = `${new Intl.NumberFormat("ru-RU").format(
        value / 1_000,
      )} ${$localize`:@@pricing.tariff-table.thousand:`}`;
    }
    if (value > 1_000_000) {
      result = `${new Intl.NumberFormat("ru-RU").format(
        value / 1_000_000,
      )} ${$localize`:@@pricing.tariff-table.million:`}`;
    }
    return result;
  }

  getDiscountValue(credits: number, discounts: CreditsDiscount[] | undefined) {
    if (!discounts) {
      return 0;
    }
    const sale = discounts?.find((element) => {
      let to = element.creditTo;
      if (!to) {
        // Infinity casts to null, when passing from backend to frontend,
        // so we check, if we get null - then assume this is Infinity
        to = Number.POSITIVE_INFINITY;
      }
      return credits >= element.creditFrom && credits < to;
    });

    if (!sale) {
      return 0;
    }
    return sale.discount;
  }

  creditsNotEnough(userCredits: number, bookPriceCredits: number) {
    const sub = userCredits - bookPriceCredits;
    if (sub < 0) {
      return Math.abs(sub);
    }
    return 0;
  }

  getOptionsForCurrencySelect() {
    const currencies = [
      { symbol: "₽", description: $localize`:@@admin.currencies.ruouble:` },
      { symbol: "$", description: $localize`:@@admin.currencies.dollar-us:` },
      { symbol: "€", description: $localize`:@@admin.currencies.euro:` },
      { symbol: "£", description: $localize`:@@admin.currencies.pound-sterling:` },
    ];
    const options: SelectValue[] = [];
    for (const currency of currencies) {
      options.push({
        id: currency.symbol,
        value: `${currency.symbol} - ${currency.description}`,
      });
    }
    return options;
  }

  getCompanyCurrencyOptions() {
    const currencies = [
      { symbol: "RUB", description: $localize`:@@admin.currencies.ruouble:` },
      { symbol: "USD", description: $localize`:@@admin.currencies.dollar-us:` },
      { symbol: "EUR", description: $localize`:@@admin.currencies.euro:` },
      { symbol: "GBP", description: $localize`:@@admin.currencies.pound-sterling:` },

      { symbol: "UAH", description: "UAH" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "BYR", description: "BYR" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "BYN", description: "BYN" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "AZN", description: "AZN" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "CHF", description: "CHF" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "CZK", description: "CZK" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "CAD", description: "CAD" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "PLN", description: "PLN" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "SEK", description: "SEK" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "TRY", description: "TRY" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "CNY", description: "CNY" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
      { symbol: "INR", description: "INR" /*$localize`:@@admin.currencies.pound-sterling:`*/ },
    ];
    const options: SelectValue[] = [];
    for (const currency of currencies) {
      options.push({
        id: currency.symbol,
        value: `${currency.symbol} - ${currency.description}`,
      });
    }
    return options;
  }

  getCurrencySymbol(currency: string | undefined) {
    switch (currency?.toLowerCase()) {
      case "rub":
        return "₽";
      case "usd":
        return "$";
      case "eur":
        return "€";
      case "gbp":
        return "£";
      default:
        return "₽";
    }
  }

  getOptionsForSubtitleSelect() {
    const options: SelectValue[] = [
      { id: "free", value: $localize`:@@pricing.tariffs.for-whom.free:` },
      { id: "beginner", value: $localize`:@@pricing.tariffs.for-whom.beginner:` },
      { id: "specialist", value: $localize`:@@pricing.tariffs.for-whom.specialist:` },
      { id: "master", value: $localize`:@@pricing.tariffs.for-whom.master:` },
    ];
    return options;
  }
}
