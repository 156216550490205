<m-app-bar
  *ngIf="shouldShowTopBar"
  [user]="user"
  [userBalance]="userBalance!"
  [isLanguageSwitchAvailable]="isLanguageSwitchAvailable()"
  (onThemeToggle)="onThemeToggle()"
  (onLanguageChange)="onLanguageChange($event)"
  (onLogoutClick)="onLogoutClick()"
  (onPromocodeEnter)="onPromocodeEnter($event)"
></m-app-bar>

<router-outlet></router-outlet>

<router-outlet name="modal"></router-outlet>

<m-notification-host></m-notification-host>

<m-mobile-alert></m-mobile-alert>

<m-welcome-modal
  *ngIf="!userActionMessageRead && (userActionMessage$ | async) as message"
  [message]="message"
  (actionClick)="onModalActionClick($event, true)"
  (close)="onModalActionClick($event, false)"
></m-welcome-modal>

<m-loading-spinner *ngIf="isLoading" @fadeInOut>{{loadingDescription}}</m-loading-spinner>

<m-account-connect-telegram-modal-view
  *ngIf="isAccountConnectTelegramModalVisible"
  [user]="user"
  [userRewardOneTime]="getSubscribeToTelegramChannelReward()"
  (onConnect)="onConnectTelegramClick()"
  (onSubscribeToChannel)="onSubscribeToChannelTelegramClick()"
  (onCheckSubscribeToChannel)="onCheckSubscribeToChannelTelegramClick()"
  (onClose)="hideAccountConnectTelegramModal()"
/>
