import { Injectable } from "@angular/core";
import {
  ApplyTemplateRequestDto,
  BookCover,
  BookCoverTemplate,
  CoverImageType,
  PredefinedSvgObjects,
  UploadGeneratedImageRequestDto,
  UploadImageResponseDto,
} from "@metranpage/book-data";
import { AnalyticsService } from "@metranpage/core";
import { plainToInstance } from "class-transformer";
import { CoverApi } from "./cover.api";
import { PublishedCoverStore } from "./published-cover.store";

@Injectable({
  providedIn: "root",
})
export class CoverService {
  constructor(
    private readonly coverApi: CoverApi,
    private readonly publishedCoverStore: PublishedCoverStore,
    private readonly analytics: AnalyticsService,
  ) {}

  async getCoverByBookId(bookId: number): Promise<BookCover> {
    const cover = await this.coverApi.getCover(bookId);
    return plainToInstance(BookCover, cover);
  }

  async createDefaultCover(bookId: number): Promise<BookCover> {
    const cover = await this.coverApi.createDefaultCover(bookId);
    return plainToInstance(BookCover, cover);
  }

  updateCover(bookId: number, data: Partial<BookCover>): Promise<BookCover> {
    return this.coverApi.updateCover(bookId, data);
  }

  toTemplate(coverId: number): Promise<BookCoverTemplate> {
    return this.coverApi.toTemplate(coverId);
  }

  async applyTemplate(data: ApplyTemplateRequestDto): Promise<BookCover> {
    const cover = await this.coverApi.applyTemplate(data);
    return plainToInstance(BookCover, cover);
  }

  /////////////////////////////
  // Images

  uploadImage(bookId: number, file: File, type: CoverImageType): Promise<UploadImageResponseDto> {
    return this.coverApi.uploadImage(bookId, file, type);
  }

  uploadGeneratedObjectImage(data: UploadGeneratedImageRequestDto): Promise<UploadImageResponseDto> {
    return this.coverApi.uploadGeneratedObjectImage(data);
  }

  getObjectImageUrl(bookId: number, imageName: string): string {
    return this.coverApi.getObjectImageUrl(bookId, imageName);
  }

  getCoverPreviewImageUrl(bookId: number): string {
    return this.coverApi.getCoverPreviewImageUrl(bookId);
  }

  getCoverFullsizeImageUrl(bookId: number): string {
    return this.coverApi.getCoverFullsizeImageUrl(bookId);
  }

  getCoverPreviewImageBlob(bookId: number): Promise<Blob> {
    return this.coverApi.getCoverPreviewImageBlob(bookId);
  }

  getCoverFullsizeImageBlob(bookId: number): Promise<Blob> {
    return this.coverApi.getCoverFullsizeImageBlob(bookId);
  }

  /////////////////////////////////////
  // SVG Shapes

  getPredefinedSvgObjects(): Promise<PredefinedSvgObjects[]> {
    return this.coverApi.getSvgObjects();
  }

  /////////////////////////////////////
  // Share

  async publishBookCover(id: number) {
    return await this.coverApi.publishBookCover(id);
  }

  async unpublishBookCover(id: number) {
    return await this.coverApi.unpublishBookCover(id);
  }

  async getPublishedCover(id: number) {
    return await this.coverApi.getPublishedCover(id);
  }

  async getPublishedCoverUnauthorized(id: number) {
    return await this.coverApi.getPublishedCoverUnauthorized(id);
  }

  /////////////////////////////////////
  // Likes

  async loadPublishedCoversPaginated(page: number) {
    const publishedCoversData = await this.coverApi.getPublishedCoversPaginated(page);
    this.publishedCoverStore.addPublishedCoversToEnd(publishedCoversData.items);
    this.publishedCoverStore.setPublishedCoversPageCount(publishedCoversData.pageCount);
  }

  async loadPublishedCoversPaginatedUnauthorized(page: number) {
    const publishedCoversData = await this.coverApi.getPublishedCoversPaginatedUnauthorized(page);
    this.publishedCoverStore.addPublishedCoversToEnd(publishedCoversData.items);
    this.publishedCoverStore.setPublishedCoversPageCount(publishedCoversData.pageCount);
  }

  getUrlForCoverThumbnailByCoverId(id: number) {
    return this.coverApi.getUrlForCoverThumbnailByCoverId(id);
  }

  getUrlForCoverByCoverId(id: number) {
    return this.coverApi.getUrlForCoverByCoverId(id);
  }

  async likeBookCover(id: number) {
    this.publishedCoverStore.updatePublishedCoverOnLike(id, "like");

    this.analytics.event("like-cover");

    return await this.coverApi.likeBookCover(id);
  }

  async unlikeBookCover(id: number) {
    this.publishedCoverStore.updatePublishedCoverOnLike(id, "unlike");

    this.analytics.event("unlike-cover");

    return await this.coverApi.unlikeBookCover(id);
  }
}
