import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Book, BookSizeAndType, UpdateBookInfo } from "@metranpage/book-data";
import { LoadingService, RouterService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { FormatService } from "@metranpage/format-data";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { UserStore } from "@metranpage/user-data";
import { Observable, Subscription, map, timer } from "rxjs";
import { BookRouterService } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";

@Component({
  selector: "m-books-page",
  templateUrl: "./books.page.html",
  styleUrls: ["./books.page.scss"],
})
export class BooksPage implements OnInit {
  books$: Observable<Book[]>;
  books: Book[] = [];
  bookToEdit?: Book;
  bookToDelete?: Book;

  onboardingStarted = false;

  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;

  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  protected isChangeTariffModalVisible = false;

  protected timeoutOnboarding = 2000;

  sub: Subscription = new Subscription();

  constructor(
    private readonly bookService: BookService,
    private readonly bookRouterService: BookRouterService,
    private readonly pricingService: PricingService,
    private readonly onboardingService: OnboardingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly routerService: RouterService,
    private readonly formatService: FormatService,
    private readonly cdr: ChangeDetectorRef,
    booksStore: BooksStore,
    userStore: UserStore,
  ) {
    this.books$ = booksStore.getBooksObservable().pipe(map((bl) => bl.filter((b) => b.isVisibleToUser)));

    this.sub.add(
      this.books$.subscribe((books) => {
        this.books = books;

        this.sub.add(
          timer(this.timeoutOnboarding).subscribe(() => {
            this.startOnboarding();
          }),
        );
      }),
    );

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();
      }),
    );
  }

  async ngOnInit() {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.updateBooks();
    this.loadingService.stopLoading();
    if (result === "error") {
      this.notificationService.error($localize`:@@books.error.cant-load-books:`);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    const url = this.router.routerState.snapshot.url.replaceAll("/", "");
    if (this.books.length > 0 && (!this.onboardingStarted || showForced) && url === "books") {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("books-page", showForced);
    }
  }

  async onNewBookClick() {
    if (this.books.length >= (this.activeSubscription?.tariff.activeProjects || 1)) {
      this.isChangeTariffModalVisible = true;
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const createBookResult = await this.bookService.createBook();
    this.loadingService.stopLoading();

    if (createBookResult.status === "success") {
      const formatData = this.formatService.getFormatData(
        createBookResult.book?.bookSettings?.width,
        createBookResult.book?.bookSettings?.height,
      );
      this.bookRouterService.showModal(createBookResult.book!, "templates", "new", formatData);
    } else {
      this.notificationService.error($localize`:@@books.error.cant-create-book:`);
    }
  }

  protected setBookToEdit(book: Book) {
    this.bookRouterService.showModal(book, "book-data", "edit");
  }

  async onInfoSet(bookInfo: UpdateBookInfo, isEnd: boolean) {
    if (!this.bookToEdit) {
      return;
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.updateBookInfo(this.bookToEdit, bookInfo);
    this.loadingService.stopLoading();

    if (result === "success") {
      if (isEnd) {
        this.bookToEdit = undefined;
        this.cdr.detectChanges();
      }
    } else if (result === "error") {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  async onBookSizeSet(bookSize: BookSizeAndType, isEnd: boolean) {
    if (!this.bookToEdit) {
      return;
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.setBookSizeAndType(this.bookToEdit, bookSize);
    this.loadingService.stopLoading();
    if (result === "success") {
      if (isEnd) {
        this.bookToEdit = undefined;
        this.cdr.detectChanges();
      }
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  protected cancelEdition() {
    this.bookToEdit = undefined;
  }

  setBookToDelete(book: Book) {
    this.bookToDelete = book;
  }

  async deleteBook(book: Book) {
    const result = await this.bookService.deleteBook(book);
    if (result === "success") {
      this.bookToDelete = undefined;
    } else if (result === "error") {
      this.notificationService.error($localize`:@@books.error.cant-delete-book:`);
    }
  }

  cancelDeletion() {
    this.bookToDelete = undefined;
  }

  protected onClickChangeTariffModal() {
    this.router.navigate(["payments", "subscription"]);
  }

  protected onCloseChangeTariffModal() {
    this.isChangeTariffModalVisible = false;
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected getInfoModalTitle() {
    return this.bookService.getInfoModalTitle(this.higherTariff);
  }

  protected getInfoModalText() {
    return this.bookService.getInfoModalText(this.activeSubscription, this.higherTariff);
  }
}
