import { Component, HostListener, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Book } from "@metranpage/book-data";
import { CompanyStore } from "@metranpage/company";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import {
  AnalyticsService,
  BreadcrumbsService,
  IS_PURCHASES_AVAILABLE,
  LoadingService,
  LoadingState,
  RewardsService,
  RewardsStore,
  RouterService,
  UserRewardOneTime,
  filterUndefined,
} from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { FormatService } from "@metranpage/format-data";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService, PricingViewService } from "@metranpage/pricing";
import { ActiveSubscription, PaymentCurrency, Tariff } from "@metranpage/pricing-data";
import { ThemeService } from "@metranpage/theme";
import { User, UserStore } from "@metranpage/user-data";
import { BalanceData, PaymentData } from "@metranpage/user-payment-data";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, Subscription, combineLatest, map } from "rxjs";
import { filter, first, skip } from "rxjs/operators";
import { BookRouterService } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksApi } from "../../services/books.api";
import { BooksStore } from "../../services/books.store";

@UntilDestroy()
@Component({
  selector: "m-preview-page",
  templateUrl: "./preview.page.html",
  styleUrls: ["./preview.page.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class PreviewPage implements OnInit, OnDestroy {
  books$: Observable<Book[]>;
  books: Book[] = [];
  book?: Book;
  previews$!: Observable<string[]>;
  previewsThumbnails$!: Observable<string[]>;
  previews: string[] = [];
  loadingState$: Observable<LoadingState>;

  redirectOnLayoutFinishUrl?: string;

  protected user?: User;
  protected selectedLeftPage = 0;
  protected isPaymentsModalVisible = false;
  protected isLowBalanceModalVisible = false;
  protected isFreemiumModalVisible = false;
  protected isVerificationEmailModalVisible = false;
  protected isChangeTariffModalVisible = false;
  protected isPreviewsActual = false;
  protected isPrintPreviewsReady = false;
  protected isEpubPreviewsReady = false;
  protected isFinalsActual = false;
  protected isPrintFinalsReady = false;
  protected isEpubFinalsReady = false;
  protected layoutStep = "start";
  protected showLinksSidebar = false;
  protected showLayoutStepsSidebar = false;
  protected shouldSendReadyEmail = false;
  protected paymentData!: PaymentData;
  protected balanceData!: BalanceData;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected onboardingStarted = false;

  protected rewardsOneTime: UserRewardOneTime[] = [];
  protected currency: PaymentCurrency = "RUB";

  private sub = new Subscription();

  tariffsForUpgrade$!: Observable<Tariff[]>;

  constructor(
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly bookRouterService: BookRouterService,
    private readonly router: Router,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly booksApi: BooksApi,
    private readonly analytics: AnalyticsService,
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly pricingService: PricingService,
    private readonly onboardingService: OnboardingService,
    private readonly routerService: RouterService,
    private readonly themeService: ThemeService,
    private readonly formatService: FormatService,
    private readonly companyStore: CompanyStore,
    private readonly rewardsService: RewardsService,
    rewardsStore: RewardsStore,
    private readonly pricingViewService: PricingViewService,
    userStore: UserStore,
    @Inject(IS_PURCHASES_AVAILABLE) protected readonly isPurchasesAvailable: boolean,
  ) {
    this.loadingState$ = this.loadingService.loadingState$;

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.books$ = booksStore.getBooksObservable().pipe(map((bl) => bl.filter((b) => b.isVisibleToUser)));

    this.sub.add(
      this.books$.subscribe((books) => {
        this.books = books;
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.redirectOnLayoutFinishUrl = company?.redirectOnLayoutFinishUrl;
          this.currency = company.currency;
        }),
    );

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );
  }

  ngOnInit(): void {
    this.loadingService.startLoading({ fullPage: true });

    this.previews$ = this.booksStore.getActiveBookObservable().pipe(
      filter((b) => !!b),
      map((book) => {
        if (book?.bookResults?.previews) {
          this.previews = book?.bookResults?.previews;
          this.startOnboarding();

          book?.bookResults.previews.sort((a, b) => a.localeCompare(b, "en", { numeric: true }));
          return book?.bookResults.previews.map((url) => {
            return this.booksApi.getUrlForBookFile(book?.id, `previews/${url}`);
          });
        }
        return [];
      }),
    );

    this.previewsThumbnails$ = this.booksStore.getActiveBookObservable().pipe(
      filter((b) => !!b),
      map((book) => {
        if (book?.bookResults?.previewsThumbnails) {
          book?.bookResults.previewsThumbnails.sort((a, b) => a.localeCompare(b, "en", { numeric: true }));
          return book?.bookResults.previewsThumbnails.map((url) => {
            return this.booksApi.getUrlForBookFile(book?.id, `previews-thumbnails/${url}`);
          });
        }
        return [];
      }),
    );

    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), first())
        .subscribe(async (book) => {
          this.book = book;
          this.updateBookResultState(book);

          // stop fullpage loading
          this.loadingService.stopLoading();
          this.breadcrumbsService.enableBreadcrumbs();

          if (book.actionKey !== "preview" && book.actionKey !== "final" && !this.isPreviewsActual) {
            // if no current action and previews not actual - make preview
            await this.makePreview();
          } else if (book?.actionKey === "preview") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-preview-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          } else if (book?.actionKey === "final") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-final-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          } else if (!book.actionKey && this.isLayoutFinished("final")) {
            if (this.redirectOnLayoutFinishUrl) {
              window.location.href = this.redirectOnLayoutFinishUrl;
            }
          }
        }),
    );

    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), skip(1))
        .subscribe(async (book) => {
          this.book = book;
          this.updateBookResultState(book);

          this.loadingService.stopLoading();

          if (!book?.actionKey) {
            // if (this.isPreviewsActual || this.isFinalsActual) {
            this.breadcrumbsService.enableBreadcrumbs();
            // }

            if (this.redirectOnLayoutFinishUrl) {
              if (this.isLayoutFinished("final")) {
                window.location.href = this.redirectOnLayoutFinishUrl;
              }
            }
          } else if (book?.actionKey === "preview") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-preview-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          } else if (book?.actionKey === "final") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-final-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    if (this.previews.length && (!this.onboardingStarted || showForced)) {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("preview-page", showForced, 500);
    }
  }

  getThemeSpecificImageSource(): string {
    const themeSuffix = this.themeService.getThemeSuffix();
    return `/assets/img/info-modal-free-layout-${themeSuffix}.png`;
  }

  getThemeEpub(): string {
    const themeSuffix = this.themeService.getThemeSuffix();
    return `/assets/img/epub-version-${themeSuffix}.png`;
  }

  // getText() {
  //   if (this.direction === "column") {
  //     return $localize`:@@books.preview.preview-info-text:`;
  //   }
  //   return;
  // }

  private updateBookResultState(book: Book) {
    this.isPrintPreviewsReady = book?.bookResults?.isPrintPreviewsReady ?? false;
    this.isEpubPreviewsReady = book?.bookResults?.isEpubPreviewsReady ?? false;
    this.isPrintFinalsReady = book?.bookResults?.isPrintFinalsReady ?? false;
    this.isEpubFinalsReady = book?.bookResults?.isEpubFinalsReady ?? false;
    this.layoutStep = book?.bookResults?.layoutStep ?? "start";

    if (book?.bookResults?.previewTime && book?.bookSettings?.updatedAt) {
      this.isPreviewsActual = book?.bookResults?.previewTime > book?.bookSettings?.updatedAt;
    }
    if (book?.bookResults?.finalTime && book?.bookSettings?.updatedAt) {
      this.isFinalsActual = book?.bookResults?.finalTime > book?.bookSettings?.updatedAt;
    }

    this.showLinksSidebar = false;
    this.showLayoutStepsSidebar = false;

    if (!book?.actionKey && this.isPreviewsActual && !this.isFinalsActual) {
      this.showLinksSidebar = true;
    } else if (
      (book?.actionKey === "" && this.isPreviewsActual && this.isFinalsActual) ||
      book?.actionKey === "preview" ||
      book?.actionKey === "final" ||
      (!this.isPreviewsActual && !this.isFinalsActual)
    ) {
      this.showLayoutStepsSidebar = true;
    }
  }

  protected onSelectLeftPage(value: number) {
    this.selectedLeftPage = value;
  }

  protected async onProduceClick() {
    this.notificationService.closeAll();
    const activeBook = this.booksStore.getActiveBook()!;

    if (!this.user?.isVerified) {
      this.router.navigate(["/account/account-verification"], { queryParams: { from: "preview", id: activeBook.id } });
      return;
    }

    await this.calculatePaymentData(activeBook);
  }

  protected onEditClick(step: string) {
    const formatData = this.formatService.getFormatData(
      this.book?.bookSettings?.width,
      this.book?.bookSettings?.height,
    );
    if (step === "upload") {
      this.bookRouterService.showModal(this.book!, "upload", "edit");
      return;
    }
    if (step === "template") {
      this.bookRouterService.showModal(this.book!, "templates", "edit");
      return;
    }

    this.router.navigate(["books", this.booksStore.getActiveBook()?.id, step]);
  }

  protected showPricingModal() {
    if (this.paymentData.bookPrice === 0) {
      this.isFreemiumModalVisible = true;
      return;
    }

    if (this.paymentData.userBalance.credits >= this.paymentData.bookPrice) {
      this.isPaymentsModalVisible = true;
      return;
    }

    this.isLowBalanceModalVisible = true;
  }

  protected closePricingModal() {
    this.isPaymentsModalVisible = false;
    this.isLowBalanceModalVisible = false;
  }

  protected async onLayoutFreeBook() {
    this.closeFreemiumModal();
    await this.makeFinalAfterPayment(false);
  }

  protected closeFreemiumModal() {
    this.isFreemiumModalVisible = false;
  }

  protected async onPayGoldCredits(_value: number) {
    this.closePricingModal();
    await this.makeFinalAfterPayment(true);
  }

  protected async onPayCredits(_value: number) {
    this.closePricingModal();
    await this.makeFinalAfterPayment(false);
  }

  protected onCreditsBuy(credits: number) {
    window.open(`payments/await-payment-link?creditsCount=${credits}`, "_blank");
  }

  protected onBuySubscription(tariff: Tariff) {
    this.closePricingModal();
    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }

  async makePreview() {
    this.analytics.event("preview-started");
    this.notificationService.closeAll();

    this.loadingService.startLoading({ description: $localize`:@@books.styles.action-preview-hint:` });
    this.breadcrumbsService.disableBreadcrumbs();

    this.showLayoutStepsSidebar = true;
    this.layoutStep = "start";

    const activeBook = this.booksStore.getActiveBook()!;
    const result = await this.bookService.producePreview(activeBook);
    if (result === "success") {
      this.shouldSendReadyEmail = true;
    } else {
      this.notificationService.error($localize`:@@books.build.cant-start-preview-error:`);
      this.breadcrumbsService.enableBreadcrumbs();
      this.loadingService.stopLoading();
    }
  }

  private async calculatePaymentData(book: Book) {
    this.loadingService.startLoading({ fullPage: true });
    const paymentData = await this.bookService.checkCredits(book);
    if (paymentData === "error") {
      this.notificationService.error($localize`:@@books.build.cant-start-processing-error:`);
      this.loadingService.stopLoading();
      return;
    }

    this.paymentData = paymentData;
    this.balanceData = {
      price: paymentData.bookPrice,
      userBalance: paymentData.userBalance,
    };
    this.showPricingModal();
    this.loadingService.stopLoading();
  }

  private async makeFinalAfterPayment(withGoldCredit: boolean) {
    this.analytics.event("preview-get-final");
    this.notificationService.closeAll();
    this.loadingService.startLoading({ description: $localize`:@@books.book.action-final:` });
    this.breadcrumbsService.disableBreadcrumbs();

    this.showLayoutStepsSidebar = true;
    this.layoutStep = "start";

    const activeBook = this.booksStore.getActiveBook()!;
    const result = await this.bookService.produceFinal(activeBook, withGoldCredit);
    if (result === "success") {
      // this.loadingService.stopLoading();
      // this.router.navigateByUrl('/');
    } else {
      this.notificationService.error($localize`:@@books.build.cant-start-processing-error:`);
      this.breadcrumbsService.enableBreadcrumbs();
      this.loadingService.stopLoading();
    }
  }

  protected async onNewBookClick() {
    if (this.books.length >= (this.activeSubscription?.tariff.activeProjects ?? 1)) {
      this.isChangeTariffModalVisible = true;
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const createBookResult = await this.bookService.createBook();
    this.loadingService.stopLoading();

    if (createBookResult.status === "success") {
      if (!createBookResult.book) {
        this.notificationService.error($localize`:@@books.error.cant-create-book:`);
        return;
      }

      this.bookRouterService.navigateToBookEditStep(createBookResult.book!, "templates", "new");
    } else {
      this.notificationService.error($localize`:@@books.error.cant-create-book:`);
    }
  }

  protected async onDownloadProjectClick() {
    const activeBook = this.booksStore.getActiveBook()!;
    const fileName = activeBook?.bookResults?.finalPackageUrl;
    if (fileName) {
      await this.bookService.downloadFinal(activeBook, fileName, "");
    }
  }

  protected async onDownloadPreviewEpubClick() {
    const activeBook = this.booksStore.getActiveBook()!;
    // const fileName = activeBook?.bookResults?.finalPackageUrl;
    const fileName = activeBook?.bookResults?.epubUrl;
    if (fileName) {
      await this.bookService.downloadEpub(activeBook, fileName);
    }
  }

  protected onClickChangeTariffModal() {
    this.router.navigate(["payments", "subscription"]);
  }

  protected onCloseChangeTariffModal() {
    this.isChangeTariffModalVisible = false;
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected getInfoModalTitle() {
    return this.bookService.getInfoModalTitle(this.higherTariff);
  }

  protected getInfoModalText() {
    return this.bookService.getInfoModalText(this.activeSubscription, this.higherTariff);
  }

  protected isLayoutFinished(mode: "preview" | "final") {
    let isPrintEnableAndReady = this.book?.exportPrint && this.book?.bookResults?.isPrintPreviewsReady;
    let isEpubEnableAndReady = this.book?.exportEpub && this.book?.bookResults?.isEpubPreviewsReady;

    if (mode === "final") {
      isPrintEnableAndReady = this.book?.exportPrint && this.book?.bookResults?.isPrintFinalsReady;
      isEpubEnableAndReady = this.book?.exportEpub && this.book?.bookResults?.isEpubFinalsReady;
    }

    if (this.book?.exportPrint && this.book?.exportEpub) {
      if (isPrintEnableAndReady && isEpubEnableAndReady) {
        return true;
      }
    } else {
      if (isPrintEnableAndReady || isEpubEnableAndReady) {
        return true;
      }
    }

    return false;
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }

  @HostListener("click", ["$event", "$event.target.tagName"])
  onClick(_event: MouseEvent | KeyboardEvent, _targetTag: unknown) {
    const activeBook = this.booksStore.getActiveBook()!;
    if (activeBook.bookResults?.isPrintPreviewsReady && this.shouldSendReadyEmail) {
      this.shouldSendReadyEmail = false;
      this.bookService.trackActiveBook(activeBook);
    }
  }
}
