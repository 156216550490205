import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { debounceTime, takeUntil } from "rxjs";
import { Tariff } from "../../models/tariff";
import { User } from "../../models/user";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";
import { AdminUsersService } from "../../services/users/users.service";
import { AdminUsersStore } from "../../services/users/users.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-users-page",
  templateUrl: "./users.page.html",
  styleUrls: ["./users.page.scss"],
})
export class AdminUsersPage extends AdminBasePage implements OnInit {
  protected isUserDetailsShown = false;
  protected detailsUser?: User = undefined;

  users: User[] = [];
  page = 1;
  pageCount = 1;
  tariffs: Tariff[] = [];

  protected searchForm = new FormGroup({
    email: new FormControl("", { nonNullable: true, validators: [] }),
    integrationUserId: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchEmail: string | undefined = undefined;
  protected searchIntegrationUserId: string | undefined = undefined;

  protected addCreditsForm = new FormGroup({
    credits: new FormControl(0, { nonNullable: true, validators: [] }),
    goldCredits: new FormControl(0, { nonNullable: true, validators: [] }),
  });

  protected addSubscriptionForm = new FormGroup({
    tariffId: new FormControl(0, { nonNullable: true, validators: [Validators.required, Validators.min(1)] }),
    period: new FormControl(0, { nonNullable: true, validators: [Validators.required, Validators.min(1)] }),
  });

  constructor(
    private readonly adminUsersService: AdminUsersService,
    private readonly adminTariffsService: AdminTariffsService,
    adminUsersStore: AdminUsersStore,
  ) {
    super();

    adminUsersStore
      .getUsersObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((users) => {
        this.users = users;
      });

    adminUsersStore
      .getUsersPageCountObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count) => {
        this.pageCount = count;
      });
  }

  ngOnInit(): void {
    this.adminUsersService.loadUsers(this.page);
    this.loadTariffs();

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      if (value.email) {
        this.searchEmail = value.email;
      } else {
        this.searchEmail = undefined;
      }

      this.searchIntegrationUserId = value.integrationUserId || undefined;

      this.adminUsersService.loadUsers(this.page, {
        email: this.searchEmail,
        integrationUserId: this.searchIntegrationUserId,
      });
    });
  }

  trackByUserId(index: number, user: User) {
    return user.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminUsersService.loadUsers(this.page);
  }

  async addCredits(user: User) {
    await this.adminUsersService.addCredits(user, {
      credits: +this.addCreditsForm.value.credits!,
      goldCredits: +this.addCreditsForm.value.goldCredits!,
    });
    await this.adminUsersService.loadUsers(this.page, {
      email: this.searchEmail,
      integrationUserId: this.searchIntegrationUserId,
    });

    this.closeUserDetails();
  }

  async addSubscription(user: User) {
    await this.adminUsersService.addSubscription(user, {
      tariffId: this.addSubscriptionForm.value.tariffId!,
      period: this.addSubscriptionForm.value.period!,
    });
    await this.adminUsersService.loadUsers(this.page, {
      email: this.searchEmail,
      integrationUserId: this.searchIntegrationUserId,
    });

    this.closeUserDetails();
  }

  showUserDetails(user: User) {
    this.detailsUser = user;
    this.isUserDetailsShown = true;
  }

  closeUserDetails() {
    this.detailsUser = undefined;
    this.isUserDetailsShown = false;

    this.addCreditsForm.setValue({ credits: 0, goldCredits: 0 });
    this.addSubscriptionForm.setValue({ tariffId: 0, period: 0 });
  }

  private async loadTariffs() {
    this.tariffs = await this.adminTariffsService.loadTariffs();
  }

  protected getOptionsForTariffsSelect() {
    return this.adminTariffsService.getOptionsForTariffsSelect(this.tariffs);
  }
}
