import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { AccountTariffAndPaymentsMenuItemComponent } from "./components/account-tariff-and-payments-menu-item/account-tariff-and-payments-menu-item.component";
import { AccountMenuItemComponent } from "./components/menu-item/menu-item.component";
import { AccountActiveTariffPage } from "./pages/account-active-tariff/account-active-tariff.page";
import { AccountConnectTelegramPage } from "./pages/account-connect-telegram/account-connect-telegram.page";
import { AccountContactsPage } from "./pages/account-contacts/account-contacts.page";
import { AccountDevPage } from "./pages/account-dev/account-dev.page";
import { AccountHostPage } from "./pages/account-host.page";
import { AccountMainPage } from "./pages/account-main/account-main.page";
import { AccountNotificationsPage } from "./pages/account-notifications/account-notifications.page";
import { AccountPasswordPage } from "./pages/account-password/account-password.page";
import { AccountPaymentMethodsPage } from "./pages/account-payment-methods/account-payment-methods.page";
import { AccountPaymentsHistoryPage } from "./pages/account-payments-history/account-payments-history.page";
import { AccountRewardsPage } from "./pages/account-rewards/account-rewards.page";
import { AccountTariffAndPaymentsHostPage } from "./pages/account-tariff-and-payments-host/account-tariff-and-payments-host.page";
import { AccountVerificationCheckTokenPage } from "./pages/account-verification-check-token/account-verification-check-token.page";
import { AccountVerificationPage } from "./pages/account-verification/account-verification.page";
import { UserService } from "./services/user.service";
import { AccountConnectTelegramModalView } from "./views/account-connect-telegram-modal/account-connect-telegram-modal.view";
import { AccountContactsView } from "./views/account-contacts/account-contacts.view";
import { AccountDevView } from "./views/account-dev/account-dev.view";
import { AccountMainView } from "./views/account-main/account-main.view";
import { AccountPasswordView } from "./views/account-password/account-password.view";
import { AccountPaymentMethodView } from "./views/account-payment-method/account-payment-method.view";
import { AccountSocialConnectButtonView } from "./views/account-social-connect-button/account-social-connect-button.view";
import { AccountTariffAndPaymentsNavView } from "./views/account-tariff-and-payments-nav/account-tariff-and-payments-nav.view";
import { AccountVerificationView } from "./views/account-verification/account-verification.view";
import { AccountNavView } from "./views/nav/nav.view";

@NgModule({
  declarations: [
    //components
    AccountMenuItemComponent,
    //views
    AccountMainView,
    AccountPasswordView,
    AccountTariffAndPaymentsNavView,
    AccountTariffAndPaymentsMenuItemComponent,
    AccountContactsView,
    AccountVerificationView,
    AccountNavView,
    AccountPaymentMethodView,
    AccountDevView,
    AccountSocialConnectButtonView,
    AccountConnectTelegramModalView,

    //pages
    AccountHostPage,
    AccountMainPage,
    AccountPasswordPage,
    AccountTariffAndPaymentsHostPage,
    AccountVerificationPage,
    AccountVerificationCheckTokenPage,
    AccountContactsPage,
    AccountActiveTariffPage,
    AccountPaymentMethodsPage,
    AccountPaymentsHistoryPage,
    AccountNotificationsPage,
    AccountDevPage,
    AccountConnectTelegramPage,
    AccountRewardsPage,

    // services
  ],
  imports: [CoreModule, RouterModule],

  exports: [AccountConnectTelegramModalView],
  providers: [{ provide: "UserRefresher", useClass: UserService }],
})
export class UserModule {}
