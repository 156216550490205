import { ChangeDetectorRef, Component } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { LoadingService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import { PaymentCurrency } from "@metranpage/pricing-data";
import { Observable, Subscription } from "rxjs";
import { CompanyCloudPaymentsSettings, CompanyPaymentsSettings, CompanyStripeSettings } from "../../models/company";
import { AdminCompanyStore } from "../../services/company/company.store";
import { AdminCompanyCredsService } from "../../services/creds/companies-creds.service";
import { AdminCompanyCredsSettingsStore } from "../../services/creds/companies-creds.store";

function paymentsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    // if (
    //   value.isCloudPaymentsEnable &&
    //   (!value.cloudpayments.privateKey ||
    //     value.cloudpayments.privateKey?.trim() === "" ||
    //     !value.cloudpayments.publicId ||
    //     value.cloudpayments.publicId?.trim() === "")
    // ) {
    //   return {
    //     emptyCloudPaymentsFields: true,
    //   };
    // }
    // if (
    //   value.isStripeEnable &&
    //   (!value.stripe.secretKey ||
    //     value.stripe.secretKey?.trim() === "" ||
    //     !value.stripe.publicKey ||
    //     value.stripe.publicKey?.trim() === "" ||
    //     !value.stripe.webhookSecret ||
    //     value.stripe.webhookSecret?.trim() === "")
    // ) {
    //   return {
    //     emptyStripeFields: true,
    //   };
    // }
    if (value.isCloudPaymentsEnable && value.isStripeEnable) {
      return {
        selectedAllMethods: true,
      };
    }
    return null;
  };
}

@Component({
  selector: "m-admin-company-payments-settings-page",
  templateUrl: "./payments.page.html",
  styleUrls: ["./payments.page.scss"],
})
export class AdminCompanyPaymentsPage {
  protected cloudpaymentsSettings$: Observable<CompanyCloudPaymentsSettings | undefined> | undefined;
  protected stripeSettings$: Observable<CompanyStripeSettings | undefined> | undefined;

  readonly form: FormGroup;

  sub: Subscription = new Subscription();

  constructor(
    private readonly service: AdminCompanyCredsService,
    private readonly store: AdminCompanyCredsSettingsStore,
    private readonly adminCompanyStore: AdminCompanyStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = this.formBuilder.group(
      {
        currency: new FormControl<PaymentCurrency>("RUB", {
          nonNullable: false,
          validators: [Validators.required],
        }),
        isCloudPaymentsEnable: new FormControl<boolean>(false, {
          nonNullable: false,
          validators: [Validators.required],
        }),
        isStripeEnable: new FormControl<boolean>(false, { nonNullable: false, validators: [Validators.required] }),
        cloudpayments: this.formBuilder.group({
          publicId: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
          privateKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
        }),
        stripe: this.formBuilder.group({
          publicKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
          secretKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
          webhookSecret: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
        }),
      },
      { validator: [paymentsValidator()] },
    );
  }

  async ngAfterViewInit() {
    await this.service.refreshPaymentsSettings();

    this.sub.add(
      this.store
        .getPaymentsSettingsObservable()
        .pipe(filterUndefined())
        .subscribe((settings) => {
          this.form.patchValue({
            currency: settings.currency,
            isCloudPaymentsEnable: settings.isCloudPaymentsEnable,
            cloudpayments: {
              privateKey: settings?.cloudpayments?.privateKey || "",
              publicId: settings?.cloudpayments?.publicId || "",
            },
            isStripeEnable: settings.isStripeEnable,
            stripe: {
              secretKey: settings?.stripe?.secretKey || "",
              publicKey: settings?.stripe?.publicKey || "",
            },
          });
          this.cdr.markForCheck();
        }),
    );

    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  watchFormChanges() {
    this.sub.add(
      this.form.get("isCloudPaymentsEnable")?.valueChanges.subscribe((value) => {
        if (!value) {
          return;
        }
        this.form.patchValue({ currency: "RUB", isStripeEnable: false }, { emitEvent: false });
        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      this.form.get("isStripeEnable")?.valueChanges.subscribe((value) => {
        if (!value) {
          return;
        }
        this.form.patchValue({ currency: "EUR", isCloudPaymentsEnable: false }, { emitEvent: false });
        this.cdr.markForCheck();
      }),
    );
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updatePaymentsSettings(this.form.getRawValue() as CompanyPaymentsSettings);
    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set CloudPayments settings");
      console.error("Cannot set CloudPayments settings");
    }
    this.loadingService.stopLoading();
  }

  async onDeleteCloudPayments() {
    const result = await this.service.deleteCloudPaymentsSettings();
    if (result.status === "success") {
      // do nothing, as we already subscribed
    } else {
      this.notificationService.error("Cannot delete settings");
      console.error("Cannot delete settings");
    }
    this.cdr.markForCheck();
  }

  async onDeleteStripe() {
    const result = await this.service.deleteStripeSettings();
    if (result.status === "success") {
      // do nothing, as we already subscribed
    } else {
      this.notificationService.error("Cannot delete settings");
      console.error("Cannot delete settings");
    }
    this.cdr.markForCheck();
  }

  protected getCompanyCurrencyOptions() {
    return this.pricingViewService.getCompanyCurrencyOptions();
  }
}
