import { Component, Input, OnInit } from "@angular/core";
import { CompanyStore } from "@metranpage/company";
import { AnalyticsService, NotificationsPopUpService } from "@metranpage/core";
import { User } from "@metranpage/user-data";

import { Observable, filter, map } from "rxjs";

@Component({
  selector: "m-home-menu",
  templateUrl: "./home-menu.view.html",
  styleUrls: ["./home-menu.view.scss"],
})
export class HomeMenuView implements OnInit {
  @Input()
  user!: User;

  knowledgeBaseUrl$!: Observable<string | undefined>;

  constructor(
    private readonly notificationService: NotificationsPopUpService,
    private readonly analytics: AnalyticsService,
    companyStore: CompanyStore,
  ) {
    this.knowledgeBaseUrl$ = companyStore.getCompanyObservable().pipe(
      map((company) => company?.knowledgeBaseUrl?.trim()),
      filter((url) => !!url),
    );
  }

  ngOnInit(): void {}

  onMyProjectsClick() {
    this.analytics.event("main-left-menu-my-projects");
  }
  onGenerationsClick() {
    this.analytics.event("main-left-menu-generate");
  }

  openKnowledgeBase(url: string) {
    window.open(url, "_blank");
  }

  showSoonNotification() {
    this.notificationService.closeAll();
    this.notificationService.notify({
      content: $localize`:@@soon-text-notification:`,
      type: "success",
      showOptsTimeout: 2,
    });
  }
}
