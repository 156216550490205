import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { PricingModule } from "@metranpage/pricing";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ImageGenerationPage } from "./pages/image-generation/image-generation.page";
import { ColorSchemeSelectorView } from "./views/color-scheme-selector/color-scheme-selector.view";
import { ColorsMenuView } from "./views/color-scheme-selector/colors-menu/colors-menu.view";
import { GeneratedImageCardView } from "./views/generated-image-card/generated-image-card.view";
import { GeneratingImageCardView } from "./views/generating-image-card/generating-image-card.view";
import { GenerationErrorImageCardView } from "./views/generation-error-image-card/generation-error-image-card.view";
import { GenerationResultView } from "./views/generation-result/generation-result.view";
import { GenerationResultsView } from "./views/generation-results/generation-results.view";
import { ImageAdvancedGenerationModeView } from "./views/image-advanced-generation-mode/image-advanced-generation-mode.view";
import { ImageGenerationView } from "./views/image-generation/image-generation.view";
import { ImageProportionSelectorView } from "./views/image-proportion-selector/image-proportion-selector.view";
import { ImagesCountSelectorView } from "./views/images-count-selector/images-count-selector.view";
import { ModalConfirmationView } from "./views/modal-confimation/modal-confirmation.view";
import { PreviewImageModalView } from "./views/preview-image-modal/preview-image-modal.view";
import { PreviewGeneratedImageView } from "./views/preview-image-modal/preview-image.view";
import { PromptRestrictionView } from "./views/prompt-restriction/prompt-restriction.view";
import { ShareImageModalView } from "./views/share-image-modal/share-image-modal.view";
import { SidebarSection2 } from "./views/sidebar-section/sidebar-section.view";
import { SliderSelectorView } from "./views/slider-selector/slider-selector.view";
import { StylesSelectorView } from "./views/styles-selector/styles-selector.view";

@NgModule({
  imports: [CommonModule, FormsModule, CoreModule, NgOptimizedImage, RouterModule, InfiniteScrollModule, PricingModule],
  providers: [],
  declarations: [
    // components
    SidebarSection2,

    // views
    GeneratingImageCardView,
    GeneratedImageCardView,
    GenerationErrorImageCardView,
    GenerationResultsView,
    GenerationResultView,
    ColorSchemeSelectorView,
    ImagesCountSelectorView,
    ImageProportionSelectorView,
    ImageAdvancedGenerationModeView,
    StylesSelectorView,
    ColorsMenuView,
    SliderSelectorView,
    PreviewImageModalView,
    PreviewGeneratedImageView,
    ModalConfirmationView,
    ImageGenerationView,
    PromptRestrictionView,
    ShareImageModalView,
    // pages
    ImageGenerationPage,
  ],
  exports: [ImageGenerationView, SidebarSection2],
})
export class ImageGenerationModule {}
