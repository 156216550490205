import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, delay, fromEvent, map } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { Heading, PaletteDTO } from "../../models/editor";
import { ListType, StyleKey, StyleSettings, Styles, StylesSettings } from "../../models/styles";
import { EditorDataService } from "../../services/editor-data.service";
import { MarkupService } from "../../services/markup.service";
import { ToolbarSettings } from "../markup-block-toolbar/markup-block-toolbar.view";
import { MarkupBlockView } from "./blocks/block.view";
import { ImageBlockDefaultData } from "./blocks/image-block/image-block.component";
import { ListData } from "./blocks/list-block/interfaces/list-data.interface";
import {
  BlockMergeEvent,
  BlockMovementEvent,
  BlockSelectEvent,
  BlockSimulatedSelectEvent,
  BlockSplitEvent,
} from "./editor.events";
import { BlockId, EditorBlock, EditorDataItem } from "./editor.models";
import { MarkupEditorService, TextSelectionState } from "./editor.service";
import { EditorStore } from "./editor.store";

type BlockToolbarState = { topPosition: number; isVisible: boolean };
type SelectionState = {
  onPageIndex: number;
  index: number;
  selectedItemStyle: StyleKey;
};
export type Direction = "up" | "down";

@Component({
  selector: "m-markup-editor",
  templateUrl: "./markup-editor.view.html",
  styleUrls: ["./markup-editor.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupEditorView implements AfterViewInit, AfterViewChecked, OnDestroy {
  @Input()
  styles!: Styles;
  @Input()
  items!: EditorDataItem[];
  @Input()
  styleChangeEvents!: Observable<StyleKey | undefined>;
  @Input()
  selectBlockEvents!: Observable<BlockId | undefined>;
  @Input()
  formChanges?: Observable<any>;
  @Input()
  palette?: PaletteDTO;
  @Input()
  bookId!: number;
  @Input()
  headerStyles: string[] = ["header1", "header2", "header3", "header4"];
  @Input()
  imageDefaultData?: ImageBlockDefaultData;
  @Input()
  toolbarSettings?: ToolbarSettings;

  @Output()
  onReady = new EventEmitter<void>();
  @Output()
  onBlockSelectionChanged = new EventEmitter<EditorDataItem[]>();
  @Output()
  onHeadingsChanged = new EventEmitter<Heading[]>();
  @Output()
  onBlockHovered = new EventEmitter<StyleKey | undefined>();
  @Output()
  onBlockUpdate = new EventEmitter<void>();
  @Output()
  onBlockInViewport = new EventEmitter<number>();
  @Output()
  onBlockWarningDelete = new EventEmitter<void>();

  @Output()
  onAiGenerationClick = new EventEmitter<any>();

  @ViewChild("inlineToolbar", { read: ElementRef })
  inlineToolbar!: ElementRef;

  @ViewChild("textGenerationContextMenu", { read: ElementRef })
  textGenerationContextMenu!: ElementRef;

  @ViewChildren(MarkupBlockView)
  blocks: QueryList<MarkupBlockView> | undefined = undefined;

  private awaitedScrollBlockId: BlockId | undefined;

  protected currentPage = 0;
  protected pageSize = 60 as const;
  protected pageCount = 0;

  protected blockToolbarState: BlockToolbarState = {
    isVisible: false,
    topPosition: 0,
  };
  protected selectionState: SelectionState | undefined = undefined;

  protected paginatedItems: EditorDataItem[] = [];

  protected stylesOpts$: Observable<StylesSettings>;
  protected selectedBlocksIds$: Observable<BlockId[]>;
  protected stylesOptsByStyle?: Record<StyleKey, Observable<StyleSettings>>;
  protected simulatedBlockSelectEvents = new Subject<BlockSimulatedSelectEvent>(); // simulate user click on block

  protected selectionChange$: Observable<Event> = fromEvent(document, "selectionchange");

  protected textSelectionState$: Observable<TextSelectionState>;
  protected textSelectionState: TextSelectionState | undefined = undefined;

  protected textGenerationContextMenuVisible = false;

  protected sub = new Subscription();

  get index(): number {
    if (!this.selectionState) {
      return 0;
    }
    return this.selectionState.index;
  }

  get itemLength(): number {
    return this.items.length - 1;
  }

  constructor(
    private readonly markupService: MarkupService,
    private readonly editorStore: EditorStore,
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
    markupEditorService: MarkupEditorService,
    private readonly editorDataService: EditorDataService,
  ) {
    this.stylesOpts$ = this.editorStore.getSettingsForStylesObservable();
    this.selectedBlocksIds$ = this.editorStore.getSelectedBlockIdsObservable();

    this.textSelectionState$ = markupEditorService.selectionState$.asObservable();
  }

  ngAfterViewInit(): void {
    this.pageCount = Math.ceil(this.items.length / this.pageSize);
    this.selectPage(0);

    this.recalcHeadings();

    this.stylesOptsByStyle = {} as Record<StyleKey, Observable<StyleSettings>>;
    for (const key of Object.keys(this.styles)) {
      const sk = key as StyleKey;
      this.stylesOptsByStyle[sk] = this.stylesOpts$.pipe(map((styles) => styles[sk]));
    }

    this.sub.add(
      this.styleChangeEvents.subscribe((style) => {
        if (style) {
          this.changeBlocksStyle(style);
        }
      }),
    );

    this.sub.add(
      this.selectBlockEvents.subscribe((blockId) => {
        if (blockId) {
          this.scrollToBlock(blockId);
        }
      }),
    );

    this.sub.add(
      this.textSelectionState$.subscribe((textSelectionState) => {
        this.textSelectionState = textSelectionState;
        this.onSelectionChange(textSelectionState.range);
      }),
    );

    this.sub.add(
      this.formChanges?.pipe(delay(100)).subscribe((data) => {
        this.redrawToolbar();
      }),
    );

    // this.sub.add(
    //   this.blocks?.changes.subscribe((r) => {
    //     this.blocks = r;
    //   })
    // );
  }

  ngAfterViewChecked(): void {
    this.scrollSamePageToBlock(this.awaitedScrollBlockId!);
    this.awaitedScrollBlockId = undefined;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getBlocks() {
    const blocks = this.items.map((item) => item.block);
    return this.editorDataService.cleanData(blocks);
  }

  // apply change to original data source
  onContentChanged(newData: EditorBlock) {
    const data = this.items.find((item) => item.block.id === newData.id);
    if (data) {
      data.block = newData;

      if (newData.style.includes("header")) {
        this.recalcHeadings();
      }
    }

    this.onBlockUpdate.emit();
  }

  addBlock(styleKey: StyleKey, newBlockData?: any) {
    if (!this.selectionState) {
      console.error("cannot add block without selection");
      return;
    }

    const newBlock = this.markupService.createBlock(styleKey, this.items, newBlockData);

    const index = this.selectionState.index + 1;
    const onPageIndex = this.selectionState.onPageIndex + 1;

    const blockData = { block: newBlock, errors: [] };
    this.items.splice(index, 0, blockData);
    this.paginatedItems.splice(onPageIndex, 0, blockData);

    this.selectionState.index = index;
    this.selectionState.onPageIndex = onPageIndex;

    this.cdr.detectChanges();

    const selectedBlock = this.getSelectedBlock();

    if (selectedBlock) {
      this.selectBlock(selectedBlock.data.id, "start");
    }

    this.scrollSamePageToBlock(newBlock.id);
  }

  addListBlock(style: ListType): void {
    this.addBlock("list", { style: style });
  }

  deleteBlock(): void {
    if (!this.selectionState) {
      console.error("Cannot update block");
      return;
    }
    this.items.splice(this.selectionState.index, 1);
    this.paginatedItems.splice(this.selectionState.onPageIndex, 1);

    this.selectionState.index = this.selectionState.index - 1;
    this.selectionState.onPageIndex = this.selectionState.onPageIndex - 1;

    if (this.selectionState.index < 0) {
      this.selectionState.index = 0;
    }
    if (this.selectionState.onPageIndex < 0) {
      this.selectionState.onPageIndex = 0;
    }

    this.cdr.detectChanges();

    const selectedBlock = this.getSelectedBlock();
    if (selectedBlock) {
      this.selectBlock(selectedBlock.data.id, "end");
    }

    this.recalcHeadings();
  }

  onSplitBlock(event: BlockSplitEvent) {
    // previous block already have changed text, see m-text-editor
    this.addBlock(event.styleKey, {
      text: event.text,
    });

    if (event.styleKey.includes("header")) {
      this.recalcHeadings();
    }
  }

  onMergeBlocks(event: BlockMergeEvent) {
    const blockId = event.blockId;
    const index = this.items.findIndex((item) => item.block.id === blockId);

    if (index > 0 && index !== -1) {
      const previous = this.items[index - 1];
      const current = this.items[index];

      if (!this.canMerge(previous, current)) {
        return;
      }

      const prevBlock = this.getBlockElementById(previous.block.id);
      if (!prevBlock) {
        console.error("no prev block to merge");
        return;
      }
      const currentData = current.block.data;
      prevBlock.appendTextAndFocus(currentData.text);

      this.deleteBlock();
    }
  }

  deleteBlockByOnPageIndex(onPageIndex: number) {
    const blockIndex = this.getBlockIndex(onPageIndex);
    this.items.splice(blockIndex, 1);
    this.paginatedItems.splice(onPageIndex, 1);

    if (this.selectionState && this.selectionState.onPageIndex >= onPageIndex) {
      this.selectionState.index = this.selectionState.index - 1;
      this.selectionState.onPageIndex = this.selectionState.onPageIndex - 1;
    }

    this.cdr.detectChanges();
    const selectedBlock = this.getSelectedBlock();
    if (selectedBlock) {
      this.selectBlock(selectedBlock.data.id, "end");
    }

    this.recalcHeadings();
  }

  moveBlock(direction: Direction): void {
    if (!this.selectionState) {
      console.error("Cannot update block");
      return;
    }

    const blockData = this.paginatedItems[this.selectionState.onPageIndex];
    const index = direction === "up" ? this.selectionState.index - 1 : this.selectionState.index + 1;
    const onPageIndex = direction === "up" ? this.selectionState.onPageIndex - 1 : this.selectionState.onPageIndex + 1;

    this.deleteBlock();
    this.items.splice(index, 0, blockData);
    this.paginatedItems.splice(onPageIndex, 0, blockData);
    this.selectionState.index = index;
    this.selectionState.onPageIndex = onPageIndex;
    this.cdr.detectChanges();

    const selectedBlock = this.getSelectedBlock();
    if (selectedBlock) {
      this.selectBlock(selectedBlock.data.id, "start");
      this.scrollSamePageToBlock(selectedBlock.data.id);
    }
  }

  onBlockSelected(event: BlockSelectEvent) {
    if (event.multi) {
      this.editorStore.addSelectedBlockId(event.blockId);
      this.blockToolbarState.isVisible = false;
    } else {
      this.editorStore.setSelectedBlockIds([event.blockId]);
      this.blockToolbarState.topPosition = event.blockTopPosition;
      this.blockToolbarState.isVisible = true;

      this.selectionState = {
        onPageIndex: event.onPageIndex,
        index: this.getBlockIndex(event.onPageIndex),
        selectedItemStyle: this.paginatedItems[event.onPageIndex].block.style,
      };
    }

    const selectedBlocksIds = this.editorStore.getSelectedBlockIds();
    this.onBlockSelectionChanged.emit(this.items.filter((block) => selectedBlocksIds.includes(block.block.id)));
  }

  convertListToText(items: ListData[]) {
    let text = "";

    if (!items) {
      return text;
    }
    for (const item of items) {
      text += `${item.content}<br>`;
      // text += this.convertListToText(item.items);
    }
    return text;
  }

  convertTextToList(text: string, style: StyleKey) {
    const isOrdered = true;
    // if (style.search("unordered")) {
    //   isOrdered = false;
    // }

    const listStyle = isOrdered ? "ordered" : "unordered";

    const items = text.split("<br>");

    const data: any = {
      style: listStyle,
      level: -1,
      startIndex: 0,
      endIndex: 0,
      content: "",
      items: [],
    };
    for (const content of items) {
      data.items.push({
        content: content,
        level: 0,
        style: listStyle,
        index: 1,
        uniqueId: uuidv4(),
        items: [],
      });
    }

    return data;
  }

  changeBlocksStyle(style: StyleKey) {
    const selectedBlocksIds = this.editorStore.getSelectedBlockIds();

    const textStyles = Object.values(this.styles)
      .filter((s) => s.type === "text" || s.type === "header")
      .map((s) => s.styleKey);
    const listStyles = Object.values(this.styles)
      .filter((s) => s.type === "list")
      .map((s) => s.styleKey);
    const notAcceptableStyleKeysToConvert: StyleKey[] = ["image", "table", "page-break"];

    const isText = (style: StyleKey) => {
      return textStyles.includes(style);
    };
    const isList = (style: StyleKey) => {
      return listStyles.includes(style);
    };

    for (const block of this.items) {
      if (notAcceptableStyleKeysToConvert.includes(style)) {
        continue;
      }
      if (notAcceptableStyleKeysToConvert.includes(block.block.style)) {
        continue;
      }

      if (selectedBlocksIds.includes(block.block.id)) {
        if (isList(block.block.style) && isText(style)) {
          const text = this.convertListToText(block.block.data.items);
          block.block.data = { text };
        } else if (isText(block.block.style) && isList(style)) {
          const data = this.convertTextToList(block.block.data.text, style);
          block.block.data = data;
        }

        block.block.style = style;
      }
    }

    // for (const block of this.paginatedItems) {
    //   if (selectedBlocksIds.includes(block.block.id)) {
    //     block.block.style = style;
    //   }
    // }

    this.onBlockSelectionChanged.emit(this.items.filter((block) => selectedBlocksIds.includes(block.block.id)));

    this.recalcHeadings();

    this.cdr.detectChanges();
  }

  onBlockMovement(event: BlockMovementEvent) {
    const index = this.items.findIndex((item) => item.block.id === event.blockId);
    if (event.direction === "next") {
      this.selectBlock(this.items[index + 1].block.id, "start");
    } else if (event.direction === "prev") {
      this.selectBlock(this.items[index - 1].block.id, "end");
    }
  }

  protected scrollToBlock(blockId: BlockId) {
    const index = this.items.findIndex((item) => item.block.id === blockId);

    const newPage = Math.floor(index / this.pageSize);

    if (newPage === this.currentPage) {
      this.scrollSamePageToBlock(blockId);
    } else {
      this.awaitedScrollBlockId = blockId;
      this.selectPage(newPage);
      this.cdr.detectChanges();
    }
  }

  protected selectPage(page: number) {
    if (this.elementRef?.nativeElement) {
      this.elementRef.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    this.currentPage = page;

    const start = this.currentPage * this.pageSize;
    const pageEnd = (this.currentPage + 1) * this.pageSize;
    const end = Math.min(pageEnd, this.items.length);
    this.paginatedItems = this.items.slice(start, end);

    this.blockToolbarState.isVisible = false;

    this.cdr.markForCheck();
  }

  protected recalcHeadings() {
    const headings: Heading[] = [];

    const blocks = this.items.map((item) => item.block);
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];

      if (this.headerStyles.includes(block.style)) {
        const level = Number.parseInt(block.style.replace("header", ""));

        const textWithoutTags = block.data.text
          .replaceAll("<br>", " ") // replace br with space
          .replaceAll("<br />", " ") // replace br with space
          .replaceAll(/(<sup>.+<\/sup>)|(<sub>.+<\/sub>)|(<footnote[^>]*>\d+<\/footnote>)/gim, "") // replace sup, sub, footnotes with space
          .replaceAll(/(<([^>]+)>)/gi, "") // strip tags
          .replaceAll("&nbsp;", ""); // remove unnecessary spaces

        let title = _.truncate(textWithoutTags.trim(), { length: 30 });
        if (!title) {
          title = $localize`:@@books.markup.empty-header:`;
        }

        headings.push({
          blockId: block.id,
          level,
          text: title,
        });
      }
    }
    this.onHeadingsChanged.emit(headings);
  }

  private scrollSamePageToBlock(id: BlockId) {
    const element = this.elementRef.nativeElement.querySelector(`[data-id="${id}"]`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      // element.scrollIntoView({ behavior: 'smooth' });
    }
    this.selectBlock(id, "start");
  }

  private selectBlock(blockId: BlockId, setCursorTo: "start" | "end") {
    this.simulatedBlockSelectEvents.next({ blockId, setCursorTo });
    // this.operationsService.selectComponentByIndex(componentIndex);
    // this.operationsService.setCursor(componentIndex);
  }

  trackByBlockId(index: number, item: EditorDataItem) {
    return item.block.id;
  }

  onSelectionChange(range: Range | undefined) {
    if (!range) {
      return;
    }

    if (!range.collapsed) {
      this.positionInlineToolbarToSelection(range);
    }

    // this.hideTextGenerationContextMenu();
  }

  protected positionInlineToolbarToSelection(range: Range) {
    const selectionRect = range.getBoundingClientRect();

    const parentRect = this.elementRef.nativeElement.getBoundingClientRect();

    const toolbarStyle = this.inlineToolbar.nativeElement.style;
    toolbarStyle.opacity = "1";

    toolbarStyle.left = `${selectionRect.x - parentRect.x}px`;
    toolbarStyle.top = `${selectionRect.y - parentRect.y - 55}px`;
  }

  protected hideInlineToolbar() {
    const toolbarStyle = this.inlineToolbar.nativeElement.style;
    toolbarStyle.opacity = "0";
    toolbarStyle.left = "-1000px";
  }

  protected onInlineBoldClick() {
    const block = this.getBlockElementByIndex(this.textSelectionState?.onPageIndex);
    if (!block) {
      return;
    }
    block.applyBold();
  }

  protected onInlineItalicClick() {
    const block = this.getBlockElementByIndex(this.textSelectionState?.onPageIndex);
    if (!block) {
      return;
    }
    block.applyItalick();
  }

  protected onInlineAddFootnoteClick() {
    const block = this.getBlockElementByIndex(this.textSelectionState?.onPageIndex);
    if (!block) {
      return;
    }

    this.hideInlineToolbar();

    block.addFootnote();
  }

  protected onScroll() {
    if (!this.blocks) {
      return;
    }

    for (const block of this.blocks) {
      if (block.isInViewport()) {
        const blockIndex = this.getBlockIndex(block.onPageIndex);
        this.onBlockInViewport.emit(blockIndex);
      }
    }
  }

  private getBlockIndex(onPageIndex: number): number {
    return this.currentPage * this.pageSize + onPageIndex;
  }

  private getSelectedBlock() {
    return this.blocks?.find((block) => block.onPageIndex === this.selectionState?.onPageIndex);
  }

  private redrawToolbar() {
    if (this.selectionState) {
      const selectedBlock = this.getSelectedBlock();
      if (!selectedBlock) {
        return;
      }

      this.blockToolbarState.topPosition = selectedBlock.elementRef.nativeElement.offsetTop;

      this.cdr.markForCheck();
    }
  }

  private getBlockElementByIndex(onPageIndex: number | undefined) {
    if (!this.blocks || onPageIndex === undefined) {
      return;
    }
    return this.blocks.find((block) => block.onPageIndex === onPageIndex);
  }

  private getBlockElementById(id: string | undefined) {
    if (!this.blocks || id === undefined) {
      return;
    }
    return this.blocks.find((block) => block.data.id === id);
  }

  protected isHeaderBefore(blockId: string) {
    const index = this.items.findIndex((item) => item.block.id === blockId);

    for (let i = index - 1; i >= 0; i--) {
      if (this.items[i].block.style === "image") {
        continue;
      }
      if (this.items[i].block.style === "header1" || this.items[i].block.style === "header2") {
        return true;
      }
      return false;
    }
    return false;
  }

  private canMerge(block1: EditorDataItem, block2: EditorDataItem): boolean {
    const noMergeStyles: StyleKey[] = ["image", "table", "list"];
    if (noMergeStyles.includes(block1.block.style) || noMergeStyles.includes(block2.block.style)) {
      return false;
    }
    return true;
  }

  protected positionTextGenerationContextMenuToSelection(range: Range) {
    const selectionRect = range.getBoundingClientRect();
    const parentRect = this.elementRef.nativeElement.getBoundingClientRect();

    const contextMenuStyle = this.textGenerationContextMenu.nativeElement.style;
    contextMenuStyle.opacity = "1";
    contextMenuStyle.left = `${selectionRect.x - parentRect.x + 20}px`;
    contextMenuStyle.top = `${selectionRect.y - parentRect.y + 15}px`;
  }

  displayTextGenerationContextMenu(event: any) {
    event.preventDefault();

    const range = this.textSelectionState?.range;
    if (!range || range.collapsed) {
      this.hideTextGenerationContextMenu();
      return;
    }

    this.positionTextGenerationContextMenuToSelection(range);

    this.textGenerationContextMenuVisible = true;
  }

  hideTextGenerationContextMenu() {
    this.textGenerationContextMenuVisible = false;

    const contextMenuStyle = this.textGenerationContextMenu.nativeElement.style;
    contextMenuStyle.opacity = "0";
    contextMenuStyle.left = "-1000px";
  }

  // protected onTextGenerationContextMenuClick(mode: string) {
  //   // if (!this.textSelectionState) {
  //   //   return;
  //   // }
  //   // const text = this.paginatedItems[this.textSelectionState.onPageIndex!].block.data.text || "";

  //   const selectedText = this.getSelectedText();

  //   this.onAiGenerationClick.emit({
  //     mode,
  //     prompt: selectedText,
  //   });

  //   this.hideTextGenerationContextMenu();
  // }

  private getSelectedText() {
    let text = "";
    if (window.getSelection()) {
      const select = window.getSelection();
      text = select?.toString() || "";
    }
    return text;
  }
}
