import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  forwardRef,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { InputNumberComponent } from "@metranpage/design-system";
import { filter, tap } from "rxjs";

@Component({
  selector: "m-slider-with-indication",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputNumberComponent],
  templateUrl: "./slider-with-indication.view.html",
  styleUrls: ["./slider-with-indication.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderWithIndication),
      multi: true,
    },
  ],
})
export class SliderWithIndication implements ControlValueAccessor, OnInit {
  @Input("min") min = 0;
  @Input("max") max = 100;
  @Input("title") title!: string;
  @Input("multiplier") multiplier!: number;
  protected pseudoWidth!: string;
  private onTouched = () => {};
  private onChange = (_: any) => {};
  protected value = "0";
  protected isDisabled = false;
  form!: FormGroup;
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      input: ["0"],
    });

    this.form.controls.input.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((_) => this.form.controls.input.valid),
        tap((v) => this.setValue(v)),
      )
      .subscribe();
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value !== "0") {
      this.form.controls.input.setValue(this.value);
    }
    this.setPseudoWidth(this.value);
    this.cdr.markForCheck();
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  setValueFromEvent(event: Event): void {
    if (event.currentTarget) {
      const newValue = (event.currentTarget as HTMLInputElement).value;
      this.form.controls.input.setValue(newValue);
    }
  }

  setValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.setPseudoWidth(this.value);
  }

  setPseudoWidth(value: string): void {
    const newWidth = Math.round((+value / this.max) * 100);
    this.pseudoWidth = `${newWidth}%`;
  }
}
