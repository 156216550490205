{
  "deployedUrl": "https://test.metranpage.com/",
  "ga": "G-7XRT391W56",
  "companyUuid": "187e8e3c-d419-4df1-9a53-8164021af6a5",
  "availableLanguages": [
    { "visibleName": "Русский", "iconSrc": "/assets/img/flag-ru.svg", "value": "ru" },
    { "visibleName": "English", "iconSrc": "/assets/img/flag-gb.svg", "value": "en" }
  ],
  "replainId": "24d6ece9-dcd9-4b13-8efc-bb3113ecd163",
  "downloadFileTemplate": "metranpage.com_{filename}",
  "color": "#e02379",
  "i18n": {
    "en": {
      "app-name": "Metranpage"
    },
    "ru": {
      "app-name": "Метранпаж"
    }
  },
  "socials": [
    {
      "icon": "vk.svg",
      "url": "https://vk.com/metranpageonline",
      "locales": [
        { "lang": "ru", "value": "Вконтакте" },
        { "lang": "en", "value": "Vkontakte" },
        { "lang": "de", "value": "Vkontakte" }
      ]
    },
    {
      "icon": "telegram.svg",
      "url": "https://t.me/gometranpage",
      "locales": [
        { "lang": "ru", "value": "Телеграм" },
        { "lang": "en", "value": "Telegram" },
        { "lang": "de", "value": "Telegram" }
      ]
    },
    {
      "icon": "youtube.svg",
      "url": "https://www.youtube.com/@metranpage_here",
      "locales": [
        { "lang": "ru", "value": "Youtube" },
        { "lang": "en", "value": "Youtube" },
        { "lang": "de", "value": "Youtube" }
      ]
    },
    {
      "icon": "social.svg",
      "url": "https://t.me/mezhdu_strok_comm",
      "locales": [
        { "lang": "ru", "value": "Telegram" },
        { "lang": "en", "value": "Telegram" },
        { "lang": "de", "value": "Telegram" }
      ]
    }
  ],
  "flags": {
    "canCreatePublicTemplates": true,
    "isImagesGenerationAvailable": true,
    "isCommunityAvailable": true,
    "isPurchasesAvailable": true,
    "isPublishInRussia": true,
    "isEditionNoticeAvailable": true,
    "isUserTemplateUploadAvailable": true,
    "isUserRoleNotificationAvailable": true,
    "isVkLoginAvailable": true,
    "isGoogleLoginAvailable": true,
    "showUpdatesNews": true,
    "useSimpleSpinner": false,
    "isCoverEditorEnabled": true
  }
}
