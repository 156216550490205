import { Injectable } from "@angular/core";
import { RouterService } from "@metranpage/core";
import { BehaviorSubject, Subject } from "rxjs";

export type CreateProjectEvent = {
  projectType: "book" | "cover";
};

@Injectable({
  providedIn: "root",
})
export class HomeService {
  createProjectEvent$ = new BehaviorSubject<CreateProjectEvent | undefined>(undefined);
  showLoginModalEvent$ = new Subject<void>();

  constructor(private readonly routerService: RouterService) {}

  showNewProjectModal(opts: CreateProjectEvent) {
    this.createProjectEvent$.next(opts);
  }

  resetNewProjectModal() {
    this.createProjectEvent$.next(undefined);
  }

  showLoginModal() {
    this.routerService.showInModal(undefined);
    this.showLoginModalEvent$.next();
  }
}
