<form [formGroup]="form" class="wrapper" *ngIf="values$ | async as values">
  <div class="section">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.alignment">cover-editor.object.settings.alignment</div>
      <m-cover-object-alignment (align)="onAlign($event)"></m-cover-object-alignment>
    </div>
  </div>
  <div class="section">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.text.font-family">
        cover-editor.object.settings.text.font-family
      </div>
      <m-font-select
        formControlName="fontFamily"
        size="s"
        [options]="fontOptions"
        [hideArrowDivider]="true"
        (optionMouseOver)="onPreviewFontFamily($event.toString())"
        (dropdownMouseLeave)="onResetFontFamily()"
      ></m-font-select>
      <div class="row equal-size">
        <div></div>
        <m-ng-input-number-with-icon
          iconLeft="font-size-01.svg"
          formControlName="fontSize"
          size="40"
          [iconSize]="28"
          text-align="right"
        />
      </div>
      <div class="row equal-size">
        <m-ng-input-number-with-icon
          iconLeft="line-height-01.svg"
          formControlName="lineHeight"
          size="40"
          [iconSize]="28"
          text-align="right"
        />
        <m-ng-input-number-with-icon
          iconLeft="letter-spacing-01.svg"
          formControlName="letterSpacing"
          size="40"
          [iconSize]="28"
          text-align="right"
        />
      </div>
    </div>
  </div>
  <div class="section">
    <div class="row control-line">
      <div class="caption" i18n="@@cover-editor.object.settings.text.style">
        cover-editor.object.settings.text.style
      </div>
      <div class="button-set">
        <m-ng-icon
          [size]="24"
          (click)="toggleTextBold()"
          src="/assets/icons/bold-01.svg"
          [class.active]="currentObject.bold"
        ></m-ng-icon>
        <m-ng-icon
          [size]="24"
          (click)="toggleTextItalic()"
          src="/assets/icons/italic-01.svg"
          [class.active]="currentObject.italic"
        ></m-ng-icon>
        <m-ng-icon
          [size]="24"
          (click)="toggleTextUnderline()"
          src="/assets/icons/underline-01.svg"
          [class.active]="currentObject.underline"
        ></m-ng-icon>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="row control-line">
      <div class="caption" i18n="@@cover-editor.object.settings.text.align">
        cover-editor.object.settings.text.align
      </div>
      <div class="button-set">
        <m-ng-icon
          [size]="24"
          (click)="setTextAlign('left')"
          src="/assets/icons/align-left-01.svg"
          [class.active]="currentObject.textAlign === 'left'"
        ></m-ng-icon>
        <m-ng-icon
          [size]="24"
          (click)="setTextAlign('center')"
          src="/assets/icons/align-center-01.svg"
          [class.active]="currentObject.textAlign === 'center'"
        ></m-ng-icon>
        <m-ng-icon
          [size]="24"
          (click)="setTextAlign('right')"
          src="/assets/icons/align-right-01.svg"
          [class.active]="currentObject.textAlign === 'right'"
        ></m-ng-icon>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="row control-line">
      <div class="caption" i18n="@@books.markup.style-details.case-title">
        books.markup.style-details.case-title
      </div>
      <div class="button-set">
        <m-ng-icon
          [size]="24"
          (click)="setTextCase(textCase.Auto)"
          src="/assets/icons/cover-minus.svg"
          [class.active]="!currentObject.textCase || currentObject.textCase === textCase.Auto"
        ></m-ng-icon>
        <m-ng-icon
          [size]="24"
          (click)="setTextCase(textCase.Upper)"
          src="/assets/icons/cover-upper-case.svg"
          [class.active]="currentObject.textCase === textCase.Upper"
        ></m-ng-icon>
        <m-ng-icon
          [size]="24"
          (click)="setTextCase(textCase.Lower)"
          src="/assets/icons/cover-lower-case.svg"
          [class.active]="currentObject.textCase === textCase.Lower"
        ></m-ng-icon>
      </div>
    </div>
  </div>


  <div class="section">
    <div class="rowset">
      <div class="row space-between">
        <div class="caption" i18n="@@cover-editor.object.settings.text.fill">
          cover-editor.object.settings.text.fill
        </div>
        <div class="caption dimmed">{{ fillType }}</div>
      </div>
      <div class="row">
        <m-cover-fill-select
          [fill]="currentObject.fill"
          (update)="updateFill($event)"
          [useColorPickerExternalHost]="true"
          popupPosition="top"
        ></m-cover-fill-select>
      </div>
      <span class="caption" i18n="@@cover.shadow">cover.shadow</span>
      <m-switch
        [isChecked]="withShadow"
        [withLabel]="false"
        class="elements-group"
        (onToggle)="toggleShadow()"
      ></m-switch>
    </div>
    <ng-container *ngIf="withShadow">
      <div formGroupName="shadow">
        <div class="row control-line">
          <m-slider-with-indication
            title="cover.offset"
            i18n-title="@@cover.offset"
            formControlName="offset"
            name="offset"
          ></m-slider-with-indication>
        </div>
        <div class="row control-line">
          <m-slider-with-indication
            formControlName="direction"
            title="cover.direction"
            i18n-title="@@cover.direction"
            [max]="360"
          ></m-slider-with-indication>
        </div>
        <div class="row control-line">
          <m-slider-with-indication
            formControlName="blur"
            title="cover.blur"
            i18n-title="@@cover.blur"
          ></m-slider-with-indication>
        </div>
        <div class="row space-between">
          <div class="caption" i18n="@@cover.shadow-color">cover.shadow-color</div>
        </div>
        <div class="row">
          <m-cover-color-select
            [color]="color"
            [useColorPickerExternalHost]="false"
            (update)="updateShadowColor($event)"
          ></m-cover-color-select>
        </div>
      </div>
    </ng-container>
  </div>
</form>
